import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    USER_SUBSCRIBE_REQUEST,
    USER_SUBSCRIBE_SUCCESS,
    USER_SUBSCRIBE_FAIL,
    USER_SUBSCRIBE_RESET,

    LIST_SUBSCRIBE_REQUEST,
    LIST_SUBSCRIBE_SUCCESS,
    LIST_SUBSCRIBE_FAIL,
    LIST_SUBSCRIBE_RESET,

    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_RESET,

    ADMIN_UPDATE_PROFILE_REQUEST,
    ADMIN_UPDATE_PROFILE_SUCCESS,
    ADMIN_UPDATE_PROFILE_FAIL,
    ADMIN_UPDATE_PROFILE_RESET,

    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_RESET,

    SCIENTIFIC_BOARD_REQUEST,
    SCIENTIFIC_BOARD_SUCCESS,
    SCIENTIFIC_BOARD_FAIL,

    MESSAGE_LIST_REQUEST,
    MESSAGE_LIST_SUCCESS,
    MESSAGE_LIST_FAIL,

    MESSAGE_CREATE_REQUEST,
    MESSAGE_CREATE_SUCCESS,
    MESSAGE_CREATE_FAIL,

} from '../constants/userConstants'


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}


export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userSubscribeReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SUBSCRIBE_REQUEST:
            return { loading: true }

        case USER_SUBSCRIBE_SUCCESS:
            return { loading: false, success: true }

        case USER_SUBSCRIBE_FAIL:
            return { loading: false, error: action.payload }

        case USER_SUBSCRIBE_RESET:
                return {}
        default:
            return state
    }
}


export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true }

        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload }

        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case USER_DETAILS_RESET:
            return { user: {} }


        default:
            return state
    }
}


export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }

        case USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_PROFILE_RESET:
            return {}

        default:
            return state
    }
}


export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true }

        case USER_LIST_SUCCESS:
            return {
                loading: false,
                members: action.payload,
            }

        case USER_LIST_FAIL:
            return { loading: false, error: action.payload }

        case USER_LIST_RESET:
            return { users: [] }

        default:
            return state
    }
}


export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true }

        case USER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const adminUpdateReducer = (state = { user: [] }, action) => {
    switch (action.type) {
        case ADMIN_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case ADMIN_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true }

        case ADMIN_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case ADMIN_UPDATE_PROFILE_RESET:
            return { user: {} }

        default:
            return state
    }
}


export const userUpdateReducer = (state = { user: [] }, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true }

        case USER_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_RESET:
            return { user: {} }

        default:
            return state
    }
}


export const scientificBoardReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case SCIENTIFIC_BOARD_REQUEST:
            return { loading: true }

        case SCIENTIFIC_BOARD_SUCCESS:
            return { loading: false, users: action.payload }

        case SCIENTIFIC_BOARD_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const subscribeListReducer = (state = { subs: [] }, action) => {
    switch (action.type) {
        case LIST_SUBSCRIBE_REQUEST:
            return { loading: true }

        case LIST_SUBSCRIBE_SUCCESS:
            return {
                loading: false,
                subs: action.payload,
            }

        case LIST_SUBSCRIBE_FAIL:
            return { loading: false, error: action.payload }

        case LIST_SUBSCRIBE_RESET:
            return { subs: [] }

        default:
            return state
    }
}

// -------------------------------------------- MESSAGES 

export const listMessageReducer = (state = { message: [] }, action) => {
    switch (action.type) {
        case MESSAGE_LIST_REQUEST:
            return { loading: true }

        case MESSAGE_LIST_SUCCESS:
            return {
                loading: false,
                message: action.payload.message,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count_read: action.payload.count_read,
                count_unread: action.payload.count_unread
            }

        case MESSAGE_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const messageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_CREATE_REQUEST:
            return { loading: true }

        case MESSAGE_CREATE_SUCCESS:
            return { loading: false, success: true }

        case MESSAGE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

