import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import FormContainer from '../../components/FormContainer'
import { login } from '../../actions/userActions'
import PageTitle from '../../components/PageTitle'
import { useNavigate } from 'react-router-dom';


function LoginScreen(props) {
  const [searchParams] = useSearchParams();
  const [usernameValue, setUsernameValue] = useState()
  const [passwordValue, setPasswordValue] = useState()
  let redirect = searchParams.get('redirect')
  const navigation = useNavigate();
  const dispatch = useDispatch()
  const userLogin = useSelector(state => state.userLogin)
  const { error, loading, userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      if (!redirect) {
        navigation('/admin')
      } else {
        navigation(`/${redirect}`);
      }
    }
  }, [navigation, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(usernameValue, passwordValue));
  }

  return (
    <FormContainer>
      <PageTitle title='Sign in' />
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>

        <Form.Group controlId='username' >
          <Form.Label>Username</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Enter Username'
            value={usernameValue ?? ""}
            onChange={(e) => setUsernameValue(e.target.value)}
          >
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type='password'
            placeholder='Enter Password'
            value={passwordValue ?? ""}
            onChange={(e) => setPasswordValue(e.target.value)}
          >
          </Form.Control>
        </Form.Group>

        <Button disabled={!usernameValue || !passwordValue} type='submit' variant='primary'>
          Sign In
        </Button>
      </Form>
    </FormContainer>
  )
}

export default LoginScreen
