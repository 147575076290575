import React from 'react'
import { isMobile} from 'react-device-detect';

function Member({ member }) {
    return (
        <div className="blog-entry p-3 fadeInLeft animated text-center mx-3">
            <span className='blog-img'>
            <img src={member.img} className="img-responsive img-fit" alt={member.name}/>
            </span>
            <h4>{member.name}</h4>
            <div className={isMobile ? 'text-mobile' : 'text-sm'}>
                <h6 className='mt-3 text-color-5'>{member.affiliation}</h6>
                <h6 className='mt-3'>{member.field_of_study}</h6>
            </div>
        </div>
    )
}

export default Member