
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PageTitle from '../../../components/PageTitle'
import { settingsClassificationAction } from '../../../actions/assetActions'

import "./styles.css";

export default function Classer() {

    const dispatch = useDispatch()
    const settingsClassification = useSelector(state => state.settingsClassification)
    const { c } = settingsClassification
    useEffect(() => {
        if (!c) {
            dispatch(settingsClassificationAction('c'));
        }
    }, [dispatch])

    return (
        <div className='mx-3'>
            <PageTitle title='Higher Classification' />
            {c &&
                <div dangerouslySetInnerHTML={{ __html: c }} className='text-justify mx-2'></div>
            }
        </div>
    );
}