import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { ackAction, updateAckAction } from '../../../actions/assetActions'
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
function AdminEditAckScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();
    const [headerv, setHeaderv] = useState('')
    const [textv, setTextv] = useState('')
    const dispatch = useDispatch()
    const ackList = useSelector(state => state.ackList)
    const { error, loading, header, text } = ackList
    const ackUpdate = useSelector(state => state.ackUpdate)
    // const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = ackUpdate
    const [msg, setMsg] = useState('')
    const [updateClicked, setUpdateClicked] = useState(false)
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        if (updateClicked) {
            setMsg('Updated successfully.')
            dispatch(ackAction())
            setUpdateClicked(false)

        } else {
            if (!header) {
                dispatch(ackAction())
            } else {
                setHeaderv(header)
                setTextv(text)
            }
        }
    }, [dispatch, updateClicked])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateAckAction({
            header: headerv,
            text: textv,
        }))
        // alert.show('Item Changed successfully')
        setUpdateClicked(true)
    }
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
        ]
    }
    return (
        <div>
            {loading
                ? (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (

                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <h1 className='mb-5'>Edit Acknowledgement</h1>
                                {/* {loadingUpdate && <Loader />}
                                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>} */}
                                {updateClicked && msg && msg.length &&
                                    <Message variant='success'>{msg}</Message>
                                }
                                <Form onSubmit={submitHandler}>

                                    <Form.Control
                                        required
                                        type='header'
                                        placeholder='Enter header'
                                        value={headerv ?? ""}
                                        onChange={(e) => setHeaderv(e.target.value)}
                                    >
                                    </Form.Control>
                                    <ReactQuill theme="snow" value={textv ?? ""} onChange={setTextv} modules={quillModules}  preserveWhitespace="true" className='my-3' />
                                    <Button type='submit' variant='primary'>
                                    <i className='fas fa-edit'></i> Update
                                    </Button>

                                </Form>
                            </Col >
                        </Row>
                    )}

        </div>


    )
}
export default AdminEditAckScreen