import React from 'react'
import { Pagination, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'

function Paginate({ pages, page, q = '', A = '', B = '', C = '', D = '', E = '', F = '', G = '', H = '', has_next, has_prev }) {

    const location = useLocation();
    const { pathname } = location;

    page = parseInt(page)
    pages = parseInt(pages)
    var startPage, endPage;
    if (pages <= 10) {
        startPage = 1;
        endPage = pages;
    } else {
        if (page <= 6) { startPage = 1; endPage = 10; } else {
            startPage = page - 5;
            endPage = page + 4;

            if (endPage > pages) {
                endPage = pages;
            }

            if (endPage - startPage < 9) {
                startPage = endPage - 9
            }


        }
    }


    return (pages > 1 && (
        <Row className='d-flex justify-content-md-start'>
            <Col className='col-md-auto'>
                <Pagination>
                    {has_prev && <LinkContainer
                        to={`${pathname}?q=${q}&A=${A}&B=${B}&C=${C}&D=${D}&E=${E}&F=${F}&G=${G}&H=${H}&page=1`}
                    >
                        <Pagination.Item><small>1</small> <i className="fas fa-angle-double-left"></i></Pagination.Item>
                    </LinkContainer>}

                    {[...Array((endPage + 1) - startPage).keys()].map((x) => (
                        <LinkContainer
                            key={startPage + x}
                            to={`${pathname}?q=${q}&A=${A}&B=${B}&C=${C}&D=${D}&E=${E}&F=${F}&G=${G}&H=${H}&page=${startPage + x}`}
                        >
                            <Pagination.Item active={startPage + x === page}>{startPage + x}</Pagination.Item>
                        </LinkContainer>
                    ))}

                    {has_next && 
                    <LinkContainer
                        to={`${pathname}?q=${q}&A=${A}&B=${B}&C=${C}&D=${D}&E=${E}&F=${F}&G=${G}&H=${H}&page=${pages}`}>
                            <Pagination.Item><i className="fas fa-angle-double-right"></i> <small>{pages}</small></Pagination.Item>
                    </LinkContainer>}
                </Pagination>
            </Col>
        </Row>
    )
    )
}

export default Paginate
