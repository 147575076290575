import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../../components/FormContainer'
import { register } from '../../../actions/userActions'
import { useAlert } from 'react-alert'
import { FaArrowCircleLeft } from 'react-icons/fa'

function RegisterScreen() {
    const [name, setName] = useState('')
    const [affiliation, setAffiliation] = useState('')
    const [email, setEmail] = useState('')
    const [study, setStudy] = useState('')
    alert = useAlert()
    const navigation = useNavigate();

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login');
        }
    }, [])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(register(name, email, study, affiliation))
        alert.show('Member registered successfully')
        navigation('/admin/members/')
    }

    return (
        <FormContainer>
            <Link to='/admin/members/'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>
            <h1>New scientific board</h1>
            <Form onSubmit={submitHandler}>

                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder='Enter name'
                        value={name ?? ""}
                        onChange={(e) => setName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Enter Email'
                        value={email ?? ""}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='study'>
                    <Form.Label>Filed of study</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter field of study'
                        value={study ?? ""}
                        onChange={(e) => setStudy(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='affiliation'>
                    <Form.Label>affiliation</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter affiliation'
                        value={affiliation ?? ""}
                        onChange={(e) => setAffiliation(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    <i className='fas fa-save'></i> Register
                </Button>

            </Form>
        </FormContainer >
    )
}

export default RegisterScreen
