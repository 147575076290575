import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listObituaries } from '../../actions/herbulotActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PageTitle from '../../components/PageTitle'
import { Accordion } from 'react-bootstrap';
import { saveAs } from "file-saver";
import { SITE_URL } from '../../constants/siteConstants';

function ObitScreen() {
    const dispatch = useDispatch()

    const obitList = useSelector(state => state.obitList)
    const { loading, error, obit } = obitList
    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    useEffect(() => {
        if (!obit.length) {
            dispatch(listObituaries())
        }
    }, [dispatch])

    const [activePanel, SetActivePanel] = useState(0)

    const panelHandler = id => {
        if (id === activePanel) {
            SetActivePanel(-1)
        } else {
            SetActivePanel(id)
        }
    }
    return (
        <div>
            {obit && obit.length ? <PageTitle title='Claude Herbulot Obituaries' /> : null}

            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (

                        <Accordion defaultActiveKey="0">
                            {obit && obit.map((item, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header className={activePanel === index ? 'active' : ''} onClick={() => panelHandler(index)}>
                                        {item.header}
                                    </Accordion.Header>
                                    <Accordion.Body className="test">
                                        {item.doc ?
                                            <p className='mt-1 mb-1  text-color-2 cursor-pointer' onClick={() => saveFile(item.doc)}>
                                                <i className='fas fa-download text-color-2'></i> See attachment
                                            </p>
                                        : null}
                                        <div dangerouslySetInnerHTML={{ __html: item.text }} className='text-justify'></div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}
        </div>)
}


export default ObitScreen