import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { archiveDetails } from '../../actions/assetActions';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import SocialMedia from '../../components/SocialMedia';
import { SITE_URL } from '../../constants/siteConstants';
import { saveAs } from 'file-saver'

function SingleArchiveScreen() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const item = useSelector(state => state.detailArchive)
    const { loading, error, archive } = item
    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    useEffect(() => {
        if (!archive || id !== archive.id) {
            dispatch(archiveDetails(id))
        }
    }, [dispatch, id])


    return (
        <React.Fragment>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <div className='mx-3'>
                            <Link to='/archive/' className='fhst-heading3'>
                                <i className="fas fa-arrow-alt-circle-left cursor-pointer"></i>
                                Archive List
                            </Link>
                            <h1 className='m-5 text-center fhst-heading2'>{archive.title} </h1>
                            <SocialMedia
                                url={SITE_URL}
                                text={archive.title}
                            />
                            {archive.doc ? 
                            <button className='btn btn-primary mt-1 mb-1 text-sm' onClick={() => saveFile(archive.doc)}>
                                <i className='fas fa-download'></i> Download
                            </button> : null}
                            <img src={archive.img} className="img-responsive w-50 mb-3" alt={archive.header} />
                            <p className='text-me text-black mb-3'>{archive.header}</p>
                            <div dangerouslySetInnerHTML={{ __html: archive.description }}></div>
                        </div>
                    )}
        </React.Fragment>

    )
}

export default SingleArchiveScreen