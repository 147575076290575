import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsCatNotesAction, updateSettingsCatNotesAction } from '../../../actions/assetActions'
import { SETTINGS_CATNOTES_UPDATE_RESET } from '../../../constants/assetConstants'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminCatNotes() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
  
    const navigation = useNavigate();
  
    const [catNote, setCatNote] = useState('')
  
    const dispatch = useDispatch()
  
    const settingsNote = useSelector(state => state.settingsCatNote)
    const { error, loading, note } = settingsNote
  
    const settingsCatNoteUpdate = useSelector(state => state.settingsCatNoteUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsCatNoteUpdate
  
    useEffect(() => {
  
      if (!userInfo || !userInfo.isAdmin) {
        navigation('/login?redirect=admin')
      }
  
      if (successUpdate) {
        dispatch({ type: SETTINGS_CATNOTES_UPDATE_RESET })
        dispatch(settingsCatNotesAction())
      } else {
        if (!note) {
          dispatch(settingsCatNotesAction())
        } else {
          setCatNote(note)
        }
      }
  
    }, [dispatch, note, successUpdate])
  
    const submitHandler = (e) => {
      e.preventDefault()
      dispatch(updateSettingsCatNotesAction({
        catNote
      }))
    }
  
    const quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
  
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
                            
  
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link'],
  
        ['clean']
      ]
    }
  
    return (
  
      <div>
        {loading || loadingUpdate
          ? (<Loader />)
          : error || errorUpdate ?
            (<Message variant='danger'>{error}</Message>)
            :
            (
              <Row>
                <Col md={12}>
                  <h1 className='mb-5'>World Catalouge notes</h1>
                  {note &&
                    <Form onSubmit={submitHandler}>
  
                      <ReactQuill theme="snow" value={catNote || ""} onChange={setCatNote} modules={quillModules}  preserveWhitespace="true" />
                      <Button type='submit' variant='primary' className='mt-2'>
                      <i className='fas fa-edit'></i> Update
                      </Button>
  
                    </Form>
                  }
                </Col >
              </Row>
            )}
      </div>
    )
  }
  

export default AdminCatNotes