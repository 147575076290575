import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { obitDetails, updateObituaries } from '../../../actions/herbulotActions'
import { OBITUARIES_UPDATE_RESET } from '../../../constants/herbulotConstants'
import { FaArrowCircleLeft } from "react-icons/fa";
import { saveAs } from "file-saver";
import { SITE_URL } from '../../../constants/siteConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
function AdminEditObit() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const { id } = useParams();
    const navigation = useNavigate();
    const [header, setHeader] = useState('')
    const [text, setText] = useState('')
    const [doc, setDoc] = useState('')
    const [uploadingDoc, setUploadingDoc] = useState(false)

    const dispatch = useDispatch()

    const obitDet = useSelector(state => state.obitDetails)
    const { error, loading, obit } = obitDet

    const obitUpdate = useSelector(state => state.obitUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = obitUpdate

    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    useEffect(() => {

        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

        if (successUpdate) {
            dispatch({ type: OBITUARIES_UPDATE_RESET })
            navigation('/admin/obituaries/')
        } else {
            if (!obit.header || obit.id != id) {
                dispatch(obitDetails(id))
            } else {
                setHeader(obit.header)
                setText(obit.text)
                setDoc(obit.doc)

            }
        }
    }, [dispatch, obit, id, successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateObituaries({
            id,
            header,
            text,
        }))
    }
    const uploadDocHandler = async (e) => {
        const file = e.target.files[0]

        const formData = new FormData()
        formData.append('doc', file)
        formData.append('id', id)
        formData.append('tbl', 'Obituaries')
        formData.append('prev', true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            const { data } = await axios.post('/api/upload/doc/', formData, config)
            setUploadingDoc(false)
            alert.show('Document Changed Successfully.')

        } catch (error) {
            setUploadingDoc(false)
        }
    }
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],


            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],

            ['clean']
        ]
    }

    return (

        <div>
            <Link to='/admin/obituaries'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>


            {loading
                ? (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (

                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <h1 className='mb-5'>Edit Obituaries</h1>
                                {loadingUpdate && <Loader />}
                                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                                <Form onSubmit={submitHandler}>

                                    <Form.Control
                                        required
                                        type='header'
                                        placeholder='Enter header'
                                        value={header ?? ""}
                                        onChange={(e) => setHeader(e.target.value)}
                                    >
                                    </Form.Control>
                                    {obit.doc ?
                                    (
                                    <div>
                                    <hr></hr>
                                <h5>Change Document</h5>
                                <p className='mt-1 mb-1 text-sm text-color-2 cursor-pointer' onClick={() => saveFile(obit.doc)}>
                                    <i className='fas fa-download text-color-2'></i> Download file
                                </p>
                                </div>)
                                : <span>Already no file is attached.</span>
                                }
                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='doc'
                                            type='file'
                                            placeholder='file'
                                            onChange={uploadDocHandler}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                    <ReactQuill theme="snow" value={text ?? ""} onChange={setText} modules={quillModules}  preserveWhitespace="true" className='my-3' />
                                    <Button type='submit' variant='primary'>
                                        <i className='fas fa-edit'></i> Update
                                    </Button>

                                </Form>
                            </Col >
                        </Row>
                    )}

        </div>


    )
}
export default AdminEditObit