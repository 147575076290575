import React from 'react'
import { Row, Col } from 'react-bootstrap'

function CatalogItemHeader({ item, count }) {
    return (
        <Row className='d-flex justify-content-md-start catalog-header'>

            <Col className="col-sm-6 col-md-auto catalog-header-s1">
                <small className="catalog-header-s2">Family: </small>
                <small> {item.family} </small>
            </Col>

            <Col className="col-sm-6 col-md-auto catalog-header-s1">
                <small className="catalog-header-s2">Subfamily: </small>
                <small> {item.sub_family} </small>
            </Col>

            {/* <Col className="col-sm-6 col-md-auto catalog-header-s1">
                <small className="catalog-header-s2">Tribe: </small>
                <small> {item.tribe} </small>
            </Col> */}

            <Col className="col-sm-6 col-md-auto catalog-header-s1">
                <small className="catalog-header-s2">Genus: </small>
                <small> <i>{item.genus}</i> {item.author}, {item.year} </small>
            </Col>
            <Col className="align-middle ml-auto mt-2 text-end">
                <small>{count}</small>
            </Col>

        </Row>
    )
}

export default CatalogItemHeader