import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listTAXAAction } from '../../actions/herbulotActions'
import PageTitle from '../../components/PageTitle'

function TaxaScreen({ type }) {
  const dispatch = useDispatch()

  const Taxa = useSelector(state => state.listTAXA)
  const { taxa } = Taxa

  useEffect(() => {
    if (!taxa || !taxa.length) {
      dispatch(listTAXAAction());
    }
  }, [dispatch])

  return (
    <div>
      <Row>
      <Col sm={12} md={6} xl={6}>
          {(taxa && taxa.named && taxa.named.length) ? <PageTitle title='Patronyms' /> : null}
          {taxa && taxa.named && taxa.named.length > 0 &&
            <div>
              <h4>Species</h4>
              {taxa.named.map(item => (
                item.category === 'species' &&
                <div className='' key={item.id}>
                <div className='' key={item.id}>
                  <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                </div>                  
                </div>
              ))}
            </div>
          }
          {taxa && taxa.named && taxa.named.length > 0 &&
            <div className='mt-4'>
              <h4>Genera</h4>
              {taxa.named.map(item => (
                item.category === 'genera' &&
                <div className='' key={item.id}>
                <div className='' key={item.id}>
                  <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                </div>                  
                </div>
              ))}
            </div>
          }
        </Col>        
        <Col sm={12} md={6} xl={6}>
          {(taxa && taxa.described && taxa.described.length) ? <PageTitle title='Taxa described' /> : null}
          {taxa && taxa.described && taxa.described.length > 0 &&
            <div>
              <h4>Species</h4>
              {taxa.described.map(item => (
                item.category === 'species' &&
                <div className='' key={item.id}>
                  <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                </div>
              ))}
            </div>
          }
          {taxa && taxa.described && taxa.described.length > 0 &&
            <div className='mt-4'>
              <h4>Genera</h4>
              {taxa.described.map(item => (
                item.category === 'genera' &&
                <div className='' key={item.id}>
                  <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                </div>
          ))}
            </div>
          }
        </Col>

      </Row>
    </div>
  )
}

export default TaxaScreen