import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { Row } from "react-bootstrap";

const ImagesGallery = ({ items }) => {
    const [images, setImages] = React.useState(null);

    React.useEffect(() => {
        setImages(
            items.map(item => ({
                original: `${item.image}`,
                thumbnail: `${item.image}`,
                description: `${item.caption}`,
            })
            )
        );
    }, []);

    return images ?
        <Row className="w-100">
            <ImageGallery
                autoPlay={true}
                slideInterval={4000}
                // thumbnailPosition={'right'}
                showThumbnails={true}
                items={images}
                lazyLoad={true}
            />
        </Row>
        : null;
};

export default ImagesGallery;

