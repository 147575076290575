import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { getUserDetails, updateAdminProfile } from '../../../actions/userActions'
import { USER_DETAILS_RESET } from '../../../constants/userConstants'
import PageTitle from '../../../components/PageTitle'
import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom';

function AdminMemberEditScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const { id } = useParams();
    alert = useAlert()
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const location = useLocation();
    const { pathname } = location;

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [affiliation, setAffiliation] = useState('')
    const [study, setStudy] = useState('')
    const [message, setMessage] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [selectedFile, setSelectedFile] = useState('')
    const [isFilePicked, setIsFilePicked] = useState(false);


    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login');
        } else {
            if (!userDetails || !userDetails.user.id || userDetails.user.id != id) {
                dispatch(getUserDetails(id))
            } else {
                setName(userDetails.user.name)
                setEmail(userDetails.user.email)
                setAffiliation(userDetails.user.affiliation)
                setStudy(userDetails.user.field_of_study)
            }

        }
    }, [dispatch, id, pathname, isFilePicked, messageSuccess, userDetails.user.id])

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(updateAdminProfile({
            'id': user.id,
            'name': name,
            'email': email,
            'affiliation': affiliation,
            'field_of_study': study
        }))
        alert.show('Member edited successfully')
        setMessage('');
        setMessageSuccess('Profile updated successfully');
        dispatch({ type: USER_DETAILS_RESET })
        navigation('/admin/members')

}
async function deleteImageHandler() {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    const { data } = await axios.get(
        `/api/image/clear/Profile/${user.id}/`,
        config
    )
    alert.error('Image deleted successfully.')
    if (data.success) {
        dispatch({
            type: USER_DETAILS_RESET,
            payload: data
        })
    }
}

const changeImageHandler = (e) => {
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true);
}

async function submitImageHandler() {
    if (selectedFile.size > 102400) {
        alert.error('Image size should be less than 100KB')
        return
    }
    const formData = new FormData();

    formData.append('tbl', 'profile');
    formData.append('id', user.id);
    formData.append('img', selectedFile);
    formData.append('prev', false);

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    await axios.post(
        '/api/upload/image/',
        formData,
        config
    )
    alert.show('Image changed successfully')
}

return (

    <div>
        {loading
            ? (<Loader />)
            : error ?
                (<Message variant='danger'>{error}</Message>)
                :
                (
                    <Row>
                        {messageSuccess && <Message variant='success'>{messageSuccess}</Message>}
                        <Col sm={12} md={6} lg={6}>
                            <PageTitle title="Edit profile"></PageTitle>

                            {message && <Message variant='danger'>{message}</Message>}
                            {error && <Message variant='danger'>{error}</Message>}
                            {loading && <Loader />}
                            <Button onClick={() => navigation('/admin/members/')}><i className="fas fa-arrow-left"></i> Back</Button>
                            {user && user.id &&
                                <Form onSubmit={submitHandler}>
                                    <Form.Group as={Row} controlId='name' className="form-group">
                                        <Form.Label column sm="2">Name</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                required
                                                type='name'
                                                placeholder='Enter name'
                                                value={name ?? ""}
                                                onChange={(e) => setName(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId='email' className="form-group">
                                        <Form.Label column sm="2">Email Address</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type='email'
                                                placeholder='Enter Email'
                                                value={email ?? ""}
                                                onChange={(e) => setEmail(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='affiliation' className="form-group">
                                        <Form.Label column sm="2">Affiliation</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type='text'
                                                placeholder='Enter Affiliation'
                                                value={affiliation ?? ""}
                                                onChange={(e) => setAffiliation(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='study' className="form-group">
                                        <Form.Label column sm="2">Field of study</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type='text'
                                                placeholder='Enter Field of study'
                                                value={study ?? ""}
                                                onChange={(e) => setStudy(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Button type='submit' variant='primary'>
                                    <i className='fas fa-edit'></i> Update
                                    </Button>
                                </Form>
                            }
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            {loading
                                ? (<Loader />)
                                : error
                                    ? (<Message variant='danger'>{error}</Message>)
                                    : (user && user.id &&
                                        <div>
                                            {user.img &&
                                                <div className="project  fadeInLeft animated" Style={user.img ? 'background-image: url(' + user.img + '); height:350px' : null}>
                                                    <div className="desc">
                                                        <div className="con">
                                                            <h3><span onClick={deleteImageHandler} className="tag tag--pill">Delete Image</span></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div>
                                                <h3>Upload Image</h3>
                                                <Form onSubmit={submitImageHandler}>
                                                    <Form.Group controlId='fileUpload'>
                                                        <Form.Control
                                                            required
                                                            type='file'
                                                            placeholder='image'
                                                            onChange={changeImageHandler}
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Button type='submit' variant='primary'>
                                                    <i className='fas fa-save'></i> Save Image
                                                    </Button>
                                                </Form>
                                                {isFilePicked ? (
                                                    <div>
                                                        <p>Filename: {selectedFile.name}</p>
                                                        <p>Filetype: {selectedFile.type}</p>
                                                        <p className={selectedFile.size > 102400 ? "error-data" : null}>
                                                            Size: {(selectedFile.size / 1024).toFixed()} KB
                                                            {selectedFile.size > 102400 && <div className='small'>image should be smaller than 100KB</div>}
                                                        </p>
                                                        <p>
                                                            lastModifiedDate:{' '}
                                                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </div>

                                    )}
                        </Col>
                    </Row>
                )
        }
    </div >
)
}

export default AdminMemberEditScreen