import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import PageTitle from '../../../components/PageTitle'
import ImageGallery from '../../../components/ImageGallery';
import { catalogSingleAction } from '../../../actions/phylogenyActions'
import { saveAs } from "file-saver";
import { SITE_URL } from '../../../constants/siteConstants';
import { isMobile } from 'react-device-detect'

function SingleCatalogScreen() {
    const { id } = useParams();
    const { tbl } = useParams();

    const navigation = useNavigate();
    const dispatch = useDispatch();
    const Det = useSelector(state => state.singleCatalog)
    const { error, loading, catalog } = Det
    useEffect(() => {
        if (!id || !tbl) {
            navigation('/geometridae/Catalogue/')
        }
        dispatch(catalogSingleAction(tbl, id))
    }, [dispatch, id])


    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    }

    return (
        <div>
            <i className='fas fa-arrow-alt-circle-left text-me cursor-pointer m-2'
                onClick={() => navigation('/geometridae/Catalogue/')}> World Catalogue</i>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>) :
                    (catalog &&
                        <div>
                            {!catalog.junior_synonym ?
                                <div className='text-md mb-5'>{
                                    catalog.genus === catalog.original_genus ?
                                        <span className="p-1 mb-2 mr-1">
                                            <i>{catalog.genus} {catalog.senior_species} {catalog.senior_sub_species}</i> {catalog.senior_species_author}, {catalog.senior_species_year}
                                        </span>
                                        :
                                        <span className="p-1 mb-2">
                                            <i>{catalog.genus} {catalog.senior_species} {catalog.senior_sub_species}</i> ({catalog.senior_species_author}, {catalog.senior_species_year}) (<i>{catalog.original_genus}</i>)
                                        </span>
                                } </div>
                                :
                                <div className='text-md mb-5'>{
                                    catalog.genus === catalog.original_genus ?
                                        <span className="p-1 mx-3">
                                            = <i>{catalog.species} {catalog.subspecies}</i> {catalog.species_author}, {catalog.species_year}
                                        </span>
                                        :
                                        <span className="p-1 mx-3">
                                            = <i>{catalog.species} {catalog.subspecies}</i> ({catalog.species_author}, {catalog.species_year}) (<i>{catalog.original_genus}</i>)
                                        </span>
                                } </div>
                            }


                            <Row>
                                <Col sm={8} md={8} xl={8}>
                                    {catalog.album && catalog.album.length ?
                                        <Row Style='margin-bottom: 120px'>
                                            <ImageGallery items={catalog.album} />
                                         </Row>
                                        : null}
                                    <Row className={isMobile ? 'text-mobile' : null}>
                                        {/* <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Superfamily:</span> <span className='text-color-5'>geometridae</span>
                                        </Col> */}
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Family:</span> <span className='text-color-5'>{catalog.family}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Subfamily:</span> <span className='text-color-5'>{catalog.sub_family}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Tribe:</span> <span className='text-color-5'>{catalog.tribe}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Genus:</span> <span className='text-color-5'>{catalog.genus}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Author:</span> <span className='text-color-5'>{catalog.author}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Year:</span> <span className='text-color-5'>{catalog.year}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Senior species:</span> <span className='text-color-5'>{catalog.senior_species}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Senior sub species:</span> <span className='text-color-5'>{catalog.senior_sub_species}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Senior species author:</span> <span className='text-color-5'>{catalog.senior_species_author}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Senior species year:</span> <span className='text-color-5'>{catalog.senior_species_year}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Species:</span> <span className='text-color-5'>{catalog.species}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Subspecies:</span> <span className='text-color-5'>{catalog.subspecies}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Species author:</span> <span className='text-color-5'>{catalog.species_author}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Species year:</span> <span className='text-color-5'>{catalog.species_year}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Species in author:</span> <span className='text-color-5'>{catalog.species_in_author}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Original genus:</span> <span className='text-color-5'>{catalog.original_genus}</span>
                                        </Col>
                                        <Col sm={12} md={12} lg={6} className='mb-2'>
                                            <span>Country:</span> <span className='text-color-5'>{catalog.country}</span>
                                        </Col>
                                    </Row>

                                    {catalog.img &&
                                        <Row>
                                            <Col>
                                                <img src={catalog.img} className="img-responsive w-50 mb-3" alt='' />
                                            </Col>
                                        </Row>}
                                    {catalog.note &&
                                        <div dangerouslySetInnerHTML={{ __html: catalog.note }} className='text-justify'></div>}
                                </Col>
                                <Col sm={4} md={4} xl={4}>
                                    {catalog.docfile &&
                                        <div className="card m-3">
                                            <div className="card-header">
                                                <h4 className='fhst-heading2'>Document</h4>
                                            </div>
                                            <div className='px-4 mb-1'>
                                                <p className={isMobile? 'mt-1 mb-1 text-sm-mobile text-color-2 cursor-pointer'
                                                : 'mt-1 mb-1 text-sm text-color-2 cursor-pointer' } onClick={() => saveFile(catalog.docfile)}>
                                                    <i className='fas fa-download text-color-2'></i> Download file
                                                </p>
                                            </div>
                                        </div>}

                                    {catalog.synonyms && catalog.synonyms.length ?
                                        <div className="card m-3">
                                            <div className="card-header">
                                                <h4 className='fhst-heading2'>Synonyms</h4>
                                            </div>
                                            <div className='mb-1'>
                                                {catalog.synonyms.map(item => (
                                                    <Link to={`/geometridae/Catalogue/CatalogY/${item.id}/`}>
                                                        {item.genus === item.original_genus ?
                                                            <div className="p-1 mx-3">
                                                                = <i>{item.species} {item.subspecies}</i> {item.species_author}, {item.species_year}
                                                            </div>
                                                            :
                                                            <div className="p-1 mx-3">
                                                                = <i>{item.species} {item.subspecies}</i> ({item.species_author}, {item.species_year}) (<i>{item.original_genus}</i>)
                                                            </div>
                                                        }
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                        : null}
                                    {catalog.junior_synonym ?
                                        <div className="card m-3">
                                            <div className="card-header">
                                                <h4 className='fhst-heading2'>junior</h4>
                                            </div>
                                            <div className='mb-1'>
                                                <Link to={`/geometridae/Catalogue/CatalogN/${catalog.junior_id}/`}>
                                                    {catalog.genus === catalog.original_genus ?
                                                        <span className="p-1 mb-2 mr-1">
                                                            <i>{catalog.genus} {catalog.senior_species} {catalog.senior_sub_species}</i> {catalog.senior_species_author}, {catalog.senior_species_year}
                                                        </span>
                                                        :
                                                        <span className="p-1 mb-2">
                                                            <i>{catalog.genus} {catalog.senior_species} {catalog.senior_sub_species}</i> ({catalog.senior_species_author}, {catalog.senior_species_year}) (<i>{catalog.original_genus}</i>)
                                                        </span>}
                                                </Link>
                                            </div>
                                        </div>
                                        : null}

                                    <div className="card m-3">
                                        <div className="card-header">
                                            <h4 className='fhst-heading2'>Classification</h4>
                                        </div>
                                        <div className={isMobile ? 'text-mobile-sm px-4 mb-1' : 'text-sm px-4 mb-1'}>
                                            <Row>
                                                <Col md={4}>Family</Col>
                                                <Col md={8} className="text-end">
                                                    <Link to={`/geometridae/Catalogue/?A=&B=${catalog.family}&C=&D=&E=&F=&G=&H=all`}>
                                                        {catalog.family}
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>Subfamily</Col>
                                                <Col md={8} className="text-end">
                                                    <Link to={`/geometridae/Catalogue/?A=&B=&C=${catalog.sub_family}&D=&E=&F=&G=&H=all`}>
                                                        {catalog.sub_family}
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>Tribe</Col>
                                                <Col md={8} className="text-end">
                                                    <Link to={`/geometridae/Catalogue/?A=&B=&C=&D=${catalog.tribe}&E=&F=&G=&H=all`}>
                                                        {catalog.tribe}
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>Genus</Col>
                                                <Col md={8} className="text-end">
                                                    <Link to={`/geometridae/Catalogue/?A=&B=&C=&D=&E=${catalog.genus}&F=&G=&H=all`}>
                                                        {catalog.genus}
                                                    </Link>
                                                </Col>
                                            </Row>

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
        </div>
    )
}

export default SingleCatalogScreen