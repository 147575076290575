import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { newsDetails, updateNews } from '../../../actions/assetActions'
import { NEWS_UPDATE_RESET } from '../../../constants/assetConstants'
import { FaArrowCircleLeft } from "react-icons/fa";
import ManageImage from '../../../components/ManageImage'
import { useAlert } from 'react-alert'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminNewsEditScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()

    const { id } = useParams();
    const navigation = useNavigate();
    const [header, setHeader] = useState('')
    const [abstract, setAbstract] = useState('')
    const [img, setImg] = useState('')
    const [body, setBody] = useState('')
    const [reference, setReference] = useState('')

    const [caption, setCaption] = useState('')

    const [changedImage, setChangedImage] = useState(false)


    const [uploadingImage, setUploadingImage] = useState(false)
    const [uploadingGallery, setUploadingGallery] = useState(false)

    const dispatch = useDispatch()

    const newsDet = useSelector(state => state.detailNews)
    const { error, loading, news } = newsDet

    const newsUpdate = useSelector(state => state.newsUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = newsUpdate

    useEffect(() => {

        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

        if (successUpdate) {
            dispatch({ type: NEWS_UPDATE_RESET })
            navigation('/admin/news/')
        } else {
            if (!news.header || news.id != id || changedImage) {
                dispatch(newsDetails(id))
                setChangedImage(false)
            } else {
                setHeader(news.header)
                setAbstract(news.abstract)
                setImg(news.img)
                setBody(news.body)
                setReference(news.reference)
            }
        }
    }, [dispatch, news, id, successUpdate, changedImage])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateNews({
            id,
            header,
            abstract,
            img,
            body,
            reference,
        }))
        alert.show('News changed successfully.')
    }

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]

        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('id', id)
        formData.append('tbl', 'News')
        formData.append('prev', true)

        setUploadingImage(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/upload/image/', formData, config)


            setImg(data)
            setUploadingImage(false)
            setChangedImage(true)
            alert.show('Image changed successfully')

        } catch (error) {
            setUploadingImage(false)
        }
    }

    const uploadGalleryHandler = async (e) => {

        const file = e.target.files[0]
        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('title', caption)

        setUploadingGallery(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post(`/api/assets/news/images/${id}/`, formData, config)


            setImg(data)
            setUploadingGallery(false)
            setChangedImage(true)
            alert.show('Image uploaded successfully')
        } catch (error) {
            setUploadingGallery(false)
        }
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],

            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    }
    const changerHandler = () => {
        setChangedImage(true)
    }
    return (

        <div>
            <Link to='/admin/news'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>


            {loading
                ? (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (

                        <Row>
                            <Col sm={12} md={6} lg={8}>
                                <h1 className='mb-5'>Edit News</h1>
                                {loadingUpdate && <Loader />}
                                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                                {news && news.id ?
                                    <Form onSubmit={submitHandler}>

                                        <Form.Group as={Row} controlId='header' className='mb-2'>
                                            <Form.Label column sm="2">Header</Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    required
                                                    type='header'
                                                    placeholder='Enter header'
                                                    value={header ?? ""}
                                                    onChange={(e) => setHeader(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId='abstract' className='mb-2'>
                                            <Form.Label column sm="2">Abstract</Form.Label>
                                            <Col sm="10">
                                                <Form.Control as='textarea'
                                                    className='h-100'
                                                    required
                                                    rows='5'
                                                    placeholder='Enter abstract'
                                                    value={abstract ?? ""}
                                                    onChange={(e) => setAbstract(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId='body' className='mb-2'>
                                            <Form.Label column sm="2">Body</Form.Label>
                                            <Col sm="10">
                                                <ReactQuill theme="snow" value={body ?? ""} onChange={setBody} modules={quillModules}  preserveWhitespace="true" />

                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId='reference' className='mb-2'>
                                            <Form.Label column sm="2">Reference</Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Enter reference'
                                                    value={reference ?? ""}
                                                    onChange={(e) => setReference(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Button type='submit' variant='primary'>
                                        <i className='fas fa-edit'></i> Update
                                        </Button>

                                    </Form>
                                    : null}
                            </Col >
                            <Col sm={12} md={6} lg={4}>
                                <h5>Change Main Image</h5>
                                <Image src={news.img} className='w-100' />
                                <div>

                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='mainimg'
                                            type='file'
                                            placeholder='image'
                                            onChange={uploadFileHandler}
                                        >
                                        </Form.Control>
                                        {uploadingImage && <Loader />}
                                    </Form.Group>

                                </div>

                            </Col>
                        </Row>
                    )}
            <hr></hr>
            <h1>Edit Photo Gallery</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {news.album && news.album.length > 0 &&
                        <div>
                            {news.album.map(item => (
                                <ManageImage item={item} tbl={'AlbumNews'} token={userInfo.token} changerHandler={changerHandler} key={item.id} />
                            ))}
                        </div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Add new image to photo gallery</h5>

                    <Form.Control
                        className='mb-3'
                        type='text'
                        placeholder='Enter caption'
                        value={caption ?? ""}
                        onChange={(e) => setCaption(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        id='galleryimg'
                        type='file'
                        placeholder='image'
                        onChange={uploadGalleryHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>
        </div>


    )
}

export default AdminNewsEditScreen