import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsClassificationAction, updateSettingsClassificationAction } from '../../../actions/assetActions'
import { SETTINGS_CLASSIFICATION_UPDATE_RESET } from '../../../constants/assetConstants'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminEditClassification() {
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const navigation = useNavigate();

  const [classification, setClassification] = useState('')

  const dispatch = useDispatch()

  const settingsClassification = useSelector(state => state.settingsClassification)
  const { error, loading, c } = settingsClassification

  const settingsClassificationUpdate = useSelector(state => state.settingsClassificationUpdate)
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsClassificationUpdate

  useEffect(() => {

    if (!userInfo || !userInfo.isAdmin) {
      navigation('/login?redirect=admin')
    }

    if (successUpdate) {
      dispatch({ type: SETTINGS_CLASSIFICATION_UPDATE_RESET })
      dispatch(settingsClassificationAction())
    } else {
      if (!c) {
        dispatch(settingsClassificationAction())
      } else {
        setClassification(c)
      }
    }

  }, [dispatch, c, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateSettingsClassificationAction({
      classification
    }))
  }

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['code-block'],

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
                          

      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link', 'image'],

      ['clean']
    ]
  }

  return (

    <div>
      {loading || loadingUpdate
        ? (<Loader />)
        : error || errorUpdate ?
          (<Message variant='danger'>{error}</Message>)
          :
          (
            <Row>
              <Col md={12}>
                <h1 className='mb-5'>Higher Classification notes</h1>
                {c &&
                  <Form onSubmit={submitHandler}>

                    <ReactQuill theme="snow" value={classification || ""} onChange={setClassification} modules={quillModules}  preserveWhitespace="true" />
                    <Button type='submit' variant='primary' className='mt-2'>
                    <i className='fas fa-edit'></i> Update
                    </Button>

                  </Form>
                }
              </Col >
            </Row>
          )}
    </div>
  )
}


export default AdminEditClassification