import {
    OBITUARIES_LIST_REQUEST,
    OBITUARIES_LIST_SUCCESS,
    OBITUARIES_LIST_FAIL,

    OBITUARIES_DETAILS_REQUEST,
    OBITUARIES_DETAILS_SUCCESS,
    OBITUARIES_DETAILS_FAIL,

    OBITUARIES_CREATE_REQUEST,
    OBITUARIES_CREATE_SUCCESS,
    OBITUARIES_CREATE_RESET,
    OBITUARIES_CREATE_FAIL,

    OBITUARIES_UPDATE_REQUEST,
    OBITUARIES_UPDATE_SUCCESS,
    OBITUARIES_UPDATE_RESET,
    OBITUARIES_UPDATE_FAIL,

    HERBULOT_ARTICLE_REQUEST,
    HERBULOT_ARTICLE_SUCCESS,
    HERBULOT_ARTICLE_FAIL,

    HERBULOT_ARTICLE_CREATE_REQUEST,
    HERBULOT_ARTICLE_CREATE_SUCCESS,
    HERBULOT_ARTICLE_CREATE_RESET,
    HERBULOT_ARTICLE_CREATE_FAIL,

    HERBULOT_ARTICLE_UPDATE_REQUEST,
    HERBULOT_ARTICLE_UPDATE_SUCCESS,
    HERBULOT_ARTICLE_UPDATE_FAIL,
    HERBULOT_ARTICLE_UPDATE_RESET,

    TAXA_LIST_REQUEST,
    TAXA_LIST_SUCCESS,
    TAXA_LIST_FAIL,

    TAXA_CREATE_REQUEST,
    TAXA_CREATE_SUCCESS,
    TAXA_CREATE_RESET,
    TAXA_CREATE_FAIL,
} from '../constants/herbulotConstants'

// -------------------------------------------- OBITUARIES 

export const listObituariesReducer = (state = { obit: [] }, action) => {
    switch (action.type) {
        case OBITUARIES_LIST_REQUEST:
            return { loading: true }

        case OBITUARIES_LIST_SUCCESS:
            return {
                loading: false,
                success:true,
                obit: action.payload
            }

        case OBITUARIES_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const obitDetailsReducer = (state = { obit: '' }, action) => {
    switch (action.type) {
        case OBITUARIES_DETAILS_REQUEST:
            return { loading: true, ...state }

        case OBITUARIES_DETAILS_SUCCESS:
            return { loading: false, obit: action.payload }

        case OBITUARIES_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state

    }
}


export const obitCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case OBITUARIES_CREATE_REQUEST:
            return { loading: true }

        case OBITUARIES_CREATE_SUCCESS:
            return { loading: false, success: true }

        case OBITUARIES_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case OBITUARIES_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const obitUpdateReducer = (state = { obit: {} }, action) => {
    switch (action.type) {
        case OBITUARIES_UPDATE_REQUEST:
            return { loading: true }

        case OBITUARIES_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case OBITUARIES_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case OBITUARIES_UPDATE_RESET:
            return { obit: {} }

        default:
            return state
    }
}


// -------------------------------------------- ARTICLES

export const listHerbulotArticlerReducer = (state = { article: [] }, action) => {
    switch (action.type) {
        case HERBULOT_ARTICLE_REQUEST:
            return { loading: true }

        case HERBULOT_ARTICLE_SUCCESS:
            return {
                loading: false,
                articles: action.payload.articles,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case HERBULOT_ARTICLE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const herbulotArticleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case HERBULOT_ARTICLE_CREATE_REQUEST:
            return { loading: true }

        case HERBULOT_ARTICLE_CREATE_SUCCESS:
            return { loading: false, success: true }

        case HERBULOT_ARTICLE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case HERBULOT_ARTICLE_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const HerbulotArticleUpdateReducer = (state = { article: {} }, action) => {
    switch (action.type) {
        case HERBULOT_ARTICLE_UPDATE_REQUEST:
            return { loading: true }

        case HERBULOT_ARTICLE_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case HERBULOT_ARTICLE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case HERBULOT_ARTICLE_UPDATE_RESET:
            return { article: {} }

        default:
            return state
    }
}

// -------------------------------------------- TAXA

export const listTAXAReducer = (state = { taxa: [] }, action) => {
    switch (action.type) {
        case TAXA_LIST_REQUEST:
            return { loading: true }

        case TAXA_LIST_SUCCESS:
            return {
                loading: false,
                taxa: action.payload,
            }

        case TAXA_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const TAXACreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TAXA_CREATE_REQUEST:
            return { loading: true }

        case TAXA_CREATE_SUCCESS:
            return { loading: false, success: true }

        case TAXA_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TAXA_CREATE_RESET:
            return {}

        default:
            return state
    }
}