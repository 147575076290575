import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createNewsAction } from '../../../actions/assetActions'
import { FaArrowCircleLeft } from "react-icons/fa";
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminNewsAddScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();

    const [header, setHeader] = useState('')
    const [abstract, setAbstract] = useState('')
    const [body, setBody] = useState('')
    const [reference, setReference] = useState('')

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createNewsAction({
            header,
            abstract,
            body,
            reference,
        }))
        alert.show('News created successfully')
        navigation('/admin/news/')
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
                                

            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],

            ['clean']
        ]
    }


    const newsCreate = useSelector(state => state.newsCreate)
    const { success: successCreate } = newsCreate

    useEffect(() => {

        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

    }, [])

    return (
        <div>
            <Link to='/admin/news'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>
            <Row>
                <Col sm={12} md={6} lg={8}>
                    <h1 className='mb-5'>Add News</h1>
                    <Form onSubmit={submitHandler}>

                        <Form.Group as={Row} controlId='header' className='mb-2'>
                            <Form.Label column sm="2">Header</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    required
                                    type='header'
                                    placeholder='Enter header'
                                    value={header ?? ""}
                                    onChange={(e) => setHeader(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='abstract' className='mb-2'>
                            <Form.Label column sm="2">Abstract</Form.Label>
                            <Col sm="10">
                                <Form.Control as='textarea'
                                    className='h-100'
                                    required
                                    rows='5'
                                    placeholder='Enter abstract'
                                    value={abstract ?? ""}
                                    onChange={(e) => setAbstract(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='body' className='mb-2'>
                            <Form.Label column sm="2">Body</Form.Label>
                            <Col sm="10">
                                <ReactQuill theme="snow" value={body ?? ""} onChange={setBody} modules={quillModules}  preserveWhitespace="true" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='reference' className='mb-2'>
                            <Form.Label column sm="2">Reference</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type='text'
                                    placeholder='Enter reference'
                                    value={reference ?? ""}
                                    onChange={(e) => setReference(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Button type='submit' variant='primary'>
                        <i className='fas fa-plus'></i> Submit
                        </Button>

                    </Form>
                </Col >

            </Row>
        </div >
    )
}

export default AdminNewsAddScreen