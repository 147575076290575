import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { settingsMundiAction } from '../../../actions/assetActions'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import PageTitle from '../../../components/PageTitle'

function MundiScreen() {
    const settingsMundi = useSelector(state => state.settingsMundi)
    const { loading, error, m } = settingsMundi
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (!m) {
            dispatch(settingsMundiAction());
        }
    }, [dispatch])

    return (
        <div className='mx-3'>
            <PageTitle title={'Geometridae Mundi'}></PageTitle>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>) :
                    <div>{
                    (m && <div dangerouslySetInnerHTML={{ __html: m }} className='text-justify mx-2'></div>
                    )}
                    </div>
                    }
        </div>
    )
}

export default MundiScreen