export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const SCIENTIFIC_BOARD_REQUEST = 'SCIENTIFIC_BOARD_REQUEST'
export const SCIENTIFIC_BOARD_SUCCESS = 'SCIENTIFIC_BOARD_SUCCESS'
export const SCIENTIFIC_BOARD_FAIL = 'SCIENTIFIC_BOARD_FAIL'

export const HERBULOTIONS_REQUEST = 'HERBULOTIONS_REQUEST'
export const HERBULOTIONS_SUCCESS = 'HERBULOTIONS_SUCCESS'
export const HERBULOTIONS_FAIL = 'HERBULOTIONS_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const ADMIN_UPDATE_PROFILE_REQUEST = 'ADMIN_UPDATE_PROFILE_REQUEST'
export const ADMIN_UPDATE_PROFILE_SUCCESS = 'ADMIN_UPDATE_PROFILE_SUCCESS'
export const ADMIN_UPDATE_PROFILE_FAIL = 'ADMIN_UPDATE_PROFILE_FAIL'
export const ADMIN_UPDATE_PROFILE_RESET = 'ADMIN_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'


export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'


export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'


export const USER_SUBSCRIBE_REQUEST = 'USER_SUBSCRIBE_REQUEST'
export const USER_SUBSCRIBE_SUCCESS = 'USER_SUBSCRIBE_SUCCESS'
export const USER_SUBSCRIBE_FAIL = 'USER_SUBSCRIBE_FAIL'
export const USER_SUBSCRIBE_RESET = 'USER_SUBSCRIBE_RESET'

export const LIST_SUBSCRIBE_REQUEST = 'LIST_SUBSCRIBE_REQUEST'
export const LIST_SUBSCRIBE_SUCCESS = 'LIST_SUBSCRIBE_SUCCESS'
export const LIST_SUBSCRIBE_FAIL = 'LIST_SUBSCRIBE_FAIL'
export const LIST_SUBSCRIBE_RESET = 'LIST_SUBSCRIBE_RESET'

export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'
export const MESSAGE_LIST_FAIL = 'MESSAGE_LIST_FAIL'

export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST'
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS'
export const MESSAGE_CREATE_FAIL = 'MESSAGE_CREATE_FAIL'

export const MESSAGE_UPDATE_REQUEST = 'MESSAGE_UPDATE_REQUEST'
export const MESSAGE_UPDATE_SUCCESS = 'MESSAGE_UPDATE_SUCCESS'
export const MESSAGE_UPDATE_FAIL = 'MESSAGE_UPDATE_FAIL'
export const MESSAGE_UPDATE_RESET = 'MESSAGE_UPDATE_RESET'
