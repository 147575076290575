export const OBITUARIES_CREATE_REQUEST = 'OBITUARIES_CREATE_REQUEST'
export const OBITUARIES_CREATE_SUCCESS = 'OBITUARIES_CREATE_SUCCESS'
export const OBITUARIES_CREATE_FAIL = 'OBITUARIES_CREATE_FAIL'
export const OBITUARIES_CREATE_RESET = 'OBITUARIES_CREATE_RESET'

export const OBITUARIES_LIST_REQUEST = 'OBITUARIES_LIST_REQUEST'
export const OBITUARIES_LIST_SUCCESS = 'OBITUARIES_LIST_SUCCESS'
export const OBITUARIES_LIST_FAIL = 'OBITUARIES_LIST_FAIL'
export const OBITUARIES_LIST_RESET = 'OBITUARIES_LIST_RESET'

export const OBITUARIES_DETAILS_REQUEST = 'OBITUARIES_DETAILS_REQUEST'
export const OBITUARIES_DETAILS_SUCCESS = 'OBITUARIES_DETAILS_SUCCESS'
export const OBITUARIES_DETAILS_FAIL = 'OBITUARIES_DETAILS_FAIL'
export const OBITUARIES_DETAILS_RESET = 'OBITUARIES_DETAILS_RESET'

export const OBITUARIES_UPDATE_REQUEST = 'OBITUARIES_UPDATE_REQUEST'
export const OBITUARIES_UPDATE_SUCCESS = 'OBITUARIES_UPDATE_SUCCESS'
export const OBITUARIES_UPDATE_FAIL = 'OBITUARIES_UPDATE_FAIL'
export const OBITUARIES_UPDATE_RESET = 'OBITUARIES_UPDATE_RESET'

export const HERBULOT_ARTICLE_REQUEST = 'HERBULOT_ARTICLE_REQUEST'
export const HERBULOT_ARTICLE_SUCCESS = 'HERBULOT_ARTICLE_SUCCESS'
export const HERBULOT_ARTICLE_FAIL = 'HERBULOT_ARTICLE_FAIL'

export const HERBULOT_ARTICLE_UPDATE_REQUEST = 'HERBULOT_ARTICLE_UPDATE_REQUEST'
export const HERBULOT_ARTICLE_UPDATE_SUCCESS = 'HERBULOT_ARTICLE_UPDATE_SUCCESS'
export const HERBULOT_ARTICLE_UPDATE_FAIL = 'HERBULOT_ARTICLE_UPDATE_FAIL'
export const HERBULOT_ARTICLE_UPDATE_RESET = 'HERBULOT_ARTICLE_UPDATE_RESET'

export const HERBULOT_ARTICLE_CREATE_REQUEST = 'HERBULOT_ARTICLE_CREATE_REQUEST'
export const HERBULOT_ARTICLE_CREATE_SUCCESS = 'HERBULOT_ARTICLE_CREATE_SUCCESS'
export const HERBULOT_ARTICLE_CREATE_FAIL = 'HERBULOT_ARTICLE_CREATE_FAIL'
export const HERBULOT_ARTICLE_CREATE_RESET = 'HERBULOT_ARTICLE_CREATE_RESET'

export const TAXA_LIST_REQUEST = 'TAXA_LIST_REQUEST'
export const TAXA_LIST_SUCCESS = 'TAXA_LIST_SUCCESS'
export const TAXA_LIST_FAIL = 'TAXA_LIST_FAIL'
export const TAXA_LIST_RESET = 'TAXA_LIST_RESET'

export const TAXA_CREATE_REQUEST = 'TAXA_CREATE_REQUEST'
export const TAXA_CREATE_SUCCESS = 'TAXA_CREATE_SUCCESS'
export const TAXA_CREATE_FAIL = 'TAXA_CREATE_FAIL'
export const TAXA_CREATE_RESET = 'TAXA_CREATE_RESET'


