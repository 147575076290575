import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { Form, Button, Col, Row } from 'react-bootstrap'
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateCaptionAction } from '../actions/assetActions';
import '../screens/admin/editor.css'


function ManageImage({ item, tbl, token, changerHandler }) {
    alert = useAlert()
    async function deleteAlbumHandler(tbl, id, token, changerHandler) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            await axios.delete(
                `/api/delete/${tbl}/${id}/`,
                config
            )
        }
        alert.error('Image deleted')
        changerHandler()
    }
    const [caption, setCaption] = useState('')

    useEffect(() => {
        setCaption(item.caption)
    }, [])

    const quillModules = {
        toolbar: [
            ['bold', 'italic'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
        ]
    }
    const dispatch = useDispatch()
    const changeCaptionHandler = (tbl, id, changerHandler) => {
        dispatch(updateCaptionAction({
            tbl,
            id,
            caption
        }))
        alert.show('caption changed')
    }
    return (
        <Row>
            <Col>
                <img width='300px' className='image-responsive m-2' src={item.image} alt='' />
                <Button className='btn btn-danger m-2' onClick={() => deleteAlbumHandler(tbl, item.id, token, changerHandler)}><i className='fas fa-trash'></i> Delete Image</Button>
            </Col>
            <Col className='mb-2'>
                {tbl === 'MainAlbum' ?
                    <ReactQuill theme="snow" value={caption || ""} onChange={setCaption} modules={quillModules}  preserveWhitespace="true" className='m-2' />
                    :
                    <Form.Group className='mb-2'>
                        <Form.Control as='textarea'
                            className='h-100'
                            rows='8'
                            placeholder='Enter Caption'
                            value={caption ?? ""}
                            onChange={(e) => setCaption(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                }
                <Button className='btn btn-success m-2' onClick={() => changeCaptionHandler(tbl, item.id, changerHandler)}>
                    <i className='fas fa-edit'></i> Change Caption
                </Button>
            </Col>
            <hr />
        </Row>
    )
}

export default ManageImage