import React, { useState } from "react";
import TreeMenu from "react-simple-tree-menu";
import dataTree from './DataTree'
import {
    Alert,
    Button,
    Form,
    ListGroup,
    Spinner,
    Badge
} from "react-bootstrap";
import "./SimpleTreeMenuStyle.scss";


import { FaSearch, FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import {
    AiOutlineQuestionCircle
} from "react-icons/ai";
import { MdChatBubbleOutline } from "react-icons/md";

import { Link } from "react-router-dom";

let treeDataArray = dataTree

const ToggleIcon = ({ on, nodetype }) => {
    switch (nodetype) {
        case "category":
            return (
                <>
                    {on ? (
                        <FaMinusSquare className="icon text-color-5" />
                    ) : (
                        <FaPlusSquare className="icon text-color-5" />
                    )}
                </>
            );
        default:
            return null;
    }
};

const BoardIcon = ({ nodetype }) => {
    switch (nodetype) {
        case "forum":
            return <MdChatBubbleOutline className="icon" />;
        case "blog":
            return <FaSearch className="icon text-color-5" />
        case "tkb":
            return <AiOutlineQuestionCircle className="icon" />;
        default:
            return null;
    }
};

const ListItem = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    count,
    tree,
    type,
    searchTerm,
    openNodes,
    toggleNode,
    matchSearch,
    focused,
    setChildNodeLimitHash,
    ...props
}) => {
    const LEVEL_INDENT = 25;

    return (
        <ListGroup.Item
            {...props}
            key={label}
            variant="light"
            className="tree-item"
            style={{
                marginLeft: searchTerm ? 0 : level * LEVEL_INDENT, 
                boxShadow: focused ? "0 0 0 .2rem rgba(49,132,253,.4)" : "none"
            }}
        >
            {hasNodes && (
                <div
                    className="d-inline-flex"
                    onClick={(e) => {
                        setChildNodeLimitHash((prevChildNodeLimitHash) => ({
                            ...prevChildNodeLimitHash,
                            [label]: 3000
                        }));
                        hasNodes && toggleNode && toggleNode();
                        e.stopPropagation();
                    }}
                >
                    <ToggleIcon on={isOpen} nodetype={props.nodetype} />
                </div>
            )}
            {!hasNodes && <BoardIcon nodetype={props.nodetype} />}
            <small>{type}: <span className="text-color-5">{label}</span></small>
            {type !== 'Genus' ?
                null
                :
                <Link to={`?tree=${tree}`}>
                    <Badge pill bg="primary" className="mx-2">{count}</Badge>
                </Link>
            }

        </ListGroup.Item>
    );
};


const TreeMenuExtended = () => {
    const [childNodeLimitHash, setChildNodeLimitHash] = useState({
        Community: 3000,
        search: 3000
    });
    const [useInitialOpenNodes, setUseInitialOpenNodes] = useState(false);
    const [loading, setLoading] = useState(false);

  
    const getNodesForRender = (nodes, searchTerm = null) => {
        const nodesForRender = [];

        if (searchTerm) {
            let searchResultsCount = 0;
            for (let itemIdx = 0; itemIdx < nodes.length; itemIdx++) {
                searchResultsCount++;
                if (searchResultsCount <= childNodeLimitHash["search"]) {
                    nodesForRender.push(nodes[itemIdx]);
                } else {
                    const { level } = nodes[itemIdx];
                    nodesForRender.push({
                        key: "pager-search",
                        parent: "search",
                        level,
                        isPager: true
                    });
                    break;
                }
            }
        } else {
            for (let itemIdx = 0; itemIdx < nodes.length; itemIdx++) {
                const regex = /.*\/|\.[^.]*$/g;
                const { parent: fullParentPath, index: childIdx } = nodes[itemIdx];
                const currParent = fullParentPath.replace(regex, "");
                if (
                    !childNodeLimitHash[currParent] ||
                    childIdx < childNodeLimitHash[currParent]
                ) {
                    nodesForRender.push(nodes[itemIdx]);
                } else if (childIdx === childNodeLimitHash[currParent]) {
                    nodesForRender.push({
                        key: `pager-${currParent}`,
                        parent: currParent,
                        isPager: false
                    });
                }
            }
        }

        return nodesForRender;
    };

    return (
        <>
            {loading && <Spinner animation="border" variant="primary" />}
            {!loading && (
                <TreeMenu
                    data={treeDataArray} 
                    onClickItem={({ key, label, ...props }) => {
                    }}
                    initialOpenNodes={
                        useInitialOpenNodes
                            ? [
                                "Community",
                                "Community/Category-A",
                                "Community/Category-A/Category-A-SubCategory"
                            ]
                            : ["Community"]
                    } 
                    initialActiveKey={
                        useInitialOpenNodes
                            ? "Community/Category-A/Category-A-SubCategory/Category-A-Subcategory-Forum"
                            : "Community"
                    } 
                    initialFocusKey={
                        useInitialOpenNodes
                            ? "Community/Category-A/Category-A-SubCategory/Category-A-Subcategory-Forum"
                            : "Community"
                    }
                    debounceTime={250} 
                >
                    {({ search, items, resetOpenNodes, searchTerm }) => {
                        const nodesForRender = getNodesForRender(items, searchTerm);

                        return (
                            <>
                                <Form>
                                    <Form.Control
                                        className="search"
                                        type="input"
                                        onChange={(e) => {
                                            resetOpenNodes();
                                            setChildNodeLimitHash((prevChildNodeLimitHash) => ({
                                                ...prevChildNodeLimitHash,
                                                Community: 3000,
                                                search: 3000
                                            }));
                                            search(e.target.value);
                                        }}
                                        placeholder="Filter tree"
                                    />
                                </Form>
                                <ListGroup className="listgroup" flush="true">
                                    {nodesForRender.map((props) => {
                                        if (props.isPager) {
                                            return (
                                                <Button
                                                    key={`${props.parent}-pager`}
                                                    className="mt-1 mb-3 align-self-center"
                                                    onClick={() => {
                                                        setChildNodeLimitHash((prevChildNodeLimitHash) => ({
                                                            ...prevChildNodeLimitHash,
                                                            [props.parent]:
                                                                childNodeLimitHash[props.parent] + 3000
                                                        }));
                                                    }}
                                                >
                                                    {`More ${props.parent === "search"
                                                        ? "Search Results"
                                                        : `${props.parent} Places`
                                                        }`}
                                                </Button>
                                            );
                                        } else {
                                            return (
                                                <ListItem
                                                    key={`${props.key}`}
                                                    setChildNodeLimitHash={setChildNodeLimitHash}
                                                    {...props}
                                                />
                                            );
                                        }
                                    })}
                                    {nodesForRender.length === 0 && (
                                        <Alert variant="info">No search results</Alert>
                                    )}
                                </ListGroup>
                            </>
                        );
                    }}
                </TreeMenu>
            )}
        </>
    );
};

const SimpleTreeMenu = () => {

    return (
        <div className="App">
            <div className="tree-wrapper w-100">
                <TreeMenuExtended />
            </div>
        </div>
    );
};
export default SimpleTreeMenu;
