import React, { useEffect } from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Outlet } from 'react-router-dom'
import { logout } from '../../actions/userActions'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

function AdminScreen() {

    const dispatch = useDispatch();
    const navigation = useNavigate()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const logoutHandler = () => {
        dispatch(logout())
        navigation('/')
    }
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login');
        }
    }, [dispatch])
    return (
        <div>
            <Navbar bg="light" variant="dark" expand="lg" className='text-sm navbar-custom' >
                <Container>
                    <Navbar.Toggle aria-controls="js-fhst-nav-toggle fhst-nav-toggle" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto nav-menu">

                            <LinkContainer to='/admin/messages/'>
                                <Nav.Link>Messages</Nav.Link>
                            </LinkContainer>

                            <NavDropdown title='Settings'>

                                <LinkContainer to='/admin/settings/home/'>
                                    <NavDropdown.Item>
                                        Home page
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/introduction/'>
                                    <NavDropdown.Item>
                                        Introduction
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/archive/'>
                                    <NavDropdown.Item>
                                        Archive
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/contactus/'>
                                    <NavDropdown.Item>
                                        Contact Us
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/mundi/'>
                                    <NavDropdown.Item>
                                        Project Mundi
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/catnotes/'>
                                    <NavDropdown.Item>
                                        World Catalouge notes
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/classification/'>
                                    <NavDropdown.Item>
                                        Higher Classification notes
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/settings/publications/'>
                                    <NavDropdown.Item>
                                        Publications notes
                                    </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/ack/'>
                                    <NavDropdown.Item>Acknowledgement</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/disclaimer/'>
                                    <NavDropdown.Item>Disclaimer</NavDropdown.Item>
                                </LinkContainer>

                            </NavDropdown>

                            <LinkContainer to='/admin/news/'>
                                <Nav.Link>News</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to='/admin/newsletters/'>
                                <Nav.Link>Newsletters</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to='/admin/congresses/'>
                                <Nav.Link>Congresses</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to='/admin/articles/'>
                                <Nav.Link>Publications</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to='/admin/members/'>
                                <Nav.Link>Members</Nav.Link>
                            </LinkContainer>

                            <NavDropdown title='CH'>
                                <LinkContainer to='/admin/settings/chinfo/'>
                                    <NavDropdown.Item>CH Information</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/obituaries/'>
                                    <NavDropdown.Item>Obituaries</NavDropdown.Item>
                                </LinkContainer>
                                
                                <LinkContainer to='/admin/herbulotarticles/'>
                                    <NavDropdown.Item>Publications</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/taxa/'>
                                    <NavDropdown.Item>TAXA</NavDropdown.Item>
                                </LinkContainer>

                            </NavDropdown>
                                <Nav.Link onClick={logoutHandler}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Outlet />

        </div>
    )
}

export default AdminScreen