import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowCircleLeft } from "react-icons/fa";
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
import Loader from '../../../components/Loader'
import { settingsArchiveAction } from '../../../actions/assetActions'

function AdminArchiveAddScreen() {
    alert = useAlert()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const dispatch = useDispatch()
    const navigation = useNavigate();

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [img, setImg] = useState('')
    const [doc, setDoc] = useState('')

    const [uploading, setUploading] = useState(false)

    const submitHandler = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('title', title)
        formData.append('description', description)
        formData.append('img', img)
        formData.append('doc', doc)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            await axios.post('/api/assets/settings/archive/add/', formData, config)

            setUploading(false)
            dispatch(settingsArchiveAction())
        } catch (error) {
            alert.show('error occured')
        }

        navigation('/admin/settings/archive/')
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],


            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    }

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

    }, [])

    return (
        <div>
            {uploading && <Loader />}
            <Link to='/admin/settings/archive/'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>
            <Row>
                <Col sm={12} md={6} lg={8}>
                    <h1 className='mb-5'>Add Archive</h1>
                    <Form onSubmit={submitHandler}>

                        <Form.Group as={Row} controlId='title' className='mb-2'>
                            <Form.Label column sm="2">Title</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    required
                                    type='title'
                                    placeholder='Enter title'
                                    value={title ?? ""}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-2'>
                            <Form.Label column sm="2">Attachment</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    id='doc'
                                    type='file'
                                    placeholder='file'
                                    onChange={(e) => setDoc(e.target.files[0])}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-2'>
                            <Form.Label column sm="2">Image</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    id='img'
                                    type='file'
                                    placeholder='file'
                                    onChange={(e) => setImg(e.target.files[0])}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='description' className='mb-2'>
                            <Form.Label column sm="2">Description</Form.Label>
                            <Col sm="10">
                                <ReactQuill theme="snow" value={description ?? ""} onChange={setDescription} modules={quillModules}  preserveWhitespace="true" />
                            </Col>
                        </Form.Group>

                        <Button type='submit' variant='primary'>
                            <i className='fas fa-plus'></i> Submit
                        </Button>

                    </Form>
                </Col >

            </Row>
        </div >
    )
}

export default AdminArchiveAddScreen