import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import ManageImage from '../../../components/ManageImage'
import { useAlert } from 'react-alert'
import { CONGRESS_UPDATE_RESET} from '../../../constants/assetConstants'
import { congressDetails, updateCongress } from '../../../actions/assetActions'
import { FaArrowCircleLeft } from "react-icons/fa";
import { saveAs } from "file-saver";
import { SITE_URL } from '../../../constants/siteConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminCongressEditScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const { id } = useParams();
    const navigation = useNavigate();
    alert = useAlert()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [img, setImg] = useState('')
    const [chairman, setChairman] = useState('')
    const [venue, setVenue] = useState('')
    const [year, setYear] = useState('')
    const [participants, setParticipants] = useState('')
    const [programs, setPrograms] = useState('')
    const [proceedings, setProceedings] = useState('')
    const [doc, setDoc] = useState('')

    const [caption, setCaption] = useState('')

    const [changedImage, setChangedImage] = useState(false)


    const [uploadingImage, setUploadingImage] = useState(false)
    const [uploadingGallery, setUploadingGallery] = useState(false)

    const dispatch = useDispatch()

    const congressDet = useSelector(state => state.detailCongress)
    const { error, loading, congress } = congressDet

    const congressUpdate = useSelector(state => state.congressUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = congressUpdate
    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

        if (successUpdate) {
            dispatch({ type: CONGRESS_UPDATE_RESET })
            navigation('/admin/congresses/')
        } else {
            if (!congress.title || congress.id != id || changedImage) {
                dispatch(congressDetails(id))
                setChangedImage(false)
            } else {
                setTitle(congress.title)
                setDescription(congress.description)
                setImg(congress.img)
                setChairman(congress.chairman)
                setVenue(congress.venue)
                setYear(congress.year)
                setParticipants(congress.participants)
                setPrograms(congress.programs)
                setProceedings(congress.proceedings)
                setDoc(congress.doc)
            }

        }
    }, [dispatch, congress, id, successUpdate, changedImage])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateCongress({
            id,
            title,
            description,
            img,
            chairman,
            venue,
            year,
            participants,
            programs,
            proceedings,
            doc
        }))
        alert.show('Congress Changed successfully')
    }

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]

        if (file.size > 102400) {
            setImg('')
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('id', id)
        formData.append('tbl', 'Congress')
        formData.append('prev', true)

        setUploadingImage(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/upload/image/', formData, config)


            setImg(data)
            setUploadingImage(false)
            setChangedImage(true)

        } catch (error) {
            setUploadingImage(false)
        }
    }

    const uploadGalleryHandler = async (e) => {

        const file = e.target.files[0]
        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('title', caption)


        setUploadingGallery(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post(`/api/assets/congress/images/${id}/`, formData, config)
            alert.show('Image uploaded successfully')


            setImg(data)
            setUploadingGallery(false)
            setChangedImage(true)

        } catch (error) {
            setUploadingGallery(false)
            alert.error('error')
        }
    }

    const [uploadingDoc, setUploadingDoc] = useState(false)
    const uploadDocHandler = async (e) => {

        const file = e.target.files[0]

        const formData = new FormData()
        formData.append('doc', file)
        formData.append('id', id)
        formData.append('tbl', 'Congress')
        formData.append('prev', true)

        setUploadingDoc(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/upload/doc/', formData, config)


            setImg(data)
            setUploadingDoc(false)
            setChangedImage(true)
            alert.show('Document Changed Successfully.')

        } catch (error) {
            setUploadingImage(false)
        }
    }
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
                                

            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    }
    const changerHandler = () => {
        setChangedImage(true)
    }
    return (
        <div>
            <Link to='/admin/congresses'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>


            {loading
                ? (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (

                        <Row>
                            <Col sm={12} md={6} lg={8}>
                                <h1 className='mb-5'>Edit Congress</h1>
                                {loadingUpdate && <Loader />}
                                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                                <Form onSubmit={submitHandler}>

                                    <Form.Group as={Row} controlId='title' className='mb-2'>
                                        <Form.Label column sm="2">Title</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                required
                                                type='title'
                                                placeholder='Enter title'
                                                value={title ?? ""}
                                                onChange={(e) => setTitle(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='description' className='mb-2'>
                                        <Form.Label column sm="2">Description</Form.Label>
                                        <Col sm="10">
                                            <ReactQuill theme="snow" value={description ?? ""} onChange={setDescription} modules={quillModules}  preserveWhitespace="true" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='chairman' className='mb-2'>
                                        <Form.Label column sm="2">Organizer(s)</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                placeholder='Enter Organizer(s)'
                                                value={chairman ?? ""}
                                                onChange={(e) => setChairman(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='venue' className='mb-2'>
                                        <Form.Label column sm="2">Venue</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type='text'
                                                placeholder='Enter venue'
                                                value={venue ?? ""}
                                                onChange={(e) => setVenue(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='year' className='mb-2'>
                                        <Form.Label column sm="2">Year</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                required
                                                type='text'
                                                placeholder='Enter year'
                                                value={year ?? ""}
                                                onChange={(e) => setYear(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='Participants' className='mb-2'>
                                        <Form.Label column sm="2">Participants</Form.Label>
                                        <Col sm="10">
                                            <ReactQuill theme="snow" value={participants ?? ""} onChange={setParticipants} modules={quillModules}  preserveWhitespace="true" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='Programs' className='mb-2'>
                                        <Form.Label column sm="2">Programs</Form.Label>
                                        <Col sm="10">
                                            <ReactQuill theme="snow" value={programs ?? ""} onChange={setPrograms} modules={quillModules}  preserveWhitespace="true" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='Proceedings' className='mb-2'>
                                        <Form.Label column sm="2">Proceedings</Form.Label>
                                        <Col sm="10">
                                            <ReactQuill theme="snow" value={proceedings ?? ""} onChange={setProceedings} modules={quillModules}  preserveWhitespace="true" />
                                        </Col>
                                    </Form.Group>

                                    <Button type='submit' variant='primary'>
                                    <i className='fas fa-edit'></i> Update
                                    </Button>

                                </Form>
                            </Col >
                            <Col sm={12} md={6} lg={4}>
                                <h5>Change Main Image</h5>
                                <Image src={congress.img} className='w-100' />
                                <div>

                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='mainimg'
                                            type='file'
                                            placeholder='image'
                                            onChange={uploadFileHandler}
                                        >
                                        </Form.Control>
                                        {uploadingImage && <Loader />}
                                    </Form.Group>
                                    <hr></hr>
                                <h5>Change Document</h5>
                                <p className='mt-1 mb-1 text-sm text-color-2 cursor-pointer' onClick={() => saveFile(congress.doc)}>
                                    <i className='fas fa-download text-color-2'></i> Download file
                                </p>
                                <div>
                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='doc'
                                            type='file'
                                            placeholder='file'
                                            onChange={uploadDocHandler}
                                        >
                                        </Form.Control>
                                        {uploadingDoc && <Loader />}
                                    </Form.Group>
                                </div>
                                </div>

                            </Col>
                        </Row>
                    )}
            <hr></hr>
            <h1>Edit Photo Gallery</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {congress.album && congress.album.length > 0 &&
                        <div>
                            {congress.album.map(item => (
                                <ManageImage item={item} tbl={'AlbumCongress'} token={userInfo.token} changerHandler={changerHandler} key={item.id} />
                            ))}
                        </div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Add new image to photo gallery</h5>

                    <Form.Control
                        className='mb-3'
                        type='text'
                        placeholder='Enter caption'
                        value={caption ?? ""}
                        onChange={(e) => setCaption(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        id='galleryimg'
                        type='file'
                        placeholder='image'
                        onChange={uploadGalleryHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>
        </div>


    )
}

export default AdminCongressEditScreen