import {
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SETTINGS_FAIL,
    SETTINGS_RESET,

    SETTINGS_UPDATE_REQUEST,
    SETTINGS_UPDATE_SUCCESS,
    SETTINGS_UPDATE_FAIL,
    SETTINGS_UPDATE_RESET,

    SETTINGS_HOME_REQUEST,
    SETTINGS_HOME_SUCCESS,
    SETTINGS_HOME_FAIL,
    SETTINGS_HOME_RESET,

    SETTINGS_HOME_UPDATE_REQUEST,
    SETTINGS_HOME_UPDATE_SUCCESS,
    SETTINGS_HOME_UPDATE_FAIL,
    SETTINGS_HOME_UPDATE_RESET,

    SETTINGS_CONTACT_REQUEST,
    SETTINGS_CONTACT_SUCCESS,
    SETTINGS_CONTACT_FAIL,
    SETTINGS_CONTACT_RESET,

    SETTINGS_CONTACT_UPDATE_REQUEST,
    SETTINGS_CONTACT_UPDATE_SUCCESS,
    SETTINGS_CONTACT_UPDATE_FAIL,
    SETTINGS_CONTACT_UPDATE_RESET,

    SETTINGS_MUNDI_REQUEST,
    SETTINGS_MUNDI_SUCCESS,
    SETTINGS_MUNDI_FAIL,
    SETTINGS_MUNDI_RESET,

    SETTINGS_MUNDI_UPDATE_REQUEST,
    SETTINGS_MUNDI_UPDATE_SUCCESS,
    SETTINGS_MUNDI_UPDATE_FAIL,
    SETTINGS_MUNDI_UPDATE_RESET,

    CH_INFO_REQUEST,
    CH_INFO_SUCCESS,
    CH_INFO_FAIL,
    CH_INFO_RESET,

    
    SETTINGS_CATNOTES_REQUEST,
    SETTINGS_CATNOTES_SUCCESS,
    SETTINGS_CATNOTES_FAIL,
    SETTINGS_CATNOTES_RESET,

    SETTINGS_CATNOTES_UPDATE_REQUEST,
    SETTINGS_CATNOTES_UPDATE_SUCCESS,
    SETTINGS_CATNOTES_UPDATE_FAIL,
    SETTINGS_CATNOTES_UPDATE_RESET,
    
    CH_INFO_UPDATE_REQUEST,
    CH_INFO_UPDATE_SUCCESS,
    CH_INFO_UPDATE_FAIL,
    CH_INFO_UPDATE_RESET,

    SETTINGS_CLASSIFICATION_REQUEST,
    SETTINGS_CLASSIFICATION_SUCCESS,
    SETTINGS_CLASSIFICATION_FAIL,
    SETTINGS_CLASSIFICATION_RESET,

    SETTINGS_CLASSIFICATION_UPDATE_REQUEST,
    SETTINGS_CLASSIFICATION_UPDATE_SUCCESS,
    SETTINGS_CLASSIFICATION_UPDATE_FAIL,
    SETTINGS_CLASSIFICATION_UPDATE_RESET,

    SETTINGS_PUBLICATIONS_REQUEST,
    SETTINGS_PUBLICATIONS_SUCCESS,
    SETTINGS_PUBLICATIONS_FAIL,
    SETTINGS_PUBLICATIONS_RESET,

    SETTINGS_PUBLICATIONS_UPDATE_REQUEST,
    SETTINGS_PUBLICATIONS_UPDATE_SUCCESS,
    SETTINGS_PUBLICATIONS_UPDATE_FAIL,
    SETTINGS_PUBLICATIONS_UPDATE_RESET,

    ACK_REQUEST,
    ACK_SUCCESS,
    ACK_FAIL,
    ACK_RESET,

    ACK_UPDATE_REQUEST,
    ACK_UPDATE_SUCCESS,
    ACK_UPDATE_FAIL,
    ACK_UPDATE_RESET,

    DISCLAIMER_REQUEST,
    DISCLAIMER_SUCCESS,
    DISCLAIMER_FAIL,
    DISCLAIMER_RESET,

    DISCLAIMER_UPDATE_REQUEST,
    DISCLAIMER_UPDATE_SUCCESS,
    DISCLAIMER_UPDATE_FAIL,
    DISCLAIMER_UPDATE_RESET,

    COUNTERS_REQUEST,
    COUNTERS_SUCCESS,
    COUNTERS_FAIL,

    NEWS_ALL_REQUEST,
    NEWS_ALL_SUCCESS,
    NEWS_ALL_FAIL,

    NEWS_TOP_REQUEST,
    NEWS_TOP_SUCCESS,
    NEWS_TOP_FAIL,

    NEWS_LIST_REQUEST,
    NEWS_LIST_SUCCESS,
    NEWS_LIST_FAIL,

    NEWS_DETAILS_REQUEST,
    NEWS_DETAILS_SUCCESS,
    NEWS_DETAILS_FAIL,

    NEWS_CREATE_REQUEST,
    NEWS_CREATE_SUCCESS,
    NEWS_CREATE_FAIL,
    NEWS_CREATE_RESET,


    NEWS_DELETE_REQUEST,
    NEWS_DELETE_SUCCESS,
    NEWS_DELETE_FAIL,

    NEWS_UPDATE_REQUEST,
    NEWS_UPDATE_SUCCESS,
    NEWS_UPDATE_FAIL,
    NEWS_UPDATE_RESET,

    HOME_ALBUM_REQUEST,
    HOME_ALBUM_SUCCESS,
    HOME_ALBUM_FAIL,

    MAIN_ALBUM_REQUEST,
    MAIN_ALBUM_SUCCESS,
    MAIN_ALBUM_FAIL,

    NEWSLETTER_LIST_REQUEST,
    NEWSLETTER_LIST_SUCCESS,
    NEWSLETTER_LIST_FAIL,

    NEWSLETTER_DETAILS_REQUEST,
    NEWSLETTER_DETAILS_SUCCESS,
    NEWSLETTER_DETAILS_FAIL,

    NEWSLETTER_CREATE_REQUEST,
    NEWSLETTER_CREATE_SUCCESS,
    NEWSLETTER_CREATE_FAIL,
    NEWSLETTER_CREATE_RESET,

    NEWSLETTER_DELETE_REQUEST,
    NEWSLETTER_DELETE_SUCCESS,
    NEWSLETTER_DELETE_FAIL,

    NEWSLETTER_UPDATE_REQUEST,
    NEWSLETTER_UPDATE_SUCCESS,
    NEWSLETTER_UPDATE_FAIL,
    NEWSLETTER_UPDATE_RESET,

    NEWSLETTER_EMAIL_REQUEST,
    NEWSLETTER_EMAIL_SUCCESS,
    NEWSLETTER_EMAIL_FAIL,

    CONGRESS_ALL_REQUEST,
    CONGRESS_ALL_SUCCESS,
    CONGRESS_ALL_FAIL,

    CONGRESS_LIST_REQUEST,
    CONGRESS_LIST_SUCCESS,
    CONGRESS_LIST_FAIL,

    CONGRESS_DETAILS_REQUEST,
    CONGRESS_DETAILS_SUCCESS,
    CONGRESS_DETAILS_FAIL,

    CONGRESS_CREATE_REQUEST,
    CONGRESS_CREATE_SUCCESS,
    CONGRESS_CREATE_FAIL,
    CONGRESS_CREATE_RESET,

    CONGRESS_DELETE_REQUEST,
    CONGRESS_DELETE_SUCCESS,
    CONGRESS_DELETE_FAIL,

    CONGRESS_UPDATE_REQUEST,
    CONGRESS_UPDATE_SUCCESS,
    CONGRESS_UPDATE_FAIL,
    CONGRESS_UPDATE_RESET,

    PARTICIPANT_CREATE_REQUEST,
    PARTICIPANT_CREATE_SUCCESS,
    PARTICIPANT_CREATE_FAIL,
    PARTICIPANT_CREATE_RESET,

    PARTICIPANT_DELETE_REQUEST,
    PARTICIPANT_DELETE_SUCCESS,
    PARTICIPANT_DELETE_FAIL,
    PARTICIPANT_DELETE_RESET,

    PROGRAM_CREATE_REQUEST,
    PROGRAM_CREATE_SUCCESS,
    PROGRAM_CREATE_FAIL,
    PROGRAM_CREATE_RESET,

    PROGRAM_DELETE_REQUEST,
    PROGRAM_DELETE_SUCCESS,
    PROGRAM_DELETE_FAIL,
    PROGRAM_DELETE_RESET,

    PROCEEDING_CREATE_REQUEST,
    PROCEEDING_CREATE_SUCCESS,
    PROCEEDING_CREATE_FAIL,
    PROCEEDING_CREATE_RESET,

    PROCEEDING_DELETE_REQUEST,
    PROCEEDING_DELETE_SUCCESS,
    PROCEEDING_DELETE_FAIL,
    PROCEEDING_DELETE_RESET,


    ARTICLE_REQUEST,
    ARTICLE_SUCCESS,
    ARTICLE_FAIL,

    ARTICLE_CREATE_REQUEST,
    ARTICLE_CREATE_SUCCESS,
    ARTICLE_CREATE_RESET,
    ARTICLE_CREATE_FAIL,

    ARTICLE_UPDATE_REQUEST,
    ARTICLE_UPDATE_SUCCESS,
    ARTICLE_UPDATE_FAIL,
    ARTICLE_UPDATE_RESET,

    CAPTION_UPDATE_REQUEST,
    CAPTION_UPDATE_SUCCESS,
    CAPTION_UPDATE_FAIL,
    CAPTION_UPDATE_RESET,

    SETTINGS_INTRO_REQUEST,
    SETTINGS_INTRO_SUCCESS,
    SETTINGS_INTRO_FAIL,
    SETTINGS_INTRO_RESET,

    SETTINGS_INTRO_UPDATE_REQUEST,
    SETTINGS_INTRO_UPDATE_SUCCESS,
    SETTINGS_INTRO_UPDATE_FAIL,
    SETTINGS_INTRO_UPDATE_RESET,

    SETTINGS_ARCHIVE_REQUEST,
    SETTINGS_ARCHIVE_SUCCESS,
    SETTINGS_ARCHIVE_FAIL,
    SETTINGS_ARCHIVE_RESET,

    SETTINGS_ARCHIVE_UPDATE_REQUEST,
    SETTINGS_ARCHIVE_UPDATE_SUCCESS,
    SETTINGS_ARCHIVE_UPDATE_FAIL,
    SETTINGS_ARCHIVE_UPDATE_RESET,

    ARCHIVE_DETAILS_REQUEST,
    ARCHIVE_DETAILS_SUCCESS,
    ARCHIVE_DETAILS_FAIL,

    ATTACH_LIST_REQUEST,
    ATTACH_LIST_SUCCESS,
    ATTACH_LIST_FAIL,

    ATTACH_CREATE_REQUEST,
    ATTACH_CREATE_SUCCESS,
    ATTACH_CREATE_FAIL,
    ATTACH_CREATE_RESET
} from '../constants/assetConstants'

// -------------------------------------------- SETTINGS

export const settingsReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_REQUEST:
            return { loading: true }

        case SETTINGS_SUCCESS:
            return {
                loading: false,
                h: action.payload.h,
                c: action.payload.c,
                m: action.payload.m,
                se: action.payload.se,
                se_titr: action.payload.se_titr,
                us_email: action.payload.us_email,
                us_phone: action.payload.us_phone,
                us_address: action.payload.us_address,
            }

        case SETTINGS_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_RESET:
            return {}

        default:
            return state
    }
}

export const settingsUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const settingsHomeReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_HOME_REQUEST:
            return { loading: true }

        case SETTINGS_HOME_SUCCESS:
            return {
                loading: false,
                id: action.payload.id,
                h: action.payload.h,
                se: action.payload.se,
                se_titr: action.payload.se_titr,
                footer: action.payload.footer
            }

        case SETTINGS_HOME_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_HOME_RESET:
            return {}

        default:
            return state
    }
}

export const settingsHomeUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_HOME_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_HOME_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_HOME_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_HOME_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const settingsContactReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_CONTACT_REQUEST:
            return { loading: true }

        case SETTINGS_CONTACT_SUCCESS:
            return {
                loading: false,
                us_email: action.payload.us_email,
                us_phone: action.payload.us_phone,
                us_address: action.payload.us_address,
            }

        case SETTINGS_CONTACT_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_CONTACT_RESET:
            return {}

        default:
            return state
    }
}

export const settingsContactUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_CONTACT_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_CONTACT_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_CONTACT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_CONTACT_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const settingsMundiReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_MUNDI_REQUEST:
            return { loading: true }

        case SETTINGS_MUNDI_SUCCESS:
            return {
                loading: false,
                m: action.payload.m,
            }

        case SETTINGS_MUNDI_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_MUNDI_RESET:
            return {}

        default:
            return state
    }
}

export const settingsMundiUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_MUNDI_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_MUNDI_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_MUNDI_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_MUNDI_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const settingsIntroReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_INTRO_REQUEST:
            return { loading: true }

        case SETTINGS_INTRO_SUCCESS:
            return {
                loading: false,
                m: action.payload.m,
            }

        case SETTINGS_INTRO_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_INTRO_RESET:
            return {}

        default:
            return state
    }
}

export const settingsIntroUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_INTRO_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_INTRO_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_INTRO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_INTRO_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const settingsArchiveReducer = (state = {}, action) => {
    switch (action.type) {
        case SETTINGS_ARCHIVE_REQUEST:
            return { loading: true }

        case SETTINGS_ARCHIVE_SUCCESS:
            return {
                loading: false,
                archiveList: action.payload,
            }

        case SETTINGS_ARCHIVE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_ARCHIVE_RESET:
            return {}

        default:
            return state
    }
}

export const settingsArchiveUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_ARCHIVE_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_ARCHIVE_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_ARCHIVE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_ARCHIVE_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const CHInfoReducer = (state = { chinfo: '' }, action) => {
    switch (action.type) {
        case CH_INFO_REQUEST:
            return { loading: true }

        case CH_INFO_SUCCESS:
            return {
                loading: false,
                m: action.payload.m,
                id: action.payload.id,
                img: action.payload.img
            }

        case CH_INFO_FAIL:
            return { loading: false, error: action.payload }

        case CH_INFO_FAIL:
            return {}

        default:
            return state
    }
}

export const CHInfoUpdateReducer = (state = { chinfo: {} }, action) => {
    switch (action.type) {
        case CH_INFO_UPDATE_REQUEST:
            return { loading: true }

        case CH_INFO_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case CH_INFO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CH_INFO_UPDATE_RESET:
            return { chinfo: {} }

        default:
            return state
    }
}


export const settingsClassificationReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_CLASSIFICATION_REQUEST:
            return { loading: true }

        case SETTINGS_CLASSIFICATION_SUCCESS:
            return {
                loading: false,
                c: action.payload.c,
            }

        case SETTINGS_CLASSIFICATION_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_CLASSIFICATION_RESET:
            return {}

        default:
            return state
    }
}

export const settingsClassificationUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_CLASSIFICATION_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_CLASSIFICATION_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_CLASSIFICATION_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_CLASSIFICATION_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}

export const settingsPublicationsReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_PUBLICATIONS_REQUEST:
            return { loading: true }

        case SETTINGS_PUBLICATIONS_SUCCESS:
            return {
                loading: false,
                c: action.payload.c,
            }

        case SETTINGS_PUBLICATIONS_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_PUBLICATIONS_RESET:
            return {}

        default:
            return state
    }
}

export const settingsPublicationsUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_PUBLICATIONS_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_PUBLICATIONS_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_PUBLICATIONS_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_PUBLICATIONS_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}


export const countersReducer = (state = { counts: '' }, action) => {
    switch (action.type) {
        case COUNTERS_REQUEST:
            return { loading: true }

        case COUNTERS_SUCCESS:
            return {
                loading: false,
                counts: action.payload,
            }

        case COUNTERS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const mainAlbumReducer = (state = { albums: [] }, action) => {
    switch (action.type) {
        case MAIN_ALBUM_REQUEST:
            return { loading: true, albums: {} }

        case MAIN_ALBUM_SUCCESS:
            return {
                loading: false,
                albums: action.payload.mains,
                count: action.payload.count
            }

        case MAIN_ALBUM_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const homeAlbumReducer = (state = { albums: [] }, action) => {
    switch (action.type) {
        case HOME_ALBUM_REQUEST:
            return { loading: true, albums: [] }

        case HOME_ALBUM_SUCCESS:
            return {
                loading: false,
                albums: action.payload,
            }

        case HOME_ALBUM_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


// -------------------------------------------- NEWS 

export const listNewsReducer = (state = { news: [] }, action) => {
    switch (action.type) {
        case NEWS_LIST_REQUEST:
            return { loading: true }

        case NEWS_LIST_SUCCESS:
            return {
                loading: false,
                news: action.payload.news,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case NEWS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const allNewsReducer = (state = { news: [] }, action) => {
    switch (action.type) {
        case NEWS_ALL_REQUEST:
            return { loading: true }

        case NEWS_ALL_SUCCESS:
            return {
                loading: false,
                news: action.payload,
            }

        case NEWS_ALL_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const newsReducer = (state = { news: [] }, action) => {
    switch (action.type) {
        case NEWS_TOP_REQUEST:
            return { loading: true, news: [] }

        case NEWS_TOP_SUCCESS:
            return {
                loading: false,
                news: action.payload.news,
            }

        case NEWS_TOP_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const newsDetailsReducer = (state = { news: '' }, action) => {
    switch (action.type) {
        case NEWS_DETAILS_REQUEST:
            return { loading: true, ...state }

        case NEWS_DETAILS_SUCCESS:
            return { loading: false, news: action.payload }

        case NEWS_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state

    }
}


export const newsCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case NEWS_CREATE_REQUEST:
            return { loading: true }

        case NEWS_CREATE_SUCCESS:
            return { loading: false, success: true }

        case NEWS_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case NEWS_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const newsUpdateReducer = (state = { news: {} }, action) => {
    switch (action.type) {
        case NEWS_UPDATE_REQUEST:
            return { loading: true }

        case NEWS_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case NEWS_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case NEWS_UPDATE_RESET:
            return { news: {} }

        default:
            return state
    }
}


export const newsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case NEWS_DELETE_REQUEST:
            return { loading: true }

        case NEWS_DELETE_SUCCESS:
            return { loading: false, success: true }

        case NEWS_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

// -------------------------------------------- NEWSLETTER

export const listNewsLetterReducer = (state = { newsletter: [] }, action) => {
    switch (action.type) {
        case NEWSLETTER_LIST_REQUEST:
            return { loading: true }

        case NEWSLETTER_LIST_SUCCESS:
            return {
                loading: false,
                newsletter: action.payload.newsletter,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case NEWSLETTER_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const newsletterDetailsReducer = (state = { newsletter: '' }, action) => {
    switch (action.type) {
        case NEWSLETTER_DETAILS_REQUEST:
            return { loading: true, ...state }

        case NEWSLETTER_DETAILS_SUCCESS:
            return { loading: false, newsletter: action.payload }

        case NEWSLETTER_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state

    }
}

export const newsletterCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case NEWSLETTER_CREATE_REQUEST:
            return { loading: true }

        case NEWSLETTER_CREATE_SUCCESS:
            return { loading: false, success: true }

        case NEWSLETTER_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case NEWSLETTER_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const newsletterUpdateReducer = (state = { newsletter: {} }, action) => {
    switch (action.type) {
        case NEWSLETTER_UPDATE_REQUEST:
            return { loading: true }

        case NEWSLETTER_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case NEWSLETTER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case NEWSLETTER_UPDATE_RESET:
            return { newsletter: {} }

        default:
            return state
    }
}


export const newsletterDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case NEWSLETTER_DELETE_REQUEST:
            return { loading: true }

        case NEWSLETTER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case NEWSLETTER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const emailNewsletterReducer = (state = {}, action) => {
    switch (action.type) {
        case NEWSLETTER_EMAIL_REQUEST:
            return { loading: true }
        case NEWSLETTER_EMAIL_SUCCESS:
            return { loading: false, success: action.payload.success }

        case NEWSLETTER_EMAIL_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


// -------------------------------------------- CONGRESS PAGE
export const listCongressReducer = (state = { congress: [] }, action) => {
    switch (action.type) {
        case CONGRESS_LIST_REQUEST:
            return { loading: true }

        case CONGRESS_LIST_SUCCESS:
            return {
                loading: false,
                congress: action.payload.congress,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case CONGRESS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const allCongressReducer = (state = { congress: [] }, action) => {
    switch (action.type) {
        case CONGRESS_ALL_REQUEST:
            return { loading: true }

        case CONGRESS_ALL_SUCCESS:
            return {
                loading: false,
                congress: action.payload,
            }

        case CONGRESS_ALL_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const congressDetailsReducer = (state = { congress: {} }, action) => {
    switch (action.type) {
        case CONGRESS_DETAILS_REQUEST:
            return { loading: true, ...state }

        case CONGRESS_DETAILS_SUCCESS:
            return {
                loading: false,
                congress: action.payload,
            }

        case CONGRESS_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state

    }
}



export const congressCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONGRESS_CREATE_REQUEST:
            return { loading: true }

        case CONGRESS_CREATE_SUCCESS:
            return { loading: false, success: true }

        case CONGRESS_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case CONGRESS_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const congressUpdateReducer = (state = { congress: {} }, action) => {
    switch (action.type) {
        case CONGRESS_UPDATE_REQUEST:
            return { loading: true }

        case CONGRESS_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case CONGRESS_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CONGRESS_UPDATE_RESET:
            return { congress: {} }

        default:
            return state
    }
}


export const congressDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONGRESS_DELETE_REQUEST:
            return { loading: true }

        case CONGRESS_DELETE_SUCCESS:
            return { loading: false, success: true }

        case CONGRESS_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const participantCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PARTICIPANT_CREATE_REQUEST:
            return { loading: true }

        case PARTICIPANT_CREATE_SUCCESS:
            return { loading: false, success: true }

        case PARTICIPANT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PARTICIPANT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const participantDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PARTICIPANT_DELETE_REQUEST:
            return { loading: true }

        case PARTICIPANT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PARTICIPANT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case PARTICIPANT_DELETE_RESET:
            return {}

        default:
            return state
    }
}


export const proceedingCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PROCEEDING_CREATE_REQUEST:
            return { loading: true }

        case PROCEEDING_CREATE_SUCCESS:
            return { loading: false, success: true }

        case PROCEEDING_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PROCEEDING_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const proceedingDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PROCEEDING_DELETE_REQUEST:
            return { loading: true }

        case PROCEEDING_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PROCEEDING_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case PROCEEDING_DELETE_RESET:
            return {}

        default:
            return state
    }
}

export const programCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PROGRAM_CREATE_REQUEST:
            return { loading: true }

        case PROGRAM_CREATE_SUCCESS:
            return { loading: false, success: true }

        case PROGRAM_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PROGRAM_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const programDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PROGRAM_DELETE_REQUEST:
            return { loading: true }

        case PROGRAM_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PROGRAM_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case PROGRAM_DELETE_RESET:
            return {}

        default:
            return state
    }
}
// -------------------------------------------- ARTICLES

export const listArticlerReducer = (state = { article: [] }, action) => {
    switch (action.type) {
        case ARTICLE_REQUEST:
            return { loading: true }

        case ARTICLE_SUCCESS:
            return {
                loading: false,
                articles: action.payload.articles,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case ARTICLE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const articleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ARTICLE_CREATE_REQUEST:
            return { loading: true }

        case ARTICLE_CREATE_SUCCESS:
            return { loading: false, success: true }

        case ARTICLE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ARTICLE_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const articleUpdateReducer = (state = { article: {} }, action) => {
    switch (action.type) {
        case ARTICLE_UPDATE_REQUEST:
            return { loading: true }

        case ARTICLE_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case ARTICLE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case ARTICLE_UPDATE_RESET:
            return { article: {} }

        default:
            return state
    }
}


// ------------------------------------------ ACK
export const ackReducer = (state = { ack: '' }, action) => {
    switch (action.type) {
        case ACK_REQUEST:
            return { loading: true }

        case ACK_SUCCESS:
            return {
                loading: false,
                header: action.payload.header,
                text: action.payload.text,
            }

        case ACK_FAIL:
            return { loading: false, error: action.payload }

        case ACK_RESET:
            return {}

        default:
            return state
    }
}



export const ackUpdateReducer = (state = { ack: {} }, action) => {
    switch (action.type) {
        case ACK_UPDATE_REQUEST:
            return { loading: true }

        case ACK_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case ACK_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case ACK_UPDATE_RESET:
            console.log('reseting ack')
            return { loading: false, success: false  }

        default:
            return state
    }
}



// ------------------------------------------ DISCLAIMER
export const disclaimerReducer = (state = { disclaimer: '' }, action) => {
    switch (action.type) {
        case DISCLAIMER_REQUEST:
            return { loading: true }

        case DISCLAIMER_SUCCESS:
            return {
                loading: false,
                header: action.payload.header,
                text: action.payload.text,
            }

        case DISCLAIMER_FAIL:
            return { loading: false, error: action.payload }

        case DISCLAIMER_RESET:
            return {}

        default:
            return state
    }
}



export const disclaimerUpdateReducer = (state = { disclaimer: {} }, action) => {
    switch (action.type) {
        case DISCLAIMER_UPDATE_REQUEST:
            return { loading: true }

        case DISCLAIMER_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case DISCLAIMER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case DISCLAIMER_UPDATE_RESET:
            return { disclaimer: {} }

        default:
            return state
    }
}


//----------------------------------------- CAPTION

export const captionUpdateReducer = (state = { caption: {} }, action) => {
    switch (action.type) {
        case CAPTION_UPDATE_REQUEST:
            return { loading: true }

        case CAPTION_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case CAPTION_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CAPTION_UPDATE_RESET:
            return { caption: {} }

        default:
            return state
    }
}

// ------------------------------------- Attach

export const listAttachReducer = (state = { attach: [] }, action) => {
    switch (action.type) {
        case ATTACH_LIST_REQUEST:
            return { loading: true }

        case ATTACH_LIST_SUCCESS:
            return {
                loading: false,
                attachs: action.payload,
            }

        case ATTACH_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const archiveDetailsReducer = (state = { archive: '' }, action) => {
    switch (action.type) {
        case ARCHIVE_DETAILS_REQUEST:
            return { loading: true, ...state }

        case ARCHIVE_DETAILS_SUCCESS:
            return { loading: false, archive: action.payload }

        case ARCHIVE_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state

    }
}


export const attachCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ATTACH_CREATE_REQUEST:
            return { loading: true }

        case ATTACH_CREATE_SUCCESS:
            return { loading: false, success: true }

        case ATTACH_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ATTACH_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const settingsCatNotesReducer = (state = { settings: '' }, action) => {
    switch (action.type) {
        case SETTINGS_CATNOTES_REQUEST:
            return { loading: true }

        case SETTINGS_CATNOTES_SUCCESS:
            return {
                loading: false,
                note: action.payload.note,
            }

        case SETTINGS_CATNOTES_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_CATNOTES_RESET:
            return {}

        default:
            return state
    }
}

export const settingsCatNotesUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_CATNOTES_UPDATE_REQUEST:
            return { loading: true }

        case SETTINGS_CATNOTES_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case SETTINGS_CATNOTES_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SETTINGS_CATNOTES_UPDATE_RESET:
            return { settings: {} }

        default:
            return state
    }
}