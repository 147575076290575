import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { createMessageAction } from '../../actions/userActions'
import { settingsContactAction } from '../../actions/assetActions'

function ContactUsScreen() {

  const messageCreate = useSelector(state => state.messageCreate)
  const { error: errorCreate, loading: loadingCreate, success: successCreate } = messageCreate


  const settingsContact = useSelector(state => state.settingsContact)
  const { us_email, us_phone, us_address } = settingsContact


  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (!us_email || !us_phone || !us_address) {
      dispatch(settingsContactAction());
    }
  }, [dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createMessageAction({
      name,
      email,
      phone,
      subject,
      body
    }))
    if (successCreate) {
      setName('')
      setEmail('')
      setPhone('')
      setSubject('')
      setBody('')
    }
  }

  return (

    <div className="fhst-contact">
      {loadingCreate
        ? (<Loader />)
        : errorCreate
          ? (<Message variant='danger'>{errorCreate}</Message>)
          : (
            <div className="fhst-narrow-content">
              {successCreate &&
                <Message variant='success'>Your message sent successfully.</Message>
              }
              <div className="row">
                <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                  <h2 className="fhst-heading">Get in Touch</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  {us_email &&
                    <div className="fhst-feature fhst-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div className="fhst-icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="fhst-text">
                        <p><a href="#">{us_email}</a></p>
                      </div>
                    </div>}

                  {us_address &&
                    <div className="fhst-feature fhst-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div className="fhst-icon">
                        <i className="fas fa-map-marked"></i>
                      </div>
                      <div className="fhst-text">
                        <p>{us_address}</p>
                      </div>
                    </div>}
                  {us_phone &&
                    <div className="fhst-feature fhst-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div className="fhst-icon">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="fhst-text">
                        <p><a href="tel://">{us_phone}</a></p>
                      </div>
                    </div>
                  }
                </div>
                <div className="col-md-7 col-md-push-1">
                  <div className="row">
                    <div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInLeft">
                      <Form onSubmit={submitHandler}>
                        <div className="form-group">
                          <Form.Control
                            required
                            type='text'
                            placeholder='Name'
                            value={name ?? ""}
                            onChange={(e) => setName(e.target.value)}
                          >
                          </Form.Control>
                        </div>
                        <div className="form-group">
                          <Form.Control
                            required
                            type='email'
                            placeholder='Email'
                            value={email ?? ""}
                            onChange={(e) => setEmail(e.target.value)}
                          >
                          </Form.Control>
                        </div>
                        <div className="form-group">
                          <Form.Control
                            type='text'
                            placeholder='Phone Number (optional)'
                            value={phone ?? ""}
                            onChange={(e) => setPhone(e.target.value)}
                          >
                          </Form.Control>
                        </div>
                        <div className="form-group">
                          <Form.Control
                            required
                            type='text'
                            placeholder='Subject'
                            value={subject ?? ""}
                            onChange={(e) => setSubject(e.target.value)}
                          >
                          </Form.Control>
                        </div>
                        <div className="form-group">
                          <Form.Control as='textarea'
                            required
                            className='h-100'
                            rows='5'
                            placeholder='Leave Message'
                            value={body ?? ""}
                            onChange={(e) => setBody(e.target.value)}
                          >
                          </Form.Control>
                        </div>
                        <div className="form-group">
                          <input type="submit" className="btn btn-primary btn-send-message" value="Send Message"
                            disabled={!name.trim() || !body.trim() || !subject.trim()} />
                        </div>
                      </Form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
    </div>
  )
}

export default ContactUsScreen