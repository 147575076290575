import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { userListAction } from '../../../actions/userActions'
import { useSearchParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { Table, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';

function AdminMemberEdit() {

    const navigation = useNavigate();
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    if (!userInfo || !userInfo.isAdmin) {
        navigation('/login')
    }
    const dispatch = useDispatch()
    alert = useAlert()
    const userList = useSelector(state => state.userList)
    const { loading, error, members } = userList
    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    if (!q) {
        q = ''
    }

    const [reload, setReload] = useState(false)

    async function deleteUserHandler(id) {
        if (window.confirm('Are you sure you want to delete this user?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.delete(
                `/api/delete/Profile/${id}/`,
                config
            )
            alert.error('Member deleted successfully.')
            setReload(true)
        }
    }


    useEffect(() => {
        if (reload) {
            setReload(false)
        }
        dispatch(userListAction())
    }, [dispatch, reload])

    return (
        <div>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <>
                            <PageTitle title='Manage Members'></PageTitle>
                            <Button onClick={() => navigation('./register')}><i className='fas fa-plus'></i> Add Scientific Board</Button>
                            <Table striped bordered hover responsive className='table-sm mt-3'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Affiliation</th>
                                        <th>Field of study</th>
                                        <th>Edit / Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {members && members.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.affiliation}</td>
                                            <td>{item.field_of_study}</td>
                                            <td>
                                                <LinkContainer to={`edit/${item.id}`}>
                                                    <Button variant='warning' className='btn-sm'>
                                                        <i className='fas fa-edit'></i>
                                                    </Button>
                                                </LinkContainer>
                                                {(item.username !== 'admin' &&
                                                    <Button variant='danger' className='btn-sm'
                                                        onClick={() => deleteUserHandler(item.id)}
                                                    >
                                                        <i className='fas fa-trash'></i>
                                                    </Button>)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
        </div>
    )
}

export default AdminMemberEdit