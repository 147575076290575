export const ALL_CATALOG_REQUEST =  'ALL_CATALOG_REQUEST'
export const ALL_CATALOG_SUCCESS =  'ALL_CATALOG_SUCCESS'
export const ALL_CATALOG_FAIL =  'ALL_CATALOG_FAIL'
export const ALL_CATALOG_RESET =  'ALL_CATALOG_RESET'

export const SINGLE_CATALOG_REQUEST =  'SINGLE_CATALOG_REQUEST'
export const SINGLE_CATALOG_SUCCESS =  'SINGLE_CATALOG_SUCCESS'
export const SINGLE_CATALOG_FAIL =  'SINGLE_CATALOG_FAIL'
export const SINGLE_CATALOG_RESET =  'SINGLE_CATALOG_RESET'


export const CATALOG_UPDATE_REQUEST = 'CATALOG_UPDATE_REQUEST'
export const CATALOG_UPDATE_SUCCESS = 'CATALOG_UPDATE_SUCCESS'
export const CATALOG_UPDATE_FAIL = 'CATALOG_UPDATE_FAIL'
export const CATALOG_UPDATE_RESET = 'CATALOG_UPDATE_RESET'

export const TREE_SEARCH_REQUEST =  'TREE_SEARCH_REQUEST'
export const TREE_SEARCH_SUCCESS =  'TREE_SEARCH_SUCCESS'
export const TREE_SEARCH_FAIL =  'TREE_SEARCH_FAIL'

export const TREE_RESULT_REQUEST =  'TREE_RESULT_REQUEST'
export const TREE_RESULT_SUCCESS =  'TREE_RESULT_SUCCESS'
export const TREE_RESULT_FAIL =  'TREE_RESULT_FAIL'

export const ADVANCED_SEARCH_REQUEST =  'ADVANCED_SEARCH_REQUEST'
export const ADVANCED_SEARCH_SUCCESS =  'ADVANCED_SEARCH_SUCCESS'
export const ADVANCED_SEARCH_FAIL =  'ADVANCED_SEARCH_FAIL'