import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsContactAction, updateSettingsContactAction } from '../../../actions/assetActions'
import { SETTINGS_CONTACT_UPDATE_RESET } from '../../../constants/assetConstants'
import { useAlert } from 'react-alert'

function AdminEditContact() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();
    const [contact_us_email, setContact_us_email] = useState('')
    const [contact_us_address, setContact_us_address] = useState('')
    const [contact_us_tel, setContact_us_tel] = useState('')
    const dispatch = useDispatch()
    const settingsContact = useSelector(state => state.settingsContact)
    const { error, loading, us_email, us_phone, us_address } = settingsContact
    const settingsUpdate = useSelector(state => state.settingsContactUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsUpdate
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        if (successUpdate) {
            dispatch({ type: SETTINGS_CONTACT_UPDATE_RESET })
            dispatch(settingsContactAction())
        } else {
            if (!us_email) {
                dispatch(settingsContactAction())
            } else {
                setContact_us_email(us_email)
                setContact_us_address(us_address)
                setContact_us_tel(us_phone)
            }
        }
    }, [dispatch, us_email, us_address, us_phone, successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateSettingsContactAction({
            contact_us_email,
            contact_us_address,
            contact_us_tel,
        }))
        alert.show('Contact information Changed successfully')
    }
    return (
        <div>
            {loading || loadingUpdate
                ? (<Loader />)
                : error || errorUpdate ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (
                        <Row>
                            <h1 className='mb-5'>Site settings</h1>
                            {us_email &&
                                <Form onSubmit={submitHandler}>
                                    <h1>Edit Contact Us page fields</h1>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            Email:
                                            <Form.Control
                                                type='text'
                                                placeholder='Contact Us Email'
                                                value={contact_us_email ?? ""}
                                                onChange={(e) => setContact_us_email(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            Address:
                                            <Form.Control
                                                type='text'
                                                placeholder='Contact Us Address'
                                                value={contact_us_address ?? ""}
                                                onChange={(e) => setContact_us_address(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                        Phone number:
                                            <Form.Control
                                                type='text'
                                                placeholder='Contact Us Phone Number'
                                                value={contact_us_tel ?? ""}
                                                onChange={(e) => setContact_us_tel(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>

                                    <Button type='submit' variant='primary'>
                                    <i className='fas fa-edit'></i> Update
                                    </Button>

                                </Form>
                            }
                        </Row>

                    )
            }
        </div >
    )
}

export default AdminEditContact