import axios from 'axios'

import {

    ALL_CATALOG_REQUEST,
    ALL_CATALOG_SUCCESS,
    ALL_CATALOG_FAIL,

    SINGLE_CATALOG_REQUEST,
    SINGLE_CATALOG_SUCCESS,
    SINGLE_CATALOG_FAIL,

    CATALOG_UPDATE_REQUEST,
    CATALOG_UPDATE_SUCCESS,
    CATALOG_UPDATE_FAIL,

    TREE_SEARCH_REQUEST,
    TREE_SEARCH_SUCCESS,
    TREE_SEARCH_FAIL,

    TREE_RESULT_REQUEST,
    TREE_RESULT_SUCCESS,
    TREE_RESULT_FAIL,

    ADVANCED_SEARCH_REQUEST,
    ADVANCED_SEARCH_SUCCESS,
    ADVANCED_SEARCH_FAIL,

} from '../constants/phylogenyConstants'


//------------------------------------ WORLD Catalogue


export const allCatalogAction = (q = '', p = '') => async (dispatch) => {
    try {
        dispatch({ type: ALL_CATALOG_REQUEST })

        const { data } = await axios.get(`/api/phylogeny/catalog/search-simple/?q=${q}&page=${p}`)

        dispatch({
            type: ALL_CATALOG_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_CATALOG_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const catalogSingleAction = (tbl, id) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_CATALOG_REQUEST })

        const { data } = await axios.get(`/api/phylogeny/catalog/${tbl}/${id}/`)

        dispatch({
            type: SINGLE_CATALOG_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SINGLE_CATALOG_FAIL,
            payload: error.response && error.response.data.message.detail
                ? error.response.data.message.detail
                : error.message,
        })

    }
}


export const updatecatalogAction = (catalog) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATALOG_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/phylogeny/catalog/update/${catalog.tbl.toLowerCase()}/${catalog.id}/`,
            catalog,
            config
        )
        dispatch({
            type: CATALOG_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CATALOG_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const treeSearchAction = () => async (dispatch) => {
    try {
        dispatch({ type: TREE_SEARCH_REQUEST })

        const { data } = await axios.get('/api/phylogeny/catalog/search-tree/')

        dispatch({
            type: TREE_SEARCH_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TREE_SEARCH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const treeResultAction = (tree = '', p = '') => async (dispatch) => {
    try {
        dispatch({ type: TREE_RESULT_REQUEST })
        const { data } = await axios.get(`/api/phylogeny/catalog/search-tree-results/${tree}/?page=${p}`)
        dispatch({
            type: TREE_RESULT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TREE_RESULT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const advancedSearchAction = (A = '', B = '', C = '', D = '', E = '', F = '', G = '', H = '', p = '') => async (dispatch) => {
    try {
        dispatch({ type: ADVANCED_SEARCH_REQUEST })

        const { data } = await axios.get(`/api/phylogeny/catalog/search-advanced/?A=${A}&B=${B}&C=${C}&D=${D}&E=${E}&F=${F}&G=${G}&H=${H}&page=${p}`)

        dispatch({
            type: ADVANCED_SEARCH_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADVANCED_SEARCH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
