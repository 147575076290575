import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { mainAlbumAction } from '../../actions/assetActions'
import Loader from '../Loader'
import Message from '../Message'
import { Row } from 'react-bootstrap'

function MainSlider() {
    const [fader, setFader] = useState(true)
    const [selected, setSelected] = useState(1)
    const dispatch = useDispatch();
    const albumMain = useSelector(state => state.mainAlbum)
    const { loading: loadingMainAlbum, error: errorMainAlbum, albums: albumsMain, count } = albumMain

    const changeSelected = (num) => {
        setSelected(num)
        if (fader) {
            setFader(false)
        } else {
            setFader(true)
        }
    }
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    var isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    function handleResize() {
        setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    const updateTime = () => {
        if (count && count > 1) {
            if (selected < count && selected > 0) {
                setSelected(selected + 1)
            } else {
                setSelected(1)
            }
        }
    }
    useEffect(() => {
        if (!albumsMain || !albumsMain.length) {
            dispatch(mainAlbumAction());
        }
        const interval = setInterval(() => {
            updateTime();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [selected, count]);

    return (
        <Row Style='width:107%;'>
            {loadingMainAlbum
                ? (<Loader />)
                : errorMainAlbum
                    ? (<Message variant='danger'>{errorMainAlbum}</Message>)
                    : (
                        <aside id="fhst-hero" Style={isMobile.any() ? null : `height: ${windowHeight}px; margin-left: -3rem`}
                            className={fader ? 'fadeInLeft animated' : 'fadeInRight animated'}>
                            <div className="flexslider" Style={isMobile.any() ? null : `height: ${window.innerHeight}px`}>
                                <ul className="slides">
                                    {albumsMain && albumsMain.length && albumsMain.map((item, index) => (
                                        <li Style={`background-image: url(${item.image});width: 100%;float: left;margin-right: -100%;position: relative;opacity:${(index + 1) === selected ? '1' : '0'};display: block;z-index:${(index + 1) === selected ? '2' : '1'};`} key={index + 1}>
                                            <div className="overlay"></div>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-12  col-sm-12 col-xs-12 slider-text"
                                                        Style={isMobile.any() ? null : `height: ${windowHeight}px;`}>
                                                        <div className="slider-text-inner">
                                                            <div className="desc">
                                                            {item.caption && <div dangerouslySetInnerHTML={{ __html: item.caption }} className='text-justify'></div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <ol className="flex-control-nav flex-control-paging">
                                    {albumsMain && albumsMain.length && albumsMain.map((item, index) => (
                                        <li key={index + 1}><a href="#" className={selected === (index + 1) ? "flex-active" : null} onClick={() => changeSelected(index + 1)}>{index + 1}</a></li>
                                    ))}
                                </ol>
                            </div>
                        </aside>
                    )}

        </Row>

    )
}

export default MainSlider