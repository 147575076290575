import React from 'react'
import { isMobile } from 'react-device-detect'

function PageTitle({title}) {
  return (
    <div className={isMobile ? 'page-title mt-50': 'page-title'}>
        <h1 className='fhst-heading'>{title}</h1>
    </div>
  )
}

export default PageTitle