import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listNews, allNews } from '../../actions/assetActions'
import { Link, useSearchParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import SearchBox from '../../components/SearchBox'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import PageTitle from '../../components/PageTitle'
import { isMobile } from 'react-device-detect'

function NewsScreen() {
  const dispatch = useDispatch()

  const newsList = useSelector(state => state.listNews)
  const { loading, error, news, pages, page, has_next, has_prev, count } = newsList

  const allNewsList = useSelector(state => state.allNews)
  const { news: newsAllList } = allNewsList

  const [searchParams] = useSearchParams();
  let q = searchParams.get('q')
  let p = searchParams.get('page')
  if (!q) {
    q = ''
  }
  const [pager, setPager] = useState('')
  const [qer, setQer] = useState('')
  useEffect(() => {
    if (!news || !news.length || (p && pager !== p) || (q !== qer) || (qer !== '' && q==='')) {
      dispatch(listNews(q, p))
    }

    if (p !== pager) {
      setPager(p)
    }
    if (q !== qer) {
      setQer(q)
    }

    if (!newsAllList || !newsAllList.length) {
      dispatch(allNews())
    }

  }, [dispatch, q, p])


  return (
    <div>
      <PageTitle title='News' />
      <SearchBox placeholder={'Search news'} count={count} q={q} data={newsAllList} next={'news'} />
      <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />

      {loading
        ? (<Loader />)
        : error
          ? (<Message variant='danger'>{error}</Message>)
          : (
            <Row>
              {news.map(item => (
                <Col key={item.id} sm={6} md={4} lg={6} xl={4}>
                  <div className="blog-entry p-3 fadeInLeft animated">
                    <Link to={`/assets/news/${item.id}/`} className="blog-img">
                      <img src={item.img} className="img-responsive img-fit" alt={item.header} />
                    </Link>
                    <span><small>{item.created} </small> | <small> {item.reference} </small></span>
                    <h3><Link to={`/assets/news/${item.id}/`}>{item.header}</Link></h3>
                    <div className={isMobile ? 'text-mobile' : 'text-sm'}>
                      {item.abstract}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
      <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />
    </div>
  )
}

export default NewsScreen