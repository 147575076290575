import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { listObituaries } from '../../../actions/herbulotActions'
import { useSearchParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { Table, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import PageTitle from '../../../components/PageTitle'

function AdminClaudeScreen() {
    const dispatch = useDispatch()
    const [deleted, setDeleted] = useState(true)
    alert = useAlert()
    const obitList = useSelector(state => state.obitList)
    const { loading, error, obit } = obitList
    const obitCreate = useSelector(state => state.obitCreate)
    const { success: successCreate } = obitCreate
    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    let p = searchParams.get('page')
    if (!q) {
        q = ''
    }
    const navigation = useNavigate();
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    async function deleteHandler(tbl, id) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.delete(
                `/api/delete/${tbl}/${id}/`,
                config
            )
            setDeleted(true)
            alert.error('Item deleted successfully.')
        }
    }
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        setDeleted(false)
        dispatch(listObituaries())
    }, [dispatch, q, p, deleted, successCreate])
    return (
        <div>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <>
                            <PageTitle title='Claude Herbulot obituaries' />
                            <LinkContainer to={`/admin/obituaries/add`}>
                                <Button variant='success' className='btn-sm'>
                                    <i className='fas fa-plus'></i> Add Obituaries
                                </Button>
                            </LinkContainer>
                            <Table striped bordered hover responsive className='table-sm mt-3'>
                                <thead>
                                    <tr>
                                        <th>List of obituaries</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {obit && obit.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.header}</td>
                                            <td>
                                                <LinkContainer to={`/admin/obituaries/edit/${item.id}`}>
                                                    <Button variant='warning' className='btn-sm'>
                                                        <i className='fas fa-edit'></i>
                                                    </Button>
                                                </LinkContainer>

                                                <Button variant='danger' className='btn-sm' onClick={() => deleteHandler('obituaries', item.id)}>
                                                    <i className='fas fa-trash'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}

        </div>)
}

export default AdminClaudeScreen