import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsArchiveAction } from '../../../actions/assetActions'
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import { saveAs } from "file-saver";
import { SITE_URL } from '../../../constants/siteConstants';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
import { LinkContainer } from 'react-router-bootstrap'


function AdminArchiveScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const settingsArchive = useSelector(state => state.settingsArchive)
    const [deleted, setDeleted] = useState(false)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();
    const dispatch = useDispatch()
    const { error, empty, loading, archiveList } = settingsArchive

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

        if (!archiveList || deleted) {
            if (!empty) {
                dispatch(settingsArchiveAction())
            }
            if (deleted) {
                setDeleted(false)
            }
        }
    }, [archiveList, deleted])

    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    async function deleteHandler(tbl, id) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.delete(
                `/api/delete/${tbl}/${id}/`,
                config
            )
            setDeleted(true)
            alert.error('Item deleted successfully.')
        }
    }

    return (
        <div>
            {loading
                ? (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (
                        <Row>
                            <Col md={12}>
                                <h1 className='mb-5'>Site Archive</h1>
                                <LinkContainer to={`/admin/archive/add`}>
                                    <Button variant='success' className='btn-sm'>
                                        <i className='fas fa-plus'></i> Create new record
                                    </Button>
                                </LinkContainer>
                                <tbody>
                                    {archiveList && archiveList.length ? archiveList.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.title}</td>
                                            <td>
                                                <LinkContainer to={`edit/${item.id}`}>
                                                    <i className='fas fa-edit cursor-pointer text-warning mx-2'></i>
                                                </LinkContainer>
                                            </td>
                                            <td>
                                                <i className='fas fa-trash mx-2 text-danger cursor-pointer'
                                                    onClick={() => deleteHandler('Archive', item.id)}>
                                                </i>
                                            </td>
                                        </tr>
                                    )) :
                                        <h2>No Archive Record Exists.</h2>}
                                </tbody>
                            </Col >
                        </Row>
                    )}

            <hr></hr>


        </div>
    )
}

export default AdminArchiveScreen