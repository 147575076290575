import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { disclaimerAction } from '../../actions/assetActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PageTitle from '../../components/PageTitle'

function DisclaimerScreen() {

    const disclaimerList = useSelector(state => state.disclaimerList)
    const { error, loading, header, text } = disclaimerList

    const dispatch = useDispatch();

    useEffect(() => {
        if (!header) {
            dispatch(disclaimerAction())
        }
    }, [dispatch, header])

    return (
        <div className='mx-4'>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <span>
                            <PageTitle title={header} />
                            <div dangerouslySetInnerHTML={{ __html: text }} className='text-justify'></div>
                        </span>
                    )}
        </div>
    )
}

export default DisclaimerScreen