import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { catalogSingleAction, updatecatalogAction } from '../../../actions/phylogenyActions';
import Loader from '../../../components/Loader';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
import ManageImage from '../../../components/ManageImage';

function AdminEditCatalogue({ tbl, id, cancleEditMode }) {
  alert=useAlert()
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  const [docText, setDocText] = useState(false)
  const [mapText, setMapText] = useState(false)
  const [changes, setChanges] = useState(false)
  if (!userInfo.isAdmin) {
    Navigate('/')
  }
  const [super_family, setsuper_family] = useState('')
  const [family, setfamily] = useState('')
  const [sub_family, setsub_family] = useState('')
  const [tribe, settribe] = useState('')
  const [genus, setgenus] = useState('')
  const [author, setauthor] = useState('')
  const [year, setyear] = useState('')
  const [senior_species, setsenior_species] = useState('')
  const [senior_sub_species, setsenior_sub_species] = useState('')
  const [senior_species_author, setsenior_species_author] = useState('')
  const [senior_species_year, setsenior_species_year] = useState('')
  const [species, setspecies] = useState('')
  const [subspecies, setsubspecies] = useState('')
  const [species_author, setspecies_author] = useState('')
  const [species_year, setspecies_year] = useState('')
  const [species_in_author, setspecies_in_author] = useState('')
  const [original_genus, setoriginal_genus] = useState('')
  const [country, setcountry] = useState('')
  const [junior_synonym, setjunior_synonym] = useState('')
  const [note, setnote] = useState('')

  const dispatch = useDispatch();
  const item = useSelector(state => state.singleCatalog)
  const {catalog } = item

  const updateHandler = () => {
    dispatch(updatecatalogAction({
      id, tbl,
      super_family, family, sub_family, tribe, genus, author, year, senior_species, senior_sub_species, senior_species_author,
      senior_species_year, species, subspecies, species_author, species_year, species_in_author, original_genus, country, junior_synonym, note
    }))
    dispatch({ type: 'ALL_CATALOG_RESET' })
    alert.show('record changed successfully')
    cancleEditMode()
  }
  const [uploadingImage, setUploadingImage] = useState(false)
  const [uploadingDoc, setUploadingDoc] = useState(false)
  const uploadDocHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('docfile', file)
    formData.append('id', id)
    formData.append('tbl', tbl)
    formData.append('prev', catalog.docfile)
    setUploadingDoc(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      await axios.post('/api/upload/doc/', formData, config)
      setUploadingDoc(false)
      setDocText(true)
      alert.show('Document Changed Successfully.')
    } catch (error) {
      setUploadingImage(false)
    }
  }
  const uploadMapHandler = async (e) => {
    const file = e.target.files[0]
    if (file.size > 102400) {
      alert.error('Image size should be less than 100KB')
      return
    }
    const formData = new FormData()
    formData.append('img', file)
    formData.append('id', id)
    formData.append('tbl', tbl)
    formData.append('prev', catalog.img)
    setUploadingImage(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      await axios.post('/api/upload/image/', formData, config)
      alert.show('Image uploaded successfully')
      setUploadingImage(false)
      setMapText(true)
      setChanges(true)
    } catch (error) {
      setUploadingImage(false)
    }
  }
  const [caption, setCaption] = useState('')
  const [uploadingGallery, setUploadingGallery] = useState(false)
  const uploadGalleryHandler = async (e) => {
    const file = e.target.files[0]
    if (file.size > 102400) {
      alert.error('Image size should be less than 100KB')
      return
    }
    const formData = new FormData()
    formData.append('image', file)
    formData.append('caption', caption)
     setUploadingGallery(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      await axios.post(`/api/phylogeny/catalog/${tbl.toLowerCase()}/images/${id}/`, formData, config)
      alert.show('Image uploaded successfully')
      setUploadingGallery(false)
      setChanges(true)
    } catch (error) {
      setUploadingGallery(false)
    }
  }
  async function clearDocHandler(e) {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      await axios.get(
        `/api/doc/clear/${tbl}/${e.target.id}/`,
        config
      )
      alert.error('Document cleared.')
      setChanges(true)
    }
  }
  async function clearImageHandler(e) {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      await axios.get(
        `/api/image/clear/${tbl}/${e.target.id}/`,
        config
      )
      alert.error('Image removed.')
      setChanges(true)
    }
  }
  useEffect(() => {
    if (!catalog || changes) {
      dispatch(catalogSingleAction(tbl, id))
    }
    setChanges(false)
    if (catalog && catalog.id) {
      setsuper_family(catalog.super_family)
      setfamily(catalog.family)
      setsub_family(catalog.sub_family)
      settribe(catalog.tribe)
      setgenus(catalog.genus)
      setauthor(catalog.author)
      setyear(catalog.year)
      setsenior_species(catalog.senior_species)
      setsenior_sub_species(catalog.senior_sub_species)
      setsenior_species_author(catalog.senior_species_author)
      setsenior_species_year(catalog.senior_species_year)
      setspecies(catalog.species)
      setsubspecies(catalog.subspecies)
      setspecies_author(catalog.species_author)
      setspecies_year(catalog.species_year)
      setspecies_in_author(catalog.species_in_author)
      setoriginal_genus(catalog.original_genus)
      setcountry(catalog.country)
      setjunior_synonym(catalog.junior_synonym)
      setnote(catalog.note)
    }
  }, [dispatch, id, catalog, changes])
  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']
    ]
  }
  const changerHandler = () => {
    setChanges(true)
  }
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={3}>
          Superfamily
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Superfamily'
            value={super_family ?? ""}
            onChange={(e) => setsuper_family(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          Family
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Family'
            value={family ?? ""}
            onChange={(e) => setfamily(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          Subfamily
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Subfamily'
            value={sub_family ?? ""}
            onChange={(e) => setsub_family(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          Tribe
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Tribe'
            value={tribe ?? ""}
            onChange={(e) => settribe(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          Genus
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Genus'
            value={genus ?? ""}
            onChange={(e) => setgenus(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          author
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='author'
            value={author ?? ""}
            onChange={(e) => setauthor(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          year
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='year'
            value={year ?? ""}
            onChange={(e) => setyear(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          senior_species
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='senior_species'
            value={senior_species ?? ""}
            onChange={(e) => setsenior_species(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          senior_sub_species
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='senior_sub_species'
            value={senior_sub_species ?? ""}
            onChange={(e) => setsenior_sub_species(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          senior_species_author
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='senior_species_author'
            value={senior_species_author ?? ""}
            onChange={(e) => setsenior_species_author(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          senior_species_year
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='senior_species_year'
            value={senior_species_year ?? ""}
            onChange={(e) => setsenior_species_year(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          species
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='species'
            value={species ?? ""}
            onChange={(e) => setspecies(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          subspecies
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='subspecies'
            value={subspecies ?? ""}
            onChange={(e) => setsubspecies(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          species_author
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='species_author'
            value={species_author ?? ""}
            onChange={(e) => setspecies_author(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          species_year
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='species_year'
            value={species_year ?? ""}
            onChange={(e) => setspecies_year(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          species_in_author
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='species_in_author'
            value={species_in_author ?? ""}
            onChange={(e) => setspecies_in_author(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          original_genus
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='original_genus'
            value={original_genus ?? ""}
            onChange={(e) => setoriginal_genus(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          country
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='country'
            value={country ?? ""}
            onChange={(e) => setcountry(e.target.value)}
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={6} lg={3}>
          junior_synonym
          {/* <Form.Control
            className='mb-3'
            type='text'
            placeholder='junior_synonym'
            value={junior_synonym ?? ""}
            onChange={(e) => setjunior_synonym(e.target.value)}
          >
          </Form.Control> */}
          <Form.Control 
            className='mb-3'
            as='select'
            onChange={(e) => setjunior_synonym(e.target.value)}
          >
            <option value="True" selected={!junior_synonym}>Y</option>
            <option value="False" selected={!junior_synonym}>N</option>
          </Form.Control>
        </Col>

        <Row>
          <Col sm={12} md={6} lg={6}>
            note
            <ReactQuill theme="snow" value={note ?? ""} onChange={setnote} modules={quillModules}  preserveWhitespace="true" />
          </Col>
          <Col>
            Document File {catalog && catalog.docfile &&
              <div>
                {catalog.docfile}
                <small className='text-color-2 mx-2 cursor-pointer' id={catalog.id}
                  onClick={clearDocHandler}>clear</small>
              </div>
            }
            <Form.Control
              id='doc'
              type='file'
              placeholder='file'
              onChange={uploadDocHandler}
            >
            </Form.Control>
            {uploadingDoc && <Loader />}


            <div className='my-2'>Map file</div>
            {catalog && catalog.img &&
              <span className="project w-50 mb-5 d-inline-block" Style={`background-image: url(${catalog.img}); height: 120px;`}>
                <div className="desc">
                  <div className="con">
                    <h3>
                      <span id={catalog.id} onClick={clearImageHandler} className="tag tag--pill">
                        Delete Image
                      </span>
                    </h3>
                  </div>
                </div>
              </span>
            }
            <Form.Control
              id='map'
              type='file'
              placeholder='file'
              onChange={uploadMapHandler}
            >
            </Form.Control>
            {uploadingImage && <Loader />}

          </Col>
          <Button variant='primary' onClick={() => updateHandler()}><i className='fas fa-edit'></i> Update</Button>
          <Button variant={mapText || docText ? 'success' : 'warning'} onClick={() => cancleEditMode()}>
            {mapText || docText ? 'Done (close update form)' :
              'Cancle'
            }
          </Button>
        </Row>
        <hr></hr>
      </Row>
      <Row>


      </Row>
      <Row>
        <Col sm={12} md={6} lg={8}>
          {catalog && catalog.album.length > 0 ?
            catalog.album.map(item => (
              <ManageImage item={item} tbl={tbl === 'CatalogN' ? 'AlbumCatalogN' : 'AlbumCatalogY'} token={userInfo.token} changerHandler={changerHandler} key={item.id} />
            ))
            :
            <small className='text-color-2'>
              Currently there is no image in photo gallery.
            </small>
          }
        </Col>
        <Col sm={12} md={6} lg={4}>
          <h5>Add new image to photo gallery</h5>
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Enter caption'
            value={caption ?? ""}
            onChange={(e) => setCaption(e.target.value)}
          >
          </Form.Control>
          <Form.Control
            id='galleryimg'
            type='file'
            placeholder='image'
            onChange={uploadGalleryHandler}
          >
          </Form.Control>
          {uploadingGallery && <Loader />}
        </Col>
      </Row>
    </div >
  )
}

export default AdminEditCatalogue