import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { listMessage } from '../../../actions/userActions'
import { useSearchParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import Paginate from '../../../components/Paginate'
import { Table, Button } from 'react-bootstrap'
import { FaEnvelopeOpen } from "react-icons/fa";
import PageTitle from '../../../components/PageTitle' 

function AdminMessagesScreen() {
    const dispatch = useDispatch()
    alert = useAlert()
    const messageList = useSelector(state => state.listMessage)
    const { loading, error, message, pages, page, has_next, has_prev, count_read, count_unread } = messageList


    const [searchParams] = useSearchParams();
    let p = searchParams.get('page')

    const navigation = useNavigate();

    const [successDelete, setSuccessDelete] = useState(false)
    const [visited, setVisited] = useState(false)
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [msg, setMsg] = useState('')
    const [msgID, setMsgID] = useState('')


    async function deleteHandler(id) {
        if (window.confirm(`Are you sure you want to delete this item ?`)) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.delete(
                `/api/delete/Message/${id}/`,
                config
            )
        }
        setSuccessDelete(true)
        alert.error('Message deleted successfully.')
    }
    async function readMessage(id, text) {
        setMsg(text)
        setMsgID(id)
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        await axios.get(
            `/api/users/contact/read/${id}/`,
            config
        )
        setVisited(true)
    }
    useEffect(() => {
        if (successDelete) {
            setSuccessDelete(false)
        }
        setVisited(false)
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        dispatch(listMessage(p))
    }, [dispatch, p, successDelete, visited])

    return (
        <div>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <>
                            <PageTitle title={`${count_unread} Unread & ${count_read} read Messages`}/>

                            <Table striped bordered hover responsive className='table-sm mt-3'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Date</th>
                                        <th>Subject</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {message.map(item => (
                                        <tr key={item.id} Style={msgID === item.id ?'background-color:#FFC300;' : null}>
                                            <td>{item.is_read ? <FaEnvelopeOpen className="m-2" />
                                                : <i className='fas fa-envelope text-color-3 m-2'></i>}
                                                {item.name} {item.id}
                                            </td>
                                            <td>{item.email}</td>
                                            <td>{item.created}</td>
                                            <td>{item.subject}</td>
                                            <td>
                                                <Button variant='info' className='btn-sm' onClick={() => readMessage(item.id, item.body)}>
                                                    <i className='fas fa-eye'></i>
                                                </Button>

                                                <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(item.id)}>
                                                    <i className='fas fa-trash'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <hr></hr>
                            <div className='mt-4 text-color-4'>
                                {msg}
                            </div>
                        </>
                    )}
            <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} isAdmin={true} />

        </div>
    )
}

export default AdminMessagesScreen