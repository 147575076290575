import React from 'react'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { useLocation } from 'react-router-dom';

function SocialMedia(props) {
    const location = useLocation();
    const { pathname } = location;
    const address = "https://geometroidea.smns-bw.org" + pathname

    return (
        <div className="row social-btns">
            <div className="col-md-12 col-sm-12 col-xs-12 social-btn">
                <FacebookShareButton url={address} quote={props.text}>
                    <i className="fab fa-facebook text-me btn-facebook" />
                </FacebookShareButton>
                <TwitterShareButton url={address} title={props.text}>
                    <i className="fab fa-twitter-square text-me twt-btn" />
                </TwitterShareButton>
                {/* <WhatsappShareButton url={address} title={props.text}>
                    <i className="fab fa-whatsapp  text-me whtsp-btn" />
                </WhatsappShareButton> */}
            </div>
        </div>
    )
}

export default SocialMedia