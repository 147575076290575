import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsMundiAction, updateSettingsMundiAction } from '../../../actions/assetActions'
import { SETTINGS_MUNDI_UPDATE_RESET } from '../../../constants/assetConstants'
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
function AdminEditMundi() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();
    const [mundi, setMundi] = useState('')
    const dispatch = useDispatch()
    const settingsMundi = useSelector(state => state.settingsMundi)
    const { error, loading, m } = settingsMundi
    const settingsMundiUpdate = useSelector(state => state.settingsMundiUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsMundiUpdate
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        if (successUpdate) {
            dispatch({ type: SETTINGS_MUNDI_UPDATE_RESET })
            dispatch(settingsMundiAction())
        } else {
            if (!m) {
                dispatch(settingsMundiAction('a'))
            } else {
                setMundi(m)
            }
        }
    }, [dispatch, m, successUpdate])
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateSettingsMundiAction({
            mundi
        }))
        alert.show('Item Changed successfully')
    }
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
        ],
    }

    // let newV = ''
    // const quillUpdater = (e) => {
    //     newV= e
    // }
    return (
        <div>
            {loading || loadingUpdate
                ? (<Loader />)
                : error || errorUpdate ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (
                        <Row>
                            <Col md={12}>
                                <h1 className='mb-5'>Site settings</h1>
                                {m &&
                                    <Form onSubmit={submitHandler}>
                                        <Form.Group as={Row} controlId='mundi' className='mb-2'>
                                            <Form.Label column sm="2">Project Mundi</Form.Label>
                                            <Col sm="10">
                                                <ReactQuill
                                                    theme="snow"
                                                    value={mundi || ""}
                                                    onChange={setMundi}
                                                    modules={quillModules}
                                                     preserveWhitespace="true"
                                                    />
                                            </Col>
                                        </Form.Group>
                                        <Button type='submit' variant='primary'>
                                            <i className='fas fa-edit'></i> Update
                                        </Button>

                                    </Form>
                                }
                            </Col >
                        </Row>
                    )}
        </div>
    )
}

export default AdminEditMundi