import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createCongressAction } from '../../../actions/assetActions'
import { FaArrowCircleLeft } from "react-icons/fa";
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminCongressAddScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const navigation = useNavigate();
    alert = useAlert()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [participants, setParticipants] = useState('')
    const [programs, setPrograms] = useState('')
    const [proceedings, setProceedings] = useState('')
    const [chairman, setChairman] = useState('')
    const [venue, setVenue] = useState('')
    const [year, setYear] = useState('')
    const [doc, setDoc] = useState('')

    const dispatch = useDispatch()

    const submitHandler = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('title', title)
        formData.append('chairman', chairman)
        formData.append('description', description)
        formData.append('venue', venue)
        formData.append('year', year)
        formData.append('participants', participants.trim())
        formData.append('programs', programs.trim())
        formData.append('proceedings', proceedings.trim())
        formData.append('doc', doc)
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            await axios.post('/api/assets/congress/add/', formData, config)
        } catch (error) {
            alert.show('error occured')
        }
        alert.show('Congress created successfully')
        navigation('/admin/congresses/')
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],


            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    }
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
    }, [])

    return (
        <div>
            <Link to='/admin/congresses/'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>
            <Row>
                <Col sm={12} md={6} lg={8}>
                    <h1 className='mb-5'>Add congress</h1>
                    <Form onSubmit={submitHandler}>

                        <Form.Group as={Row} controlId='title' className='mb-2'>
                            <Form.Label column sm="2">Title</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    required
                                    type='title'
                                    placeholder='Enter title'
                                    value={title ?? ""}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-2'>
                            <Form.Label column sm="2">PDF</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    id='doc'
                                    type='file'
                                    placeholder='file'
                                    onChange={(e) => setDoc(e.target.files[0])}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='description' className='mb-2'>
                            <Form.Label column sm="2">Description</Form.Label>
                            <Col sm="10">
                                <ReactQuill theme="snow" value={description ?? ""} onChange={setDescription} modules={quillModules}  preserveWhitespace="true" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='chairman' className='mb-2'>
                            <Form.Label column sm="2">Organizer(s)</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    className='h-100'
                                    rows='5'
                                    placeholder='Enter Organizer(s)'
                                    value={chairman ?? ""}
                                    onChange={(e) => setChairman(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='venue' className='mb-2'>
                            <Form.Label column sm="2">Venue</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type='text'
                                    placeholder='Enter venue'
                                    value={venue ?? ""}
                                    onChange={(e) => setVenue(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId='year' className='mb-2'>
                            <Form.Label column sm="2">Year</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    required
                                    type='number'
                                    placeholder='Enter year'
                                    value={year ?? ""}
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId='description' className='mb-2'>
                            <Form.Label column sm="2">Participants</Form.Label>
                            <Col sm="10">
                                <ReactQuill theme="snow" value={participants ?? ""} onChange={setParticipants} modules={quillModules}  preserveWhitespace="true" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId='description' className='mb-2'>
                            <Form.Label column sm="2">Programs</Form.Label>
                            <Col sm="10">
                                <ReactQuill theme="snow" value={programs ?? ""} onChange={setPrograms} modules={quillModules}  preserveWhitespace="true" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId='description' className='mb-2'>
                            <Form.Label column sm="2">Proceedings</Form.Label>
                            <Col sm="10">
                                <ReactQuill theme="snow" value={proceedings ?? ""} onChange={setProceedings} modules={quillModules}  preserveWhitespace="true" />
                            </Col>
                        </Form.Group>
                        <Button type='submit' variant='primary'>
                            <i className='fas fa-plus'></i> Submit
                        </Button>

                    </Form>
                </Col >

            </Row>
        </div >
    )
}

export default AdminCongressAddScreen