import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lisHerbulotArticleAction } from '../../actions/herbulotActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PageTitle from '../../components/PageTitle'
import { Accordion } from 'react-bootstrap';
import { saveAs } from "file-saver";
import { SITE_URL } from '../../constants/siteConstants';
import { isMobile } from 'react-device-detect'

function PubScreen() {
    const dispatch = useDispatch()

    const articleList = useSelector(state => state.listHerbulotArticles)
    const { loading, error, articles } = articleList

    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    useEffect(() => {
        if (!articles || !articles.length) {
            dispatch(lisHerbulotArticleAction())
        }
    }, [dispatch])

    const [activePanel, SetActivePanel] = useState(0)

    const panelHandler = id => {
        if (id === activePanel) {
            SetActivePanel(-1)
        } else {
            SetActivePanel(id)
        }
    }
    return (
        <div>
            {(articles && articles.length) ? <PageTitle title='Publications' /> : null}

            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (

                        <Accordion defaultActiveKey="0">
                            {articles && articles.map((item, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header className={activePanel === index ? 'active' : ''} onClick={() => panelHandler(index)}>
                                        {item.year} <span className={isMobile ? 'text-mobile' : 'text-sm'}>({item.pubs.length > 1 ? `${item.pubs.length} publications` : '1 publication'})</span>
                                    </Accordion.Header>
                                    <Accordion.Body className="test">
                                        {item.pubs.map(publish => (
                                            <div className='text-sm mb-1' key={publish.id}>
                                                {publish.doc &&
                                                    <span className='dang text-me text-color-2 cursor-pointer m-2' onClick={() => saveFile(publish.doc)}>
                                                        <i className='fas fa-file-pdf'></i>
                                                    </span>
                                                }
                                                {publish.reference.split('https://')[1] ?
                                                    <div className='mb-2'>
                                                        <p>{`https://${publish.reference.split('https://')[1].replace('" rel="noopener noreferrer" target="_blank">', '')}`}</p>
                                                        <a href={`https://${publish.reference.split('https://')[1].replace('" rel="noopener noreferrer" target="_blank">', '')}`}
                                                            target='_blank' className='text-color-3'>
                                                            <div dangerouslySetInnerHTML={{ __html: publish.reference.split('https://')[0] }}></div>
                                                        </a>
                                                    </div>
                                                    :
                                                    <div dangerouslySetInnerHTML={{ __html: publish.reference }}></div>
                                                }
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}
        </div>)
}

export default PubScreen