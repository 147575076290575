import axios from 'axios'
import {
    OBITUARIES_LIST_REQUEST,
    OBITUARIES_LIST_SUCCESS,
    OBITUARIES_LIST_FAIL,

    OBITUARIES_DETAILS_REQUEST,
    OBITUARIES_DETAILS_SUCCESS,
    OBITUARIES_DETAILS_FAIL,

    OBITUARIES_CREATE_REQUEST,
    OBITUARIES_CREATE_SUCCESS,
    OBITUARIES_CREATE_FAIL,

    OBITUARIES_UPDATE_REQUEST,
    OBITUARIES_UPDATE_SUCCESS,
    OBITUARIES_UPDATE_FAIL,

    HERBULOT_ARTICLE_REQUEST,
    HERBULOT_ARTICLE_SUCCESS,
    HERBULOT_ARTICLE_FAIL,

    HERBULOT_ARTICLE_CREATE_REQUEST,
    HERBULOT_ARTICLE_CREATE_SUCCESS,
    HERBULOT_ARTICLE_CREATE_FAIL,

    HERBULOT_ARTICLE_UPDATE_REQUEST,
    HERBULOT_ARTICLE_UPDATE_SUCCESS,
    HERBULOT_ARTICLE_UPDATE_FAIL,

    TAXA_LIST_REQUEST,
    TAXA_LIST_SUCCESS,
    TAXA_LIST_FAIL,

    TAXA_CREATE_REQUEST,
    TAXA_CREATE_SUCCESS,
    TAXA_CREATE_FAIL,
} from '../constants/herbulotConstants'

// -------------------------------------------- OBITUARIES

export const listObituaries = () => async (dispatch) => {
    try {
        dispatch({ type: OBITUARIES_LIST_REQUEST })

        const { data } = await axios.get('/api/herbulot/obituaries/')

        dispatch({
            type: OBITUARIES_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: OBITUARIES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const obitDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: OBITUARIES_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/herbulot/obituaries/${id}/`)

        dispatch({
            type: OBITUARIES_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: OBITUARIES_DETAILS_FAIL,
            payload: error.response && error.response.data.message.detail
                ? error.response.data.message.detail
                : error.message,
        })

    }
}


export const updateObituaries = (obit) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OBITUARIES_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/herbulot/obituaries/update/${obit.id}/`,
            obit,
            config
        )
        dispatch({
            type: OBITUARIES_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: OBITUARIES_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createObituariesAction = (obit) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OBITUARIES_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/herbulot/obituaries/add/',
            obit,
            config
        )
        dispatch({
            type: OBITUARIES_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: OBITUARIES_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// -------------------------------------------- ARTICLES

export const lisHerbulotArticleAction = (q = '') => async (dispatch) => {
    try {
        dispatch({ type: HERBULOT_ARTICLE_REQUEST })

        const { data } = await axios.get(`/api/herbulot/articles/?q=${q}`)

        dispatch({
            type: HERBULOT_ARTICLE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: HERBULOT_ARTICLE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateHerbulotArticleAction = (article) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HERBULOT_ARTICLE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/herbulot/articles/update/${article.id}/`,
            article,
            config
        )
        dispatch({
            type: HERBULOT_ARTICLE_UPDATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: HERBULOT_ARTICLE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createHerbulotArticleAction = (article) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HERBULOT_ARTICLE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/herbulot/articles/add/',
            article,
            config
        )
        dispatch({
            type: HERBULOT_ARTICLE_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: HERBULOT_ARTICLE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// -------------------------------------------- TAXA

export const listTAXAAction = () => async (dispatch) => {
    try {
        dispatch({ type: TAXA_LIST_REQUEST })

        const { data } = await axios.get(`/api/herbulot/taxa/`)

        dispatch({
            type: TAXA_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TAXA_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createTAXAAction = (taxa) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TAXA_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/herbulot/taxa/add/',
            taxa,
            config
        )
        dispatch({
            type: TAXA_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TAXA_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}