import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { congressDetails } from '../../actions/assetActions';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import ImageGallery from '../../components/ImageGallery';
import SocialMedia from '../../components/SocialMedia';
import { SITE_URL } from '../../constants/siteConstants';
import { Row, Col } from 'react-bootstrap'

function SingleCongressScreen() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const item = useSelector(state => state.detailCongress)
    const { loading, error, congress } = item
    let albumExist = false
    if (Array.isArray(congress.album) && congress.album.length) {
        albumExist = true
    }
    useEffect(() => {
        if (!congress || id !== congress.id) {
            dispatch(congressDetails(id))
        }
    }, [dispatch, id])


    return (
        <React.Fragment>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <div>
                            <Link to='/assets/congresses/' className='fhst-heading3'>
                                <i className="fas fa-arrow-alt-circle-left cursor-pointer"></i>
                                Congresses
                            </Link>
                            <h1 className='m-5 text-center fhst-heading2'>{congress.title} </h1>
                            <SocialMedia
                                url={SITE_URL}
                                text={congress.title}
                            />
                            <img src={congress.img} className="img-responsive w-50 mb-3" alt={congress.title} />
                            <p className='text-me text-black mb-3'><i className="fa fa-user"></i> Chairman: {congress.chairman}</p>
                            <div dangerouslySetInnerHTML={{ __html: congress.description }} className='text-justify'></div>
                            {albumExist && (
                                <Row>
                                    <Col sm={12} md={12} lg={{ span: 8, offset: 2 }} className='mb-5'>
                                        <ImageGallery items={congress.album} />
                                    </Col>
                                </Row>
                            )}
                            {congress.venue && (
                                <div className='text-color-3 text-me mb-5'><i className="fa fa-map-marker mt-5"></i> Venue: {congress.venue}</div>
                            )}
                            {congress.participants ?
                                <span>
                                    <h2>Participants</h2>
                                    <div dangerouslySetInnerHTML={{ __html: congress.participants }}></div>
                                </span>
                                : null
                            }
                            {congress.programs ?
                                <span>
                                    <h2>Programs</h2>
                                    <div dangerouslySetInnerHTML={{ __html: congress.programs }}></div>
                                </span>
                                : null
                            }
                            {congress.proceedings ?
                                <span>
                                    <h2>Proceedings</h2>
                                    <div dangerouslySetInnerHTML={{ __html: congress.proceedings }}></div>
                                </span>
                                : null
                            }


                        </div>
                    )
            }
        </React.Fragment >
    )
}

export default SingleCongressScreen