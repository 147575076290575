
import {
    HIGHER_CLASSIFICATION_REQUEST,
    HIGHER_CLASSIFICATION_SUCCESS,
    HIGHER_CLASSIFICATION_FAIL,

    HIGHER_CLASSIFICATION_MENU_REQUEST,
    HIGHER_CLASSIFICATION_MENU_SUCCESS,
    HIGHER_CLASSIFICATION_MENU_FAIL,

    HIGHER_CLASSIFICATION_ADD_REQUEST,
    HIGHER_CLASSIFICATION_ADD_SUCCESS,
    HIGHER_CLASSIFICATION_ADD_FAIL,

    HIGHER_CLASSIFICATION_UPDATE_REQUEST,
    HIGHER_CLASSIFICATION_UPDATE_SUCCESS,
    HIGHER_CLASSIFICATION_UPDATE_FAIL,
    HIGHER_CLASSIFICATION_UPDATE_RESET,

    HIGHER_CLASSIFICATION_SINGLE_REQUEST,
    HIGHER_CLASSIFICATION_SINGLE_SUCCESS,
    HIGHER_CLASSIFICATION_SINGLE_FAIL,
    HIGHER_CLASSIFICATION_SINGLE_RESET,

    ALL_CATALOG_REQUEST,
    ALL_CATALOG_SUCCESS,
    ALL_CATALOG_FAIL,
    ALL_CATALOG_RESET,

    SINGLE_CATALOG_REQUEST,
    SINGLE_CATALOG_SUCCESS,
    SINGLE_CATALOG_FAIL,
    SINGLE_CATALOG_RESET,

    CATALOG_UPDATE_REQUEST,
    CATALOG_UPDATE_SUCCESS,
    CATALOG_UPDATE_FAIL,
    CATALOG_UPDATE_RESET,

    TREE_SEARCH_REQUEST,
    TREE_SEARCH_SUCCESS,
    TREE_SEARCH_FAIL,

    TREE_RESULT_REQUEST,
    TREE_RESULT_SUCCESS,
    TREE_RESULT_FAIL,

    ADVANCED_SEARCH_REQUEST,
    ADVANCED_SEARCH_SUCCESS,
    ADVANCED_SEARCH_FAIL,

} from '../constants/phylogenyConstants'


// -------------------------------------------- WORLD Catalogue

export const allCatalogReducer = (state = { catalog: {} }, action) => {
    switch (action.type) {
        case ALL_CATALOG_REQUEST:
            return { loading: true }

        case ALL_CATALOG_SUCCESS:
            return {
                loading: false,
                catalogs: action.payload.items,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case ALL_CATALOG_FAIL:
            return { loading: false, error: action.payload }
        
        case ALL_CATALOG_RESET:
            return {catalog: {}}

        default:
            return state
    }
}


export const catalogSingleReducer = (state = {}, action) => {
    switch (action.type) {
        case SINGLE_CATALOG_REQUEST:
            return { loading: true, success: false }

        case SINGLE_CATALOG_SUCCESS:
            return {
                loading: false,
                success: true,
                catalog: action.payload,
            }

        case SINGLE_CATALOG_FAIL:
            return { loading: false, error: action.payload }

        case SINGLE_CATALOG_RESET:
            return {}

        default:
            return state
    }
}


export const catalogUpdateReducer = (state = { catalog: {} }, action) => {
    switch (action.type) {
        case CATALOG_UPDATE_REQUEST:
            return { loading: true }

        case CATALOG_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case CATALOG_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CATALOG_UPDATE_RESET:
            return { catalog: {} }

        default:
            return state
    }
}


export const treeSearchReducer = (state = { tree: '' }, action) => {
    switch (action.type) {
        case TREE_SEARCH_REQUEST:
            return { loading: true }

        case TREE_SEARCH_SUCCESS:
            return {
                loading: false,
                tree: action.payload,
            }

        case TREE_SEARCH_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}



export const treeResultReducer = (state = { catalog: {} }, action) => {
    switch (action.type) {
        case TREE_RESULT_REQUEST:
            return { loading: true }

        case TREE_RESULT_SUCCESS:
            return {
                loading: false,
                catalogs: action.payload.items,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case TREE_RESULT_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}



export const advancedSearchReducer = (state = { catalog: {} }, action) => {
    switch (action.type) {
        case ADVANCED_SEARCH_REQUEST:
            return { loading: true }

        case ADVANCED_SEARCH_SUCCESS:
            return {
                loading: false,
                catalogs: action.payload.items,
                page: action.payload.page,
                pages: action.payload.pages,
                has_next: action.payload.has_next,
                has_prev: action.payload.has_prev,
                count: action.payload.count
            }

        case ADVANCED_SEARCH_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
