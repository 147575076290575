import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHInfoAction } from '../../actions/assetActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import ObitScreen from './ObitScreen'
import PubScreen from './PubScreen'
import TaxaScreen from './TaxaScreen'
import PageTitle from '../../components/PageTitle'
import { Row, Col } from 'react-bootstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function ClaudeScreen() {
    const CHInfo = useSelector(state => state.CHInfo)
    const { error, loading, m, img } = CHInfo
    const dispatch = useDispatch();

    useEffect(() => {
        if (!m) {
            dispatch(CHInfoAction());
        }
    }, [dispatch])
    return (
        <span>
            <PageTitle title={'Claude Herbulot a great lepidopterist'}></PageTitle>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>) :
                    <div className='mx-4'>
                        <Row>
                            <Col>
                                <img src={img} className="img-responsive w-75 mb-3" alt='Claude herbulot' /> <br />
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={4} className='text-end m-5'>
                                <Row className='mb-5'>

                                </Row>
                                <Row>
                                    <AnchorLink href='#obit'>
                                        <p className="text-end btn btn-primary">Obituaries</p>
                                    </AnchorLink>
                                    <AnchorLink href='#pub'>
                                        <p className="text-end btn btn-primary">List of Publications</p>
                                    </AnchorLink>
                                    <AnchorLink href='#taxa'>
                                        <p className="text-end btn btn-primary">Taxa described by Claude Herbulot</p>
                                    </AnchorLink>
                                    <AnchorLink href='#taxa'>
                                        <p className="text-end btn btn-primary">Taxa named after Claude Herbulot (Patronyms)</p>
                                    </AnchorLink>

                                </Row>
                            </Col>
                        </Row>
                        {m ? <div dangerouslySetInnerHTML={{ __html: m }} className='text-justify'></div> : null}
                    </div>
            }
            <section id='obit'>
                <ObitScreen />
            </section>
            <section id='taxa'>
                <TaxaScreen />
            </section>
            <section id='pub'>
                <PubScreen />
            </section>
        </span>
    )
}
export default ClaudeScreen