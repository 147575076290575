import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./SearchBar.css";

function SearchBox(props) {
    const navigation = useNavigate();

    const searchHandler = () => {
        if (wordEntered) {
            clearInput()
            navigation(`?q=${wordEntered}`);
        }
    }
    //----------------------------------------
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = props.data.filter((value) => {
            if (value.body) {
                return value.body.toLowerCase().includes(searchWord.toLowerCase());
            } else if (value.description) {
                return value.description.toLowerCase().includes(searchWord.toLowerCase());
            } else if (value.reference) {
                return value.reference.toLowerCase().includes(searchWord.toLowerCase());
            } else {
                return " ";
            }
        });
        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };
    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };
    //---------------------------------------------
    return (<React.Fragment>
        <Row className="mb-3">
            <Col sm={12} md={6} lg={6} xl={6} xxl={4}>
                <div className="search">
                    <div className="searchInputs">
                        <input
                            type='text'
                            name='q'
                            onChange={handleFilter}
                            className='form-control'
                            placeholder={props.placeholder}
                            value={wordEntered ?? ""}
                            autoComplete="off"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    searchHandler()
                                }

                            }}
                        />
                        <Button variant='light' size="sm" onClick={() => searchHandler()}>
                            Search <i className='fas fa-search'></i>
                        </Button>
                    </div>

                    {filteredData.length !== 0 && (props.next === 'newsletters') && (
                        <div className="dataResult">
                            {filteredData.slice(0, 15).map((value, key) => {
                                return (
                                    <Link to={`/assets/newsletters/${value.id}/`} className="dataItem bg-light fadeInLeft animated">
                                        {value.title}
                                    </Link>
                                );
                            })}
                        </div>
                    )}

                    {filteredData.length !== 0 && (props.next === 'news') && (
                        <div className="dataResult">
                            {filteredData.slice(0, 15).map((value, key) => {
                                return (
                                    <Link to={`/assets/news/${value.id}/`} className="dataItem bg-light fadeInLeft animated">
                                        {value.header}
                                    </Link>
                                );
                            })}
                        </div>
                    )}

                    {filteredData.length !== 0 && (props.next === 'congress') && (
                        <div className="dataResult">
                            {filteredData.slice(0, 15).map((value, key) => {
                                return (
                                    <Link to={`/assets/congresses/${value.id}/`} className="dataItem bg-light fadeInLeft animated">
                                        {value.title}
                                    </Link>
                                );
                            })}
                        </div>
                    )}
                </div>
            </Col>
        </Row>
        {props.q && (
            <Row>
                <Col sm={12} md={6} lg={6} xl={6} xxl={4} >
                    <div className='mb-4'>
                        <i className='fas fa-search'></i> Found {props.count} items   |

                        {(props.next === 'news') && (
                            <Link to="/assets/news/" className='mb-4'>
                                <i className='fas fa-check'></i> Check all news
                            </Link>)}

                        {(props.next === 'newsletters') && (
                            <Link to="/assets/newsletters/" className='mb-4'>
                                <i className='fas fa-check'></i> Check all newsletters
                            </Link>)}

                        {(props.next === 'congress') && (
                            <Link to="/assets/congresses/" className='mb-4'>
                                <i className='fas fa-check'></i> Check all congresses
                            </Link>)}

                        {(props.next === 'articles') && (
                            <Link to="/publications/" className='mb-4'>
                                <i className='fas fa-check'></i> Check all publications
                            </Link>)}

                    </div>

                </Col>

            </Row>
        )}
    </React.Fragment>
    )
}

export default SearchBox
