import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import OutsideClickHandler from './OutsideClickHandler';
import { isMobile } from 'react-device-detect';

function LeftBar() {

  const location = useLocation();
  const { pathname } = location;
  const [active, setActive] = useState(0)
  const changeMenu = (num) => {
    setActive(num)
    document.body.classList.remove('offcanvas')
    document.getElementById('burg').classList.remove('active')
  }
  if (pathname === '/geometridae/Catalogue/' && active !== 1) {
    setActive(1)
  } else {
    if (pathname === '/geometridae/mundi/' && active !== 2) {
      setActive(2)
    } else {
      if (pathname === '/geometridae/classification/' && active !== 3) {
        setActive(3)
      } else {
        if (pathname === '/publications/' && active !== 4) {
          setActive(4)
        } else {
          if (pathname === '/ch/' && active !== 5) {
            setActive(5)
          } else {
            if (pathname === '/assets/news/' && active !== 7) {
              setActive(7)
            } else {
              if (pathname === '/assets/congresses/' && active !== 8) {
                setActive(8)
              } else {
                if (pathname === '/assets/newsletters/' && active !== 9) {
                  setActive(9)
                } else {
                  if (pathname === '/contactus/' && active !== 11) {
                    setActive(11)
                  } else {
                    if (pathname === '/' && active !== 0) {
                      setActive(0)
                    } else {
                      if (pathname === '/admin/' && active !== 99) {
                        setActive(99)
                      } else {
                        if (pathname === '/account/' && active !== 98) {
                          setActive(98)
                        } else {
                          if (pathname === '/disclaimer/' && active !== 12) {
                            setActive(12)
                          } else {
                            if (pathname === '/acknowledgement/' && active !== 13) {
                              setActive(13)
                            } else {
                              if (pathname == '/archive' && active !== 14){
                                setActive(14)
                              } else {
                                if (pathname == '/introduction' && active !== 15) {
                                  setActive(15)
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  const [burgerMenuSituation, setBurgerMenuSituation] = useState('')
  const handleBurgerMenu = (out) => {
    if (document.body.className === "offcanvas") {
      setBurgerMenuSituation('')
      document.body.classList.remove('offcanvas')
    } else {
      if (out !== 'out') {
        setBurgerMenuSituation('active')
        document.body.classList.add('offcanvas')
      }
    }
  }

  return (
    <div id="fhst-page">
      <OutsideClickHandler
        onOutsideClick={() => {
          handleBurgerMenu('out');
        }}
      >
        <a to="#" className={isMobile ? "fhst-nav-toggle" : null} onClick={() => handleBurgerMenu()}><i></i></a>
        <aside id={isMobile ? "fhst-aside-mobile" : "fhst-aside"} className="js-fullheight">
          <Link to="/"><img src="/images/logo/logo.png" className='w-100 m-0' alt='FH' /></Link>

          <nav id="fhst-main-menu" role="navigation">
            <ul>
              <li className={active === 0 ? "fhst-active" : null} onClick={() => changeMenu(0)}><Link to="/"><i className='fas fa-home'></i> Home</Link></li>
              <li className={active === 15 ? "fhst-active" : null} onClick={() => changeMenu(15)}><Link to="/introduction">Introduction</Link></li>
              <li className={active === 1 ? "fhst-active" : null} onClick={() => changeMenu(1)}><Link to="geometridae/Catalogue/">World Catalogue</Link></li>
              <li className={active === 3 ? "fhst-active" : null} onClick={() => changeMenu(3)}><Link to="geometridae/classification/">Higher classification</Link></li>
              <li className={active === 2 ? "fhst-active" : null} onClick={() => changeMenu(2)}><Link to="geometridae/mundi/">Geometridae Mundi</Link></li>
              {/* <li className={(active === 6 || active === 7 || active === 8) ? "fhst-active" : null} onClick={() => changeMenu(6)}><Link to="assets/">Libraries</Link></li> */}
              <li className={active === 7 ? "fhst-active" : null} onClick={() => changeMenu(7)}><Link to="assets/news/">News</Link></li>
              <li className={active === 8 ? "fhst-active" : null} onClick={() => changeMenu(8)}><Link to="assets/congresses/">Congresses</Link></li>
              <li className={active === 9 ? "fhst-active" : null} onClick={() => changeMenu(9)}><Link to="assets/newsletters/">Newsletters</Link></li>
              <li className={active === 4 ? "fhst-active" : null} onClick={() => changeMenu(4)}><Link to="publications/">Publications</Link></li>
              <li className={active === 5 ? "fhst-active" : null} onClick={() => changeMenu(5)}><Link to="ch/">Claude Herbulot</Link></li>
              <li className={active === 14 ? "fhst-active" : null} onClick={() => changeMenu(14)}><Link to="archive/">Archive</Link></li>
              <li className={active === 11 ? "fhst-active" : null} onClick={() => changeMenu(11)}><Link to="contactus/">Contact Us</Link></li>
              <li className={active === 12 ? "fhst-active" : null} onClick={() => changeMenu(12)}><Link to="disclaimer/">Disclaimer</Link></li>
              <li className={active === 13 ? "fhst-active" : null} onClick={() => changeMenu(13)}><Link to="acknowledgement/">Acknowledgement</Link></li>
            </ul>
          </nav>
        </aside>
      </OutsideClickHandler >

    </div >
  )
}

export default LeftBar