import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { homeAlbum, counters, settingsHomeAction, topNews } from '../actions/assetActions'
import { Table, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Scientific from '../components/Home/Scientific'
import MainSlider from '../components/Home/MainSlider'
import Loader from '../components/Loader'
import Message from '../components/Message'
import ImageGallery from '../components/ImageGallery'
import { isMobile} from 'react-device-detect';

function HomeScreen() {

    const counterList = useSelector(state => state.counters)
    const { counts } = counterList

    const [q, setQ] = useState('')

    const navigation = useNavigate();

    const dispatch = useDispatch()

    const topNewsList = useSelector(state => state.topNews)
    const { loading, error, news } = topNewsList

    const albumHome = useSelector(state => state.homeAlbum)
    const { loadingAlbum, errorAlbum, albums } = albumHome

    const settingsHome = useSelector(state => state.settingsHome)
    const { h, se, se_titr } = settingsHome

    const submitAdvancedHandler = (e) => {
        e.preventDefault()
        navigation(`/geometridae/Catalogue/?q=${q}`);
    }

    useEffect(() => {
        if (!counts || !counts.members) {
            dispatch(counters())
        }
        if (!news || !news.length) {
            dispatch(topNews());
        }
        if (!albums || !albums.length) {
            dispatch(homeAlbum());
        }
        if (!h) {
            dispatch(settingsHomeAction());
        }
    }, [dispatch])
    return (
        <React.Fragment>
            <MainSlider />
            <Row>
                <Col md={6}>
                    <h1 className='fhst-heading mt-3'>Forum Herbulot</h1>
                    {h && <div dangerouslySetInnerHTML={{ __html: h }} className='text-justify text-sm'></div>}
                    <div className='my-5'>
                    {(albums && albums.length) ? <ImageGallery items={albums}/> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="card">
                        <div className="card-header">
                            {se_titr &&
                                <h4 className='fhst-heading mt-4 text-center'>{se_titr}</h4>}
                        </div>
                        <div className='px-4 mb-3 text-justify'>
                            {se && <div dangerouslySetInnerHTML={{ __html: se }} className='text-justify text-sm'></div>}
                        </div>
                        <div className='px-4'>
                            <Form onSubmit={submitAdvancedHandler}>
                                <Row>
                                    <Col md={9} className='m-0 p-0'>
                                        <Form.Group controlId='q' className='mb-2'>
                                            <Form.Control
                                                type='text'
                                                placeholder='Type Keyword'
                                                value={q ?? ""}
                                                onChange={(e) => setQ(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className='m-0 p-0'>
                                        <Button type='submit' variant='light' className='btn-block' disabled={!q}>
                                            Search <i className='fas fa-search'></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <span className='fhst-heading'>Latest News</span>
                        </div>
                        { error
                                ? (<Message variant='danger'>{error}</Message>) :
                                (
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <Table className="table table-hover table-lg">
                                                <tbody>
                                                    {news && news.length ? news.map(topn => (
                                                        <tr key={topn.id}>
                                                            <td className="w-auto align-top">
                                                                <div className="boxer boxer-lg">
                                                                    <img src={topn.img} alt='forum herbulot' />
                                                                </div>
                                                            </td>
                                                            <td className="w-100 align-top">
                                                                <Link to={`assets/news/${topn.id}`}>
                                                                    <span className={isMobile ? 'text-mobile' : 'text-sm'}>{topn.header}</span>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                                    <tr>
                                                        <td colSpan="2" className={isMobile ? "float-end text-mobile-sm mt-3" : "float-end text-sm mt-3" }>
                                                            <Link to='assets/news/'> Read more...</Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                )}
                    </div>                    
                </Col>
            </Row>
            <Scientific />
        </React.Fragment>
    )
}

export default HomeScreen