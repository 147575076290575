import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
     userDeleteReducer, userLoginReducer, userRegisterReducer, scientificBoardReducer, userListReducer,
     userDetailsReducer, userUpdateProfileReducer, adminUpdateReducer, userSubscribeReducer, subscribeListReducer,
     messageCreateReducer, listMessageReducer
} from './reducers/userReducers'
import {
     settingsReducer, settingsUpdateReducer,settingsHomeReducer, settingsHomeUpdateReducer,settingsClassificationReducer, settingsClassificationUpdateReducer,
     settingsContactReducer,settingsContactUpdateReducer, settingsMundiReducer, settingsMundiUpdateReducer,
     CHInfoReducer, CHInfoUpdateReducer, settingsPublicationsReducer, settingsPublicationsUpdateReducer,
     countersReducer, newsCreateReducer, mainAlbumReducer, settingsArchiveReducer, settingsArchiveUpdateReducer,
     allNewsReducer, listNewsReducer, homeAlbumReducer, newsDetailsReducer, newsDeleteReducer, newsUpdateReducer,newsReducer,
     allCongressReducer, listCongressReducer, congressDetailsReducer, congressCreateReducer, congressUpdateReducer, congressDeleteReducer,
     participantCreateReducer, proceedingCreateReducer, programCreateReducer, participantDeleteReducer, proceedingDeleteReducer, programDeleteReducer,
     listArticlerReducer, articleCreateReducer, articleUpdateReducer, settingsIntroReducer, settingsIntroUpdateReducer,
     listNewsLetterReducer, newsletterCreateReducer, newsletterUpdateReducer, newsletterDeleteReducer, newsletterDetailsReducer,
     ackReducer, ackUpdateReducer, disclaimerReducer, disclaimerUpdateReducer, emailNewsletterReducer,
     archiveDetailsReducer, settingsCatNotesReducer, settingsCatNotesUpdateReducer,
} from './reducers/assetsReducers'
import {
     allCatalogReducer, treeSearchReducer, treeResultReducer, advancedSearchReducer,
     catalogSingleReducer, catalogUpdateReducer
} from './reducers/phylogenyReducers'

import {
     listObituariesReducer, obitCreateReducer, obitDetailsReducer, obitUpdateReducer,
     listHerbulotArticlerReducer, herbulotArticleCreateReducer, HerbulotArticleUpdateReducer,
     listTAXAReducer, TAXACreateReducer,
} from './reducers/herbulotReducers'

const reducer = combineReducers({
     userLogin: userLoginReducer,
     userRegister: userRegisterReducer,
     userDelete: userDeleteReducer,
     userList: userListReducer,

     listMessage: listMessageReducer,
     messageCreate: messageCreateReducer,

     scientificBoard: scientificBoardReducer,
     userDetails: userDetailsReducer,
     userUpdateProfile: userUpdateProfileReducer,
     adminUpdate: adminUpdateReducer,
     userSubscribe: userSubscribeReducer,
     subsList: subscribeListReducer,
     topNews: newsReducer,
     counters: countersReducer,
     listNews: listNewsReducer,
     allNews: allNewsReducer,
     detailNews: newsDetailsReducer,
     newsCreate: newsCreateReducer,
     newsUpdate: newsUpdateReducer,
     newsDelete: newsDeleteReducer,
     homeAlbum: homeAlbumReducer,
     mainAlbum: mainAlbumReducer,

     listNewsletter: listNewsLetterReducer,
     detailNewsletter: newsletterDetailsReducer,
     newsletterCreate: newsletterCreateReducer,
     newsletterUpdate: newsletterUpdateReducer,
     newsletterDelete: newsletterDeleteReducer,
     emailNewsletter: emailNewsletterReducer,
     settingsSite: settingsReducer,
     settingsUpdate: settingsUpdateReducer,

     settingsHome: settingsHomeReducer,
     settingsHomeUpdate: settingsHomeUpdateReducer,

     settingsContact: settingsContactReducer,
     settingsContactUpdate: settingsContactUpdateReducer,

     settingsMundi: settingsMundiReducer,
     settingsMundiUpdate: settingsMundiUpdateReducer,

     settingsIntro: settingsIntroReducer,
     settingsIntroUpdate: settingsIntroUpdateReducer,

     settingsArchive: settingsArchiveReducer,
     settingsArchiveUpdate: settingsArchiveUpdateReducer,
     detailArchive: archiveDetailsReducer,

     CHInfo: CHInfoReducer,
     CHInfoUpdate: CHInfoUpdateReducer,

     settingsClassification: settingsClassificationReducer,
     settingsClassificationUpdate: settingsClassificationUpdateReducer,

     settingsPublication: settingsPublicationsReducer,
     settingsPublicationUpdate: settingsPublicationsUpdateReducer,

     settingsCatNote: settingsCatNotesReducer,
     settingsCatNoteUpdate: settingsCatNotesUpdateReducer,

     ackList: ackReducer,
     ackUpdate: ackUpdateReducer,

     disclaimerList: disclaimerReducer,
     disclaimerUpdate: disclaimerUpdateReducer,

     listCongress: listCongressReducer,
     allCongress: allCongressReducer,
     detailCongress: congressDetailsReducer,
     congressCreate: congressCreateReducer,
     congressUpdate: congressUpdateReducer,
     congressDelete: congressDeleteReducer,

     participantCreate: participantCreateReducer,
     participantDelete: participantDeleteReducer,
     proceedingCreate: proceedingCreateReducer,
     proceedingDelete: proceedingDeleteReducer,
     programCreate: programCreateReducer,
     programDelete: programDeleteReducer,

     listArticles: listArticlerReducer,
     articleCreate: articleCreateReducer,
     articleUpdate: articleUpdateReducer,

     allCatalog: allCatalogReducer,
     singleCatalog: catalogSingleReducer,
     catalogUpdate: catalogUpdateReducer,
     treeSearch: treeSearchReducer,
     treeResult: treeResultReducer,
     advancedSearch: advancedSearchReducer,

     obitList: listObituariesReducer,
     obitUpdate: obitUpdateReducer,
     obitCreate: obitCreateReducer,
     obitDetails: obitDetailsReducer,

     listHerbulotArticles: listHerbulotArticlerReducer,
     HerbulotArticleCreate: herbulotArticleCreateReducer,
     HerbulotArticleUpdate: HerbulotArticleUpdateReducer,

     listTAXA: listTAXAReducer,
     TAXACreate: TAXACreateReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
     JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
     homeAlbum: { albums: [] },
     userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
     composeWithDevTools(applyMiddleware(...middleware)))
// const store = createStore(reducer, initialState)

export default store