import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { lisHerbulotArticleAction, updateHerbulotArticleAction, createHerbulotArticleAction } from '../../../actions/herbulotActions'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import PageTitle from '../../../components/PageTitle'
import { Accordion, Button, Row, Col, Form } from 'react-bootstrap';
import { isMobile } from 'react-device-detect'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminHerbulotArticleScreen() {
    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const articleList = useSelector(state => state.listHerbulotArticles)
    const { loading, error, articles } = articleList
    const navigation = useNavigate();
    const [deleted, setDeleted] = useState(false)
    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    let p = searchParams.get('page')
    if (!q) {
        q = ''
    }

    const [editID, setEditID] = useState('')
    const [reference, setReference] = useState('')
    const [year, setYear] = useState('')
    const [uploading, setUploading] = useState(false)
    const [addYear, setAddYear] = useState('')
    const [addRef, setAddRef] = useState('')

    async function deleteHandler(id) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.delete(
                `/api/delete/ClaudeArticles/${id}/`,
                config
            )
            setDeleted(true)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateHerbulotArticleAction({
            id: editID,
            reference,
            year: year
        }))
        setDeleted(true)
        setEditID('')
        setReference('')
        setYear('')
    }


    const addBundleHandler = (e) => {
        let r = addRef.replaceAll('<p className="ql-align-justify">', '<p>');
        dispatch(createHerbulotArticleAction({
            reference: r,
            year: addYear,
        }))
        setDeleted(true)
        setAddRef('')
        setAddYear('')
    }

    const uploadDocHandler = async (e) => {

        const file = e.target.files[0]

        const formData = new FormData()
        formData.append('doc', file)
        formData.append('id', editID)
        formData.append('tbl', 'ClaudeArticles')
        formData.append('prev', false)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

           await axios.post('/api/upload/doc/', formData, config)

            setUploading(false)
            setDeleted(true)
            setReference('')
            setYear('')
            setEditID('')

        } catch (error) {
            setUploading(false)
        }
    }

    const drawer = (id, ref, y) => {
        setReference(ref)
        setYear(y)
        setEditID(id)
    }

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        setDeleted(false)
        dispatch(lisHerbulotArticleAction(q, p))
    }, [dispatch, q, p, deleted])

    const [activePanel, SetActivePanel] = useState(0)

    async function deleteDocHandler(id) {
        if (window.confirm('Are you sure you want to delete this file?')) {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.get(
                `/api/doc/clear/ClaudeArticles/${id}/`,
                config
            )

            setDeleted(true)
        }
    }


    const panelHandler = id => {
        SetActivePanel(id)
    }
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],

            [{ 'script': 'sub' }, { 'script': 'super' }],

            [{ 'size': ['small', false, 'large', 'huge'] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
        ]
    }
    return (
        <div>
            <PageTitle title='Claude Herbulot publications' />

            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <span>
                            <h1> Add articles</h1>
                            <Row className='mb-4'>
                                <Col md={10}>
                                    <ReactQuill theme="snow" value={addRef ?? ""} onChange={setAddRef} modules={quillModules}  preserveWhitespace="true" />
                                </Col>
                                <Col md={2}>
                                    <Form.Control
                                        className='mb-4'
                                        required
                                        placeholder='Year'
                                        type='number'
                                        value={addYear ?? ""}
                                        onChange={(e) => setAddYear(e.target.value)}
                                    >
                                    </Form.Control>
                                    <Button disabled={!addRef.length && !addYear.length}
                                        onClick={addBundleHandler}
                                    ><i className='fas fa-plus'></i> Upload data</Button>
                                </Col>
                            </Row>
                            <Accordion defaultActiveKey={String(activePanel)}>
                                {articles && articles.map((item, index) => (
                                    <Accordion.Item eventKey={String(index)} key={index}>
                                        <Accordion.Header className={activePanel === index ? 'active' : ''} onClick={() => panelHandler(index)}>
                                            {item.year}
                                        </Accordion.Header>
                                        <Accordion.Body className="test">
                                            {item.pubs.map(publish => (
                                                <div key={publish.id} className='text-sm mb-1 '>
                                                    {editID === publish.id ?
                                                        <div>
                                                            <Row>
                                                                <Col sm={12} md={12} lg={10}>
                                                                    <Form.Control
                                                                        required
                                                                        placeholder='Enter reference'
                                                                        value={reference ?? ""}
                                                                        onChange={(e) => setReference(e.target.value)}
                                                                    >
                                                                    </Form.Control>
                                                                </Col>
                                                                <Col sm={12} md={6} lg={2}>
                                                                    <Form.Control
                                                                        required
                                                                        placeholder='Enter year'
                                                                        value={year ?? ""}
                                                                        onChange={(e) => setYear(e.target.value)}
                                                                    >
                                                                    </Form.Control>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <br></br>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12} md={6} lg={6}>
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            id='doc'
                                                                            type='file'
                                                                            className={isMobile ? 'text-mobile' : 'text-sm'}
                                                                            placeholder='Document'
                                                                            onChange={uploadDocHandler}
                                                                        >
                                                                        </Form.Control>
                                                                        {uploading && <Loader />}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12} md={6} lg={4}>
                                                                    <Button variant='success' onClick={submitHandler}><i className='fas fa-save'></i> Save</Button>
                                                                    <Button variant='warning' onClick={() => setEditID('')}><i className='fas fa-times'></i> Cancle</Button>
                                                                </Col>
                                                            </Row>
                                                            <hr />

                                                        </div>
                                                        :
                                                        <div>
                                                            <i className='fas fa-edit text-me text-color cursor-pointer'
                                                                onClick={() => drawer(publish.id, publish.reference, publish.year)}
                                                            ></i>
                                                            <span variant='danger'>
                                                                <i className='fas fa-trash text-me text-color-2 cursor-pointer m-2'
                                                                    onClick={() => deleteHandler(publish.id)}>
                                                                </i>
                                                            </span>
                                                            {publish.doc &&
                                                                <span className='dang text-me text-color-2 cursor-pointer m-2' onClick={() => deleteDocHandler(publish.id)}>
                                                                    <i className='fas fa-file-pdf'></i>
                                                                    <i className='fas fa-times'></i>
                                                                </span>
                                                            }
                                                            <div dangerouslySetInnerHTML={{ __html: publish.reference }}></div>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </span>
                    )
            }
        </div >)
}

export default AdminHerbulotArticleScreen