import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle' 
import { useAlert } from 'react-alert'

import { useDispatch, useSelector } from 'react-redux'
import { listNews, deleteNews } from '../../../actions/assetActions'
import { useSearchParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import Paginate from '../../../components/Paginate'
import { Table, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';

function AdminNewsScreen() {
    const dispatch = useDispatch()
    alert = useAlert()

    const newsList = useSelector(state => state.listNews)
    const { loading, error, news, pages, page, has_next, has_prev } = newsList

    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    let p = searchParams.get('page')
    if (!q) {
        q = ''
    }

    const navigation = useNavigate();


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    const newsDelete = useSelector(state => state.newsDelete)
    const { success: successDelete } = newsDelete

    const deleteHandler = (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            dispatch(deleteNews('News', id))
            alert.error('News deleted successfully')
        }
    }

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        dispatch(listNews(q, p))
    }, [dispatch, q, p, successDelete])

    return (
        <div>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <>
                        <PageTitle title='Manage Site news'></PageTitle>
                            <LinkContainer to={`/admin/news/add`}>
                                <Button variant='success' className='btn-sm'>
                                    <i className='fas fa-plus'></i> Add News
                                </Button>
                            </LinkContainer>
                            <Table striped bordered hover responsive className='table-sm mt-3'>
                                <thead>
                                    <tr>
                                        <th>List of News</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {news.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.header}</td>
                                            <td>
                                                <LinkContainer to={`edit/${item.id}`}>
                                                    <Button variant='warning' className='btn-sm'>
                                                        <i className='fas fa-edit'></i>
                                                    </Button>
                                                </LinkContainer>

                                                <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(item.id)}>
                                                    <i className='fas fa-trash'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
            <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />

        </div>
    )
}

export default AdminNewsScreen