import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { settingsIntroAction } from '../../actions/assetActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PageTitle from '../../components/PageTitle'

function Introduction() {
    const settingsIntro = useSelector(state => state.settingsIntro)
    const { loading, error, m } = settingsIntro
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (!m) {
            dispatch(settingsIntroAction());
        }
    }, [dispatch])

    return (
        <div className='mx-2'>
            <PageTitle title={'Introduction'}></PageTitle>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>) :
                    (m && <div dangerouslySetInnerHTML={{ __html: m }} className='text-justify'></div>
                    )}
        </div>
    )
}

export default Introduction