import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsPublicationsAction, updateSettingsPublicationsAction } from '../../../actions/assetActions'
import { SETTINGS_PUBLICATIONS_UPDATE_RESET } from '../../../constants/assetConstants'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminPubNotes() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
  
    const navigation = useNavigate();
  
    const [publication, setPublication] = useState('')
  
    const dispatch = useDispatch()
  
    const settingsPublication = useSelector(state => state.settingsPublication)
    const { error, loading, c } = settingsPublication
  
    const settingsPublicationUpdate = useSelector(state => state.settingsPublicationUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsPublicationUpdate
  
    useEffect(() => {
  
      if (!userInfo || !userInfo.isAdmin) {
        navigation('/login?redirect=admin')
      }
  
      if (successUpdate) {
        dispatch({ type: SETTINGS_PUBLICATIONS_UPDATE_RESET })
        dispatch(settingsPublicationsAction())
      } else {
        if (!c) {
          dispatch(settingsPublicationsAction())
        } else {
          setPublication(c)
        }
      }
  
    }, [dispatch, c, successUpdate])
  
    const submitHandler = (e) => {
      e.preventDefault()
      dispatch(updateSettingsPublicationsAction({
        publication
      }))
    }
  
    const quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
  
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
                            
  
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link'],
  
        ['clean']
      ]
    }
  
    return (
  
      <div>
        {loading || loadingUpdate
          ? (<Loader />)
          : error || errorUpdate ?
            (<Message variant='danger'>{error}</Message>)
            :
            (
              <Row>
                <Col md={12}>
                  <h1 className='mb-5'>Publication notes</h1>
                  {c &&
                    <Form onSubmit={submitHandler}>
  
                      <ReactQuill theme="snow" value={publication || ""} onChange={setPublication} modules={quillModules}  preserveWhitespace="true" />
                      <Button type='submit' variant='primary' className='mt-2'>
                      <i className='fas fa-edit'></i> Update
                      </Button>
  
                    </Form>
                  }
                </Col >
              </Row>
            )}
      </div>
    )
  }
  

export default AdminPubNotes