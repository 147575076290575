import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { archiveDetails, updateSettingsArchiveAction } from '../../../actions/assetActions'
import { SETTINGS_ARCHIVE_UPDATE_RESET } from '../../../constants/assetConstants'
import { FaArrowCircleLeft } from "react-icons/fa";
import { saveAs } from "file-saver";
import { SITE_URL } from '../../../constants/siteConstants';
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
import { settingsArchiveAction } from '../../../actions/assetActions'

function AdminArchiveEditScreen() {
    alert = useAlert()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const { id } = useParams();
    const navigation = useNavigate();
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [img, setImg] = useState('')
    const [doc, setDoc] = useState('')

    const [changedImage, setChangedImage] = useState(false)

    const [uploadingImage, setUploadingImage] = useState(false)
    const [uploadingDoc, setUploadingDoc] = useState(false)

    const dispatch = useDispatch()

    const archiveDet = useSelector(state => state.detailArchive)
    const { error, loading, archive } = archiveDet

    const archiveUpdate = useSelector(state => state.settingsArchiveUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = archiveUpdate

    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }

        if (successUpdate) {
            dispatch({ type: SETTINGS_ARCHIVE_UPDATE_RESET })
            navigation('/admin/settings/archive/')
        } else {
            if (!archive.title || archive.id != id || changedImage) {
                dispatch(archiveDetails(id))
                setChangedImage(false)
            } else {
                setTitle(archive.title)
                setDescription(archive.description)
                setImg(archive.img)
                setDoc(archive.doc)
            }
        }
    }, [dispatch, archive, id, successUpdate, changedImage])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateSettingsArchiveAction({
            id,
            title,
            description,
            img,
            doc,
        }))
        dispatch(settingsArchiveAction())
    }

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]

        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('id', id)
        formData.append('tbl', 'Archive')
        formData.append('prev', true)

        setUploadingImage(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/upload/image/', formData, config)


            setImg(data)
            setUploadingImage(false)
            setChangedImage(true)

        } catch (error) {
            setUploadingImage(false)
        }
    }

    const uploadDocHandler = async (e) => {

        const file = e.target.files[0]

        const formData = new FormData()
        formData.append('doc', file)
        formData.append('id', id)
        formData.append('tbl', 'Archive')
        formData.append('prev', true)

        setUploadingDoc(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/upload/doc/', formData, config)


            setImg(data)
            setUploadingDoc(false)
            setChangedImage(true)
            alert.show('Document Changed Successfully.')

        } catch (error) {
            setUploadingImage(false)
        }
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],


            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    }

    return (

        <div>
            <Link to='/admin/settings/archive/'>
                <FaArrowCircleLeft className='text-primary text-md mb-3' />
            </Link>


            {loading
                ? (<Loader />)
                : error ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (

                        <Row>
                            <Col sm={12} md={6} lg={8}>
                                <h1 className='mb-5'>Edit News</h1>
                                {loadingUpdate && <Loader />}
                                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                                <Form onSubmit={submitHandler}>

                                    <Form.Group as={Row} controlId='title' className='mb-2'>
                                        <Form.Label column sm="2">Title</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                required
                                                type='title'
                                                placeholder='Enter title'
                                                value={title ?? ""}
                                                onChange={(e) => setTitle(e.target.value)}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId='description' className='mb-2'>
                                        <Form.Label column sm="2">Description</Form.Label>
                                        <Col sm="10">
                                            <ReactQuill theme="snow" value={description ?? ""} onChange={setDescription} modules={quillModules}  preserveWhitespace="true" />
                                        </Col>
                                    </Form.Group>

                                    <Button type='submit' variant='primary'>
                                        <i className='fas fa-edit'></i> Update
                                    </Button>

                                </Form>
                            </Col >
                            <Col sm={12} md={6} lg={4}>
                                <h5>Change Main Image</h5>
                                <Image src={archive.img} className='w-100' />
                                <div>
                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='mainimg'
                                            type='file'
                                            placeholder='image'
                                            onChange={uploadFileHandler}
                                        >
                                        </Form.Control>
                                        {uploadingImage && <Loader />}
                                    </Form.Group>
                                </div>
                                <hr></hr>
                                <h5>Change Document</h5>
                                <p className='mt-1 mb-1 text-sm text-color-2 cursor-pointer' onClick={() => saveFile(archive.doc)}>
                                    <i className='fas fa-download text-color-2'></i> Download file
                                </p>
                                <div>
                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='doc'
                                            type='file'
                                            placeholder='file'
                                            onChange={uploadDocHandler}
                                        >
                                        </Form.Control>
                                        {uploadingDoc && <Loader />}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    )}
            <hr></hr>

        </div>


    )
}

export default AdminArchiveEditScreen