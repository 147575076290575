import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { userListAction } from '../../actions/userActions'
import { Row, Col, Image } from 'react-bootstrap'
import Member from '../Member'

function Scientific() {
    const dispatch = useDispatch()
    const [activeUser, SetActiveUser] = useState(0);
    const userList = useSelector(state => state.userList)
    const { loading, error, members } = userList
    useEffect(() => {
        if (!members || !members.length) {
            dispatch(userListAction())
        }
    }, [dispatch, members])

    return (
        <div>
            <h1 className="fhst-heading">Scientific board</h1>
            {loading
                ? <Loader />
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    :
                    <Row>
                        {
                            members && members.map(item => (
                                <Col key={item.id} sm={6} md={4} lg={6} xl={4}>
                                    <Member member={item} />
                                </Col>
                            ))}
                    </Row>
            }
        </div>
    )

}

export default Scientific