import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import ManageImage from '../../../components/ManageImage'
import { useAlert } from 'react-alert'
import { settingsHomeAction, updateSettingsHomeAction, homeAlbum, mainAlbumAction } from '../../../actions/assetActions'
import { SETTINGS_HOME_UPDATE_RESET } from '../../../constants/assetConstants'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
function AdminEditHome() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();
    const [homeintro, setHomeintro] = useState('')
    const [homepage_search_header, setHomePage_search_header] = useState('')
    const [homepage_search, setHomePage_search] = useState('')
    const [footerImg, setFooterImg] = useState('')
    const albumHome = useSelector(state => state.homeAlbum)
    const { albums } = albumHome
    const albumMain = useSelector(state => state.mainAlbum)
    const { albums: albumsMain } = albumMain
    const [caption, setCaption] = useState('')
    const [captionMain, setCaptionMain] = useState('')
    const [changed, setChanged] = useState(false)
    const [uploadingGallery, setUploadingGallery] = useState(false)
    const dispatch = useDispatch()
    const settingsHome = useSelector(state => state.settingsHome)
    const { error, loading, h, se, se_titr, footer, id } = settingsHome
    const settingsHomeUpdate = useSelector(state => state.settingsHomeUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsHomeUpdate
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        if (!albums || !albums.length || changed) {
            dispatch(homeAlbum());
        }
        if (!albumsMain.length || changed) {
            dispatch(mainAlbumAction());
        }
        if (successUpdate) {
            dispatch({ type: SETTINGS_HOME_UPDATE_RESET })
            dispatch(settingsHomeAction())
        } else {
            if (!h || changed) {
                dispatch(settingsHomeAction())
                setChanged(false)
            } else {
                setFooterImg(footer)
                setHomeintro(h)
                setHomePage_search(se)
                setHomePage_search_header(se_titr)
            }
        }
        setChanged(false)
    }, [dispatch, h, successUpdate, changed])
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateSettingsHomeAction({
            homeintro,
            homepage_search_header,
            homepage_search,
        }))
        alert.show('Settings Changed successfully')
    }
    const uploadMainGalleryHandler = async (e) => {
        const file = e.target.files[0]
        if (file.size > 153600) {
            alert.error('Image size should be less than 150KB')
            return
        }
        const formData = new FormData()
        formData.append('img', file)
        formData.append('title', captionMain)
        setUploadingGallery(true)
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.post(`/api/assets/settings/mainalbumupload/`, formData, config)
            alert.show('Image uploaded successfully')
            setUploadingGallery(false)
            setChanged(true)
        } catch (error) {
            setUploadingGallery(false)
        }
    }

    const uploadGalleryHandler = async (e) => {
        const file = e.target.files[0]
        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }
        const formData = new FormData()
        formData.append('img', file)
        formData.append('title', caption)
        setUploadingGallery(true)
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.post(`/api/assets/settings/images/`, formData, config)
            alert.show('Image uploaded successfully')
            setUploadingGallery(false)
            setChanged(true)
        } catch (error) {
            setUploadingGallery(false)
        }
    }
    const uploadFooterHandler = async (e) => {
        const file = e.target.files[0]
        if (file.size > 204800) {
            alert.error('Image size should be less than 200KB')
            return
        }
        const formData = new FormData()
        formData.append('image', file)
        formData.append('id', id)
        formData.append('prev', true)
        formData.append('tbl', 'SettingsHome')
        setUploadingGallery(true)
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.post(`/api/upload/image/`, formData, config)
            alert.show('Image uploaded successfully')
            setUploadingGallery(false)
            setChanged(true)
        } catch (error) {
            setUploadingGallery(false)
        }
    }
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    }
    const changerHandler = () => {
        setChanged(true)
    }
    return (
        <div>
            <h1>Edit main photo gallery</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {albumsMain && albumsMain.length > 0 &&
                        <div>
                            {albumsMain.map(item => (
                                <ManageImage item={item} tbl={'MainAlbum'} token={userInfo.token} changerHandler={changerHandler} key={item.id} />
                            ))}
                        </div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Add new image to photo gallery</h5>
                    <ReactQuill theme="snow" value={captionMain || ""} onChange={setCaptionMain} modules={quillModules}  preserveWhitespace="true" />
                    <Form.Control
                        id='gallerymain'
                        type='file'
                        placeholder='image'
                        onChange={uploadMainGalleryHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>
            <hr></hr>
            <h1>Edit story photo gallery</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {albums && albums.length > 0 &&
                        <div>
                            {albums.map(item => (
                                <ManageImage item={item} tbl={'AlbumHome'} token={userInfo.token} changerHandler={changerHandler} key={item.id} />
                            ))}
                        </div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Add new image to photo gallery</h5>

                    <Form.Control
                        className='mb-3'
                        type='text'
                        placeholder='Enter caption'
                        value={caption ?? ""}
                        onChange={(e) => setCaption(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        id='galleryimg'
                        type='file'
                        placeholder='image'
                        onChange={uploadGalleryHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>
            <h1>Edit Footer Photo</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {footerImg ? 
                        <img width='300px' className='image-responsive m-2' src={footerImg} />
                        : <div> No Footer Image</div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Footer Photo</h5>
                    <Form.Control
                        id='galleryimg'
                        type='file'
                        placeholder='image'
                        onChange={uploadFooterHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>            
            <hr></hr>
            {loading || loadingUpdate
                ? (<Loader />)
                : error || errorUpdate ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (
                        <Row>
                            <Col md={12}>
                                {(h) &&
                                    <Form onSubmit={submitHandler}>

                                        <Row>
                                            <Col sm={12} md={12} lg={6}>
                                                <h1 className='mb-5'>Our story</h1>
                                                <ReactQuill theme="snow" value={homeintro || ""} onChange={setHomeintro} modules={quillModules}  preserveWhitespace="true" />
                                            </Col>
                                            <Col sm={12} md={12} lg={6}>
                                                <h1 className='mb-5'>Homepage Search</h1>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Homepage search Header'
                                                    value={homepage_search_header ?? ""}
                                                    onChange={(e) => setHomePage_search_header(e.target.value)}
                                                >
                                                </Form.Control>
                                                <ReactQuill theme="snow" value={homepage_search || ""} onChange={setHomePage_search} modules={quillModules}  preserveWhitespace="true" />

                                            </Col>
                                        </Row>
                                        <Button type='submit' variant='primary' className='mt-1'>
                                        <i className='fas fa-edit'></i> Update
                                        </Button>

                                    </Form>
                                }
                            </Col >
                        </Row>
                    )}
        </div>
    )
}

export default AdminEditHome