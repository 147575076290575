import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { CHInfoAction, updateCHInfoAction } from '../../../actions/assetActions'
import { CH_INFO_UPDATE_RESET } from '../../../constants/assetConstants'
import { useAlert } from 'react-alert'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'
function AdminEditInfoCH() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    alert = useAlert()
    const navigation = useNavigate();
    const [chinfo, setCHInfo] = useState('')
    const [image, setImage] = useState('')
    const [chID, setCHID] = useState('')
    const dispatch = useDispatch()
    const [uploadingImage, setUploadingImage] = useState(false)
    const CHInfo = useSelector(state => state.CHInfo)
    const { error, loading, id, m, img } = CHInfo
    const [changedImage, setChangedImage] = useState(false)
    const CHInfoUpdate = useSelector(state => state.CHInfoUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = CHInfoUpdate
    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        if (successUpdate || changedImage) {
            setChangedImage(false)
            dispatch({ type: CH_INFO_UPDATE_RESET })
            dispatch(CHInfoAction())
        } else {
            if (!m) {
                setChangedImage(false)
                dispatch(CHInfoAction('a'))
            } else {
                setCHInfo(m)
                setImage(img)
                setCHID(id)
            }
        }
    }, [dispatch, m, successUpdate, changedImage])
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateCHInfoAction({
            chinfo
        }))
        alert.show('Item Changed successfully')
    }

    
    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]

        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('id', chID)
        formData.append('tbl', 'settingsCH')
        formData.append('prev', true)

        setUploadingImage(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/upload/image/', formData, config)


            setImage(data)
            setUploadingImage(false)
            setChangedImage(true)
            alert.show('Image changed successfully')

        } catch (error) {
            setUploadingImage(false)
        }
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link'],
            ['clean']
        ]
    }
    return (
        <div>
            {loading || loadingUpdate
                ? (<Loader />)
                : error || errorUpdate ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (
                        <Row>
                            <Col Col sm={12} md={6} lg={8}>
                                <h1 className='mb-5'>Site settings</h1>
                                {m &&
                                    <Form onSubmit={submitHandler}>
                                        <Form.Group as={Row} controlId='chinfo' className='mb-2'>
                                            <Form.Label column sm="2">CH Info</Form.Label>
                                            <Col sm="10">
                                                <ReactQuill theme="snow" value={chinfo || ""} onChange={setCHInfo} modules={quillModules}  preserveWhitespace="true" />
                                            </Col>
                                        </Form.Group>
                                        <Button type='submit' variant='primary'>
                                            <i className='fas fa-edit'></i> Update
                                        </Button>

                                    </Form>
                                }
                            </Col >
                            <Col sm={12} md={6} lg={4}>
                                <h5>Change Main Image</h5>
                                <Image src={image} className='w-100' />
                                <div>

                                    <Form.Group as={Row}>
                                        <Form.Control
                                            id='mainimg'
                                            type='file'
                                            placeholder='image'
                                            onChange={uploadFileHandler}
                                        >
                                        </Form.Control>
                                        {uploadingImage && <Loader />}
                                    </Form.Group>

                                </div>

                            </Col>
                        </Row>
                    )}
        </div>
    )
}

export default AdminEditInfoCH