import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { settingsAction, updateSettingsAction, homeAlbum, mainAlbumAction } from '../../../actions/assetActions'
import { SETTINGS_UPDATE_RESET } from '../../../constants/assetConstants'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminSettingsScreen() {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const navigation = useNavigate();
    const [homeintro, setHomeintro] = useState('')

    const [homepage_search_header, setHomePage_search_header] = useState('')
    const [homepage_search, setHomePage_search] = useState('')

    const [contact_us_email, setContact_us_email] = useState('')
    const [contact_us_address, setContact_us_address] = useState('')
    const [contact_us_tel, setContact_us_tel] = useState('')

    const [classification, setClassification] = useState('')
    const [mundi, setMundi] = useState('')

    const albumHome = useSelector(state => state.homeAlbum)
    const { albums } = albumHome

    const albumMain = useSelector(state => state.mainAlbum)
    const { albums: albumsMain } = albumMain

    const [caption, setCaption] = useState('')
    const [captionMain, setCaptionMain] = useState('')
    const [titrMain, setTitrMain] = useState('')

    const [changed, setChanged] = useState(false)

    const [uploadingGallery, setUploadingGallery] = useState(false)

    const dispatch = useDispatch()

    const settingsSite = useSelector(state => state.settingsSite)
    const { error, loading, h, c, m , se, se_titr, us_email, us_phone, us_address } = settingsSite

    const settingsUpdate = useSelector(state => state.settingsUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = settingsUpdate

    useEffect(() => {

        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        if (!albums || !albums.length || changed) {
            dispatch(homeAlbum());
        }
        if (!albumsMain || !albumsMain.length || changed) {
            dispatch(mainAlbumAction());
        }
        if (successUpdate) {
            dispatch({ type: SETTINGS_UPDATE_RESET })
            dispatch(settingsAction('a'))
        } else {
            if (!h  || !c || !m  || changed) {
                dispatch(settingsAction('a'))
                setChanged(false)
            } else {
                setHomeintro(h)
                setClassification(c)
                setMundi(m)
                setHomePage_search(se)
                setHomePage_search_header(se_titr)
                setContact_us_email(us_email)
                setContact_us_address(us_address)
                setContact_us_tel(us_phone)
            }
        }
        setChanged(false)

    }, [dispatch, h, c, m, successUpdate, changed])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateSettingsAction({
            homeintro,
            homepage_search_header,
            homepage_search,
            contact_us_email,
            contact_us_address,
            contact_us_tel,
            classification,
            mundi
        }))
    }


    const uploadMainGalleryHandler = async (e) => {

        const file = e.target.files[0]
        if (file.size > 153600) {
            alert('Image size should be less than 150KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('title', captionMain)
        formData.append('titr', titrMain)

        setUploadingGallery(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            await axios.post(`/api/assets/settings/mainalbumupload/`, formData, config)


            setUploadingGallery(false)
            setChanged(true)

        } catch (error) {
            setUploadingGallery(false)
        }
    }

    const uploadGalleryHandler = async (e) => {

        const file = e.target.files[0]
        if (file.size > 102400) {
            alert.error('Image size should be less than 100KB')
            return
        }

        const formData = new FormData()
        formData.append('img', file)
        formData.append('title', caption)

        setUploadingGallery(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            await axios.post(`/api/assets/settings/images/`, formData, config)


            setUploadingGallery(false)
            setChanged(true)

        } catch (error) {
            setUploadingGallery(false)
        }
    }

    async function deleteAlbumHandler(tbl, id) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            const { data } = await axios.delete(
                `/api/delete/${tbl}/${id}/`,
                config
            )

            if (data.success) {
                setChanged(true)
            }
        }
    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
                                

            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],

            ['clean']
        ]
    }

    return (

        <div>
            <h1>Edit main photo gallery</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {albumsMain && albumsMain.length > 0 &&
                        <div>
                            {albumsMain.map(item => (
                                <span className="project w-40 m-4 d-inline-block" Style={`background-image: url(${item.image});width:250px;height:150px;`}>
                                    <div className="desc">
                                        <div className="con">
                                            {item.caption &&
                                                <h3 className="badge mb-5">
                                                    {item.caption}
                                                </h3>
                                            }
                                            <h4>
                                                <span onClick={() => deleteAlbumHandler('MainAlbum', item.id)} className="tag tag--pill">
                                                    Delete Image
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </span>
                            ))}
                        </div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Add new image to photo gallery</h5>

                    <Form.Control
                        className='mb-3'
                        type='titr'
                        placeholder='Enter titr'
                        value={titrMain ?? ""}
                        onChange={(e) => setTitrMain(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        as='textarea'
                        className='mb-3'
                        type='text'
                        rows='3'
                        placeholder='Enter caption'
                        value={captionMain ?? ""}
                        onChange={(e) => setCaptionMain(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        id='gallerymain'
                        type='file'
                        placeholder='image'
                        onChange={uploadMainGalleryHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>
            <hr></hr>
            <h1>Edit story photo gallery</h1>
            <Row className='mt-3'>
                <Col sm={12} md={6} lg={8}>
                    {albums && albums.length > 0 &&
                        <div>
                            {albums.map(item => (
                                <span className="project w-40 m-4 d-inline-block" Style={`background-image: url(${item.image});width:150px;height:150px;`}>
                                    <div className="desc">
                                        <div className="con">
                                            {item.caption &&
                                                <h3 className="badge mb-5">
                                                    {item.caption}
                                                </h3>
                                            }
                                            <h4>
                                                <span onClick={() => deleteAlbumHandler('AlbumHome', item.id)} className="tag tag--pill">
                                                    Delete Image
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </span>
                            ))}
                        </div>
                    }
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <h5>Add new image to photo gallery</h5>

                    <Form.Control
                        className='mb-3'
                        type='text'
                        placeholder='Enter caption'
                        value={caption ?? ""}
                        onChange={(e) => setCaption(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        id='galleryimg'
                        type='file'
                        placeholder='image'
                        onChange={uploadGalleryHandler}
                    >
                    </Form.Control>
                    {uploadingGallery && <Loader />}
                </Col>
            </Row>
            <hr></hr>
            {loading || loadingUpdate
                ? (<Loader />)
                : error || errorUpdate ?
                    (<Message variant='danger'>{error}</Message>)
                    :
                    (
                        <Row>
                            <Col md={12}>
                                <h1 className='mb-5'>Site settings</h1>
                                {(h || c || m) &&
                                    <Form onSubmit={submitHandler}>

                                        <Row>
                                            <Col sm={12} md={12} lg={8}>
                                                <ReactQuill theme="snow" value={homeintro || ""} onChange={setHomeintro} modules={quillModules}  preserveWhitespace="true" />
                                            </Col>
                                            <Col sm={12} md={12} lg={4}>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Homepage search Header'
                                                    value={homepage_search_header ?? ""}
                                                    onChange={(e) => setHomePage_search_header(e.target.value)}
                                                >
                                                </Form.Control>
                                                <Form.Control as='textarea'
                                                    className='h-75'
                                                    required
                                                    rows='5'
                                                    placeholder='Homepage search text'
                                                    value={homepage_search ?? ""}
                                                    onChange={(e) => setHomePage_search(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Row>

                                        <hr></hr>
                                        <h1>Edit Contact Us page fields</h1>
                                        <Row>
                                            <Col>
                                            <Form.Control
                                                    type='text'
                                                    placeholder='Contact Us Email'
                                                    value={contact_us_email ?? ""}
                                                    onChange={(e) => setContact_us_email(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                            <Form.Control
                                                    type='text'
                                                    placeholder='Contact Us Address'
                                                    value={contact_us_address ?? ""}
                                                    onChange={(e) => setContact_us_address(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                            <Form.Control
                                                    type='text'
                                                    placeholder='Contact Us Phone Number'
                                                    value={contact_us_tel ?? ""}
                                                    onChange={(e) => setContact_us_tel(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Form.Group as={Row} controlId='classification' className='mb-2'>
                                            <Form.Label column sm="2">Classification</Form.Label>
                                            <Col sm="10">
                                                <ReactQuill theme="snow" value={classification || ""} onChange={setClassification} modules={quillModules}  preserveWhitespace="true" />
                                            </Col>
                                        </Form.Group>
                                        <hr></hr>
                                        <Form.Group as={Row} controlId='mundi' className='mb-2'>
                                            <Form.Label column sm="2">Project Mundi</Form.Label>
                                            <Col sm="10">
                                                <ReactQuill theme="snow" value={mundi || ""} onChange={setMundi} modules={quillModules}  preserveWhitespace="true" />
                                            </Col>
                                        </Form.Group>
                                        <Button type='submit' variant='primary'>
                                        <i className='fas fa-edit'></i> Update
                                        </Button>

                                    </Form>
                                }
                            </Col >
                        </Row>
                    )}
        </div>
    )
}

export default AdminSettingsScreen