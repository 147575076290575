import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lisArticleAction, settingsPublicationsAction } from '../../actions/assetActions'
import { useSearchParams } from 'react-router-dom'
import SearchBox from '../../components/SearchBox'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import PageTitle from '../../components/PageTitle'
import { Accordion, Container } from 'react-bootstrap';
import { saveAs } from "file-saver";
import { SITE_URL } from '../../constants/siteConstants';
import { isMobile } from 'react-device-detect'

function ArticleScreen() {
    const dispatch = useDispatch()

    const articleList = useSelector(state => state.listArticles)
    const { loading, error, count, pages, page, has_next, has_prev, articles } = articleList

    const settingsPublication = useSelector(state => state.settingsPublication)
    const { error: errorSettings, loading: loadingSettings, c } = settingsPublication

    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    let p = searchParams.get('page')
    if (!q) {
        q = ''
    }
    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    const [qer, setQer] = useState('')
    useEffect(() => {
        if (!articles || !articles.length || (q !== qer) || (qer !== '' && q === '')) {
            dispatch(lisArticleAction(q, p))
        }
        if (q !== qer) {
            setQer(q)
        }
        if (!c) {
            dispatch(settingsPublicationsAction())
        }
    }, [dispatch, q, p, c])

    const [activePanel, SetActivePanel] = useState(0)

    const panelHandler = id => {
        if (id === activePanel) {
            SetActivePanel(-1)
        } else {
            SetActivePanel(id)
        }
    }

    const urlCleaner = (url) => {
        let result = `https://${url.split('https://')[1].replace('" rel="noopener noreferrer" target="_blank">', '')}`
        return result
    }
    return (
        <div>
            <PageTitle title='Publications' />
            <SearchBox placeholder={'Search publications'} count={count} q={q} data={articles} next={'articles'} />
            {c ?
                    <div dangerouslySetInnerHTML={{ __html: c }} className='text-justify mx-4 text-sm'></div>
                : null}

            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (

                        <Accordion defaultActiveKey="0">
                            {articles && articles.map((item, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header className={activePanel === index ? 'active' : ''} onClick={() => panelHandler(index)}>
                                        {item.year} <span className={isMobile ? 'text-mobile' : 'text-sm'}>({item.pubs.length > 1 ? `${item.pubs.length} publications` : '1 publication'})</span>
                                    </Accordion.Header>
                                    <Accordion.Body className="test">
                                        {item.pubs.map(publish => (
                                            <div className='text-sm mb-1' key={publish.id}>
                                                {publish.doc &&
                                                    <span className='dang text-me text-color-2 cursor-pointer m-2' onClick={() => saveFile(publish.doc)}>
                                                        <i className='fas fa-file-pdf'></i>
                                                    </span>
                                                }
                                                {publish.reference.split('https://')[1] ?
                                                    <div className='mb-2'>
                                                        {/* <p>{`https://${publish.reference.split('https://')[1].replace('" rel="noopener noreferrer" target="_blank">', '')}`}</p> */}
                                                        <a href={urlCleaner(publish.reference)}
                                                            target='_blank' className='text-color-3'>
                                                            <div dangerouslySetInnerHTML={{ __html: publish.reference.split('https://')[0] }}></div>
                                                        </a>
                                                    </div>
                                                    :
                                                    <div dangerouslySetInnerHTML={{ __html: publish.reference }}></div>
                                                }
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}
            <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />
        </div>)
}

export default ArticleScreen