import React, { useState, useRef, useEffect } from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { OffCanvas, OffCanvasMenu } from "react-offcanvas";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'
import SimpleTreeMenu from './SimpleTreeMenu';
import OutsideClickHandler from './OutsideClickHandler';
import { isMobile} from 'react-device-detect';
import "./styles.css";
import "./SearchBar.css";

function SeachCatalog(props) {

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const handleClick = () => {
        setIsMenuOpened2(false);
        setIsMenuOpened(true);
    }

    const [isMenuOpened2, setIsMenuOpened2] = useState(false);
    const handleClick2 = () => {
        setIsMenuOpened(false);
        setIsMenuOpened2(true);
    }

    const navigation = useNavigate();
    const wordEntered = useRef('');


    const [searchParams] = useSearchParams();
    let tree = searchParams.get('tree')


    const searchHandler = () => {
        if (wordEntered.current.value) {
            navigation(`?q=${wordEntered.current.value}`);
        }
    }

    // Advanced search section -------------------------------------
    const [family, setFamily] = useState('')
    const [sub_family, setSub_family] = useState('')
    const [tribe, setTribe] = useState('')
    const [genus, setGenus] = useState('')
    const [match, setMatch] = useState('all')
    const [species, setSpecies] = useState('')
    const [subspecies, setSubspecies] = useState('')

    const submitAdvancedHandler = (e) => {
        e.preventDefault()

        navigation(`?B=${family}&C=${sub_family}&D=${tribe}&E=${genus}&F=${species}&G=${subspecies}&H=${match}`);
        setIsMenuOpened(false)
        setIsMenuOpened2(false)
    }
    useEffect(() => {
        setIsMenuOpened2(false)
    }, [tree, family, sub_family, tribe, genus, species, subspecies, match])

    return (<React.Fragment>
        <Row className="mb-3">
            <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
                <div className="search">
                    <div className="searchInputs">
                        <input
                            type='text'
                            name='q'
                            className='form-control'
                            placeholder={props.placeholder}
                            ref={wordEntered}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    searchHandler()
                                }

                            }}
                        />
                        <Button variant="light" size="sm" onClick={searchHandler}>
                            Search <i className='fas fa-search'></i>
                        </Button>
                    </div>
                </div>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Button variant={isMenuOpened ? "primary" : "light"} onClick={handleClick}>
                    Advanced Search <i className='fas fa-bars'></i>
                </Button>
                <Button variant={isMenuOpened2 ? "primary" : "light"} onClick={handleClick2}>
                    Hierarchy Search <i className='fas fa-sitemap'></i>
                </Button>
            </Col>
        </Row>
        {props.count &&
            <Row>
                <Col sm={12} md={6} lg={6} xl={6} xxl={6} >
                    <div className='mb-4'>
                        <i className='fas fa-search'></i> Found {props.count} items
                        <span>
                            |
                            <Link to="./" className='mb-4'>
                                <i className='fas fa-check'></i> Check all Records
                            </Link>
                        </span>
                    </div>
                </Col>
            </Row>
        }

        {
            <OutsideClickHandler
                onOutsideClick={() => {
                    setIsMenuOpened(false);
                }}
            >
                <OffCanvas
                    width={500}
                    transitionDuration={300}
                    isMenuOpened={isMenuOpened}
                    position={"right"}
                    effect={"overlay"}
                >
                    <OffCanvasMenu className="menuClass">
                        <small className='mx-2 text-color-2 cursor-pointer' onClick={() => { setIsMenuOpened(false) }}><i className='fas fa-times'> Close </i></small>
                        <h5 className="mb-4">
                            <i className='fas fa-bars m-2'></i>
                            Advanced Search
                        </h5>
                        <Form onSubmit={submitAdvancedHandler} className={isMobile ? 'text-mobile' : 'text-sm'}>
                            <Form.Group controlId='FamilyInput' className='mb-2'>
                                <Form.Control
                                    type='text'
                                    placeholder='Family'
                                    onChange={(e) => setFamily(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='SubfamilyInput' className='mb-2'>
                                <Form.Control
                                    type='text'
                                    placeholder='Subfamily'
                                    onChange={(e) => setSub_family(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            {/* <Form.Group controlId='TribeInput' className='mb-2'>
                                <Form.Control
                                    type='text'
                                    placeholder='Tribe'
                                    // onChange={(e) => setTribe(e.target.value)}
                                    disabled={true}
                                >
                                </Form.Control>
                            </Form.Group> */}

                            <Form.Group controlId='GeunsInput' className='mb-2'>
                                <Form.Control
                                    type='text'
                                    placeholder='Geuns'
                                    onChange={(e) => setGenus(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='SpeciesInput' className='mb-2'>
                                <Form.Control
                                    type='text'
                                    placeholder='Species'
                                    onChange={(e) => setSpecies(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='SubspeciesInput' className='mb-2'>
                                <Form.Control
                                    type='text'
                                    placeholder='Subspecies'
                                    onChange={(e) => setSubspecies(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='MatchCheck' className='mb-2'>
                                <Form.Check
                                    inline
                                    checked={match === 'all'}
                                    type='radio'
                                    label='Match All'
                                    id='adv1'
                                    name='paymentMethod'
                                    value='all'
                                    onChange={(e) => setMatch('all')}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    checked={match === 'any'}
                                    type='radio'
                                    label='Match Any'
                                    id='adv2'
                                    name='paymentMethod'
                                    value='any'
                                    onChange={(e) => setMatch('any')}
                                >
                                </Form.Check>
                            </Form.Group>

                            <Button type='submit' variant='primary' className='btn btn-block'
                                disabled={!(family.trim() || sub_family.trim() || tribe.trim() || genus.trim() || species.trim() || subspecies.trim())}>
                                Search
                            </Button>
                        </Form>
                    </OffCanvasMenu>
                </OffCanvas>

            </OutsideClickHandler >
        }

        {
            <OutsideClickHandler
                onOutsideClick={() => {
                    setIsMenuOpened2(false);
                }}
            >
                <OffCanvas
                    width={500}
                    transitionDuration={300}
                    isMenuOpened={isMenuOpened2}
                    position={"right"}
                    effect={"overlay"}
                >
                    <OffCanvasMenu className="menuClass">
                        <small className='mx-2 text-color-2 cursor-pointer' onClick={() => { setIsMenuOpened2(false) }}><i className='fas fa-times'> Close </i></small>
                        <h5>
                            <i className='fas fa-sitemap m-2'></i>Hierarchy Search
                        </h5>
                        <SimpleTreeMenu />
                    </OffCanvasMenu>
                </OffCanvas>
            </OutsideClickHandler>

        }

    </React.Fragment >
    )
}

export default SeachCatalog

