import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import { listTAXAAction, createTAXAAction } from '../../../actions/herbulotActions'
import PageTitle from '../../../components/PageTitle'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../editor.css'

function AdminTaxa() {
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const navigation = useNavigate();
  const [category, setCategory] = useState('species')
  const [title, setTitle] = useState('')
  const [type, setType] = useState('described')

  const [changed, setChanged] = useState(false)

  const dispatch = useDispatch()

  const Taxa = useSelector(state => state.listTAXA)
  const { error, loading, taxa } = Taxa


  useEffect(() => {

    if (!userInfo || !userInfo.isAdmin) {
      navigation('/login?redirect=admin')
    }
    setChanged(false)
    dispatch(listTAXAAction());
  }, [dispatch, changed])


  async function deleteTaxa(id) {
    // if (window.confirm('Are you sure you want to delete this item?')) {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }
      const { data } = await axios.delete(
        `/api/delete/TAXA/${id}/`,
        config
      )

      if (data.success) {
        setChanged(true)
      }
    // }
  }

  const submitHandler = () => {
    dispatch(createTAXAAction({
      title,
      type,
      category,
    }))
    setChanged(true)
    setTitle('')
  }
  const quillModules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],

        [{ 'script': 'sub' }, { 'script': 'super' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
    ]
}
  return (
    <div>
      {loading
        ? (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
          :
          (
            <div>
              <PageTitle title='Claude Herbulot Taxa and Patronyms' />
              <Row className='my-3'>
                <Col md={8}>
                  {/* <Form.Control
                    placeholder='One item per line'
                    as='textarea'
                    className='h-100'
                    required
                    rows='10'
                    value={title ?? ""}
                    onChange={(e) => setTitle(e.target.value)}
                  >
                  </Form.Control> */}
                  <ReactQuill theme="snow" value={title ?? ""} onChange={setTitle} modules={quillModules}  preserveWhitespace="true" />

                </Col>
                <Col md={4}>
                  <Form.Control
                    as="select"
                    value={type ?? ""}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value='described'>
                      Described
                    </option>
                    <option value='named'>
                      Patronyms
                    </option>
                  </Form.Control>

                  <Form.Control
                    className='my-3'
                    as="select"
                    value={category ?? ""}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value='species'>
                      species
                    </option>
                    <option value='genera'>
                      genera
                    </option>
                  </Form.Control>
                  <Button variant='success' className='btn btn-block'
                    onClick={() => submitHandler()}><i className='fas fa-plus'></i> Add Taxa</Button>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col md={6}>
                  <h1>Patronyms of CH</h1>

                  {taxa.named && taxa.named.length > 0 &&
                    <div>
                      {taxa.named.map(item => (
                          <span className='tag tag--pill m-1' key={item.id}>
                            <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                            <small className='mx-2'>({item.category})</small>
                            <span className='text-color-2'>
                              <i className='fas fa-trash m-2' onClick={() => deleteTaxa(item.id)}></i>
                            </span>
                          </span>
                      ))}
                    </div>
                  }
                </Col>
                <Col md={6}>
                  <h1>Described TAXA</h1>
                  {taxa.described && taxa.described.length > 0 &&
                    <div>
                      {taxa.described.map(item => (
                          <span className='tag tag--pill m-1' key={item.id}>
                          <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                            <small className='mx-2'>({item.category})</small>
                              <span className='text-color-2'>
                              <i className='fas fa-trash m-2' onClick={() => deleteTaxa(item.id)}></i>
                            </span>
                          </span>
                      ))}
                    </div>
                  }
                </Col>
              </Row>
            </div>
          )}
    </div>
  )
}

export default AdminTaxa