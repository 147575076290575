import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { settingsArchiveAction } from '../../actions/assetActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import PageTitle from '../../components/PageTitle'
import { saveAs } from 'file-saver'
import { SITE_URL } from '../../constants/siteConstants';
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import SocialMedia from '../../components/SocialMedia'

function Archive() {
    const settingsArchive = useSelector(state => state.settingsArchive)
    const { loading, error, archiveList } = settingsArchive

    const dispatch = useDispatch();
    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    useEffect(() => {
        if (!archiveList) {
            dispatch(settingsArchiveAction());
        }
    }, [dispatch])

    return (
        <div className='mt-2'>
            <PageTitle title={'Archive'}></PageTitle>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>) :
                    (archiveList && archiveList.length &&
                        <Row>
                            {archiveList.map(item => (
                                <Col key={item.id} sm={6} md={4} lg={6} xl={4}>
                                    <div className="blog-entry p-3 fadeInLeft animated">
                                        <Link to={`/archive/${item.id}`} className="blog-img">
                                            <img src={item.img} className="img-responsive img-fit" alt='' />
                                        </Link>
                                        <SocialMedia
                                            url={SITE_URL}
                                            text={item.title}
                                        />
                                        {item.doc ?
                                        <button className='btn btn-primary mt-1 mb-1' onClick={() => saveFile(item.doc)}>
                                            <i className='fas fa-download'></i> Download
                                        </button> : null}
                                        <h3>
                                            <Link to={`/archive/${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </h3>
                                        <Link to={`/archive/${item.id}`}>
                                            Read more ...
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    )}

        </div>
    )
}

export default Archive