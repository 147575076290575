import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LeftBar from './components/LeftBar'
import HomeScreen from './screens/HomeScreen'

import NewsScreen from './screens/assets/NewsScreen'
import NewsLetterScreen from './screens/assets/NewsLetterScreen'
import SingleNewsScreen from './screens/assets/SingleNewsScreen'
import CongressScreen from './screens/assets/CongressScreen'
import SingleCongressScreen from './screens/assets/SingleCongressScreen'

import ArticleScreen from './screens/assets/ArticleScreen'

import LoginScreen from './screens/members/LoginScreen'
import RegisterScreen from './screens/admin/members/RegisterScreen'
import ContactUsScreen from './screens/members/ContactUsScreen'

import WorldCatalog from './screens/geometridae/catalog/WorldCatalog'
import SingleCatalogScreen from './screens/geometridae/catalog/SingleCatalogScreen'
import Classer from './screens/geometridae/classification/Classer'
import MundiScreen from './screens/geometridae/classification/MundiScreen'

import Scientific from './components/Home/Scientific'

import AdminNewsScreen from './screens/admin/news/AdminNewsScreen'
import AdminNewsEditScreen from './screens/admin/news/AdminNewsEditScreen'
import AdminNewsAddScreen from './screens/admin/news/AdminNewsAddScreen'

import AdminNewslettersScreen from './screens/admin/newsletters/AdminNewslettersScreen'
import AdminNewslettersEditScreen from './screens/admin/newsletters/AdminNewslettersEditScreen'
import AdminNewsletterAddScreen from './screens/admin/newsletters/AdminNewsletterAddScreen'

import AdminCongressScreen from './screens/admin/congress/AdminCongressScreen'
import AdminCongressEditScreen from './screens/admin/congress/AdminCongressEditScreen'
import AdminCongressAddScreen from './screens/admin/congress/AdminCongressAddScreen'

import AdminArticleScreen from './screens/admin/articles/AdminArticleScreen'

import AdminCatalogueScreen from './screens/admin/phylogeny/AdminEditCatalogue'

import AdminSettingsScreen from './screens/admin/settings/AdminSettingsScreen'
import AdminEditHome from './screens/admin/settings/AdminEditHome'
import AdminEditContact from './screens/admin/settings/AdminEditContact'
import AdminEditMundi from './screens/admin/settings/AdminEditMundi'
import AdminEditInfoCH from './screens/admin/settings/AdminEditInfoCH'
import AdminEditClassification from './screens/admin/settings/AdminEditClassification'
import AdminPubNotes from './screens/admin/settings/AdminPubNotes'
import AdminCatNotes from './screens/admin/settings/AdminCatNotes'

import AdminEditAckScreen from './screens/admin/settings/AdminEditAckScreen'

import AdminEditDisclaimerScreen from './screens/admin/settings/AdminEditDisclaimerScreen'

import AdminClaudeScreen from './screens/admin/settings/AdminClaudeScreen'
import AdminAddObit from './screens/admin/settings/AdminAddObit'
import AdminEditObit from './screens/admin/settings/AdminEditObit'
import AdminHerbulotArticleScreen from './screens/admin/settings/AdminHerbulotArticleScreen'
import AdminTaxa from './screens/admin/settings/AdminTaxa'
import AdminEditIntroduction from './screens/admin/settings/AdminEditIntroduction'

import AdminArchiveScreen from './screens/admin/archive/AdminArchiveScreen'
import AdminArchiveEditScreen from './screens/admin/archive/AdminArchiveEditScreen'
import AdminArchiveAddScreen from './screens/admin/archive/AdminArchiveAddScreen'

import ClaudeScreen from './screens/herbulot/ClaudeScreen'
import ObitScreen from './screens/herbulot/ObitScreen'
import TaxaScreen from './screens/herbulot/TaxaScreen'
import PubScreen from './screens/herbulot/PubScreen'

import AdminMemberEdit from './screens/admin/members/AdminMemberEdit'
import AdminMemberEditScreen from './screens/admin/members/AdminMemberEditScreen'
import AdminMessagesScreen from './screens/admin/members/AdminMessagesScreen'

import AdminScreen from './screens/admin/AdminScreen'

import UnsubscribeScreen from './screens/members/UnsubscribeScreen'

import DisclaimerScreen from './screens/assets/DisclaimerScreen'
import AcknowledgementScreen from './screens/assets/AcknowledgementScreen'
import Introduction from './screens/assets/Introduction'
import Archive from './screens/assets/Archive'

import { FaLongArrowAltUp } from "react-icons/fa";
import SingleArchiveScreen from './screens/assets/SingleArchiveScreen'


function App() {
  const [showArrow, setShowArrow] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);
      const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        if (position > 500) {
          setShowArrow(true)
        }else{
          setShowArrow(false)
        }
    };
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
          window.removeEventListener("scroll", handleScroll);
      };
  }, [scrollPosition])
  return (

    <Router>

      <div>
        <LeftBar />
        <div id='main'>
          {/* <Header /> */}
          <Routes>
            <Route path='/' element={<HomeScreen />} exact />
            <Route index element={<HomeScreen />} />
            <Route path='assets/newsletters/' element={<NewsLetterScreen />} />
            <Route path='assets/news/' element={<NewsScreen exact />} />
            <Route path='assets/news/:id/' element={<SingleNewsScreen />} />
            <Route path='assets/congresses/' element={<CongressScreen />} exact />
            <Route path='assets/congresses/:id/' element={<SingleCongressScreen />} />

            <Route path='publications/' element={<ArticleScreen />} />
            <Route path='introduction/' element={<Introduction />} />
            
            <Route path='archive/' element={<Archive />} />
            <Route path='archive/:id/' element={<SingleArchiveScreen />} />

            <Route path='geometridae/classification/' element={<Classer />} exact />
            <Route path='geometridae/mundi/' element={<MundiScreen />} exact />
            <Route path='geometridae/Catalogue/' element={<WorldCatalog />} exact />
            <Route path='geometridae/Catalogue/:tbl/:id/' element={<SingleCatalogScreen />} />

            <Route path='members/scientific/' element={<Scientific />} />
            <Route path='login/' element={<LoginScreen />} />
            <Route path='contactus/' element={<ContactUsScreen />} />
            <Route path='unsubscribe/:id/' element={<UnsubscribeScreen />} />

            <Route path='ch/' element={<ClaudeScreen />} />
            <Route path='claude/obituaries/' element={<ObitScreen />} />
            <Route path='claude/claudepublications/' element={<PubScreen />} />
            <Route path='claude/taxadescribed/' element={<TaxaScreen type={true} />} />
            <Route path='claude/taxanamed/' element={<TaxaScreen type={false} />} />

            <Route path='admin/' element={<AdminScreen />} >

              <Route path='news/edit/:id/' element={<AdminNewsEditScreen />} />
              <Route path='news/add/' element={<AdminNewsAddScreen />} />
              <Route path='news/' element={<AdminNewsScreen />} exact />

              <Route path='newsletters/edit/:id/' element={<AdminNewslettersEditScreen />} />
              <Route path='newsletters/add/' element={<AdminNewsletterAddScreen />} />
              <Route path='newsletters/' element={<AdminNewslettersScreen />} exact />

              <Route path='congresses/edit/:id/' element={<AdminCongressEditScreen />} />
              <Route path='congresses/add/' element={<AdminCongressAddScreen />} />
              <Route path='congresses/' element={<AdminCongressScreen />} exact />

              <Route path='articles/' element={<AdminArticleScreen />} />

              {/* <Route path='higher-classification/' element={<AdminClassificationScreen />} /> */}
              <Route path='Catalogue/:id/' element={<AdminCatalogueScreen />} />

              <Route path='members/register/' element={<RegisterScreen />} />
              <Route path='members/edit/:id/' element={<AdminMemberEditScreen />} />
              <Route path='members/' element={<AdminMemberEdit />} exact />
              <Route path='messages/' element={<AdminMessagesScreen />} exact />
              <Route path='settings/catnotes/' element={<AdminCatNotes />} />
              
              <Route path='settings/' element={<AdminSettingsScreen />} />
              <Route path='settings/home/' element={<AdminEditHome />} />
              <Route path='settings/contactus/' element={<AdminEditContact />} />
              <Route path='settings/mundi/' element={<AdminEditMundi />} />
              <Route path='settings/chinfo/' element={<AdminEditInfoCH />} />
              <Route path='settings/introduction/' element={<AdminEditIntroduction />} />

              <Route path='settings/archive/edit/:id/' element={<AdminArchiveEditScreen />} />             
              <Route path='archive/add/' element={<AdminArchiveAddScreen />} />
              <Route path='settings/archive/' element={<AdminArchiveScreen />} />

              <Route path='settings/classification/' element={<AdminEditClassification />} />
              <Route path='settings/publications/' element={<AdminPubNotes />} />

              <Route path='ack/' element={<AdminEditAckScreen />} />
              <Route path='disclaimer/' element={<AdminEditDisclaimerScreen />} />

              <Route path='obituaries/' element={<AdminClaudeScreen exact />} />
              <Route path='obituaries/add' element={<AdminAddObit />} />
              <Route path='obituaries/edit/:id' element={<AdminEditObit />} />

              <Route path='herbulotarticles/' element={<AdminHerbulotArticleScreen />} />

              <Route path='taxa/' element={<AdminTaxa />} />

            </Route>
            <Route path='disclaimer/' element={<DisclaimerScreen />} />
            <Route path='acknowledgement/' element={<AcknowledgementScreen />} />
            <Route path="*" element={<HomeScreen />} />
          </Routes>
          {/* <Footer /> */}
        </div>
        { showArrow && 
        <div className="row">
          <div className="col-lg-4">
            <a className="backtop js-scroll-trigger reveal" href="#top">
              <FaLongArrowAltUp className='text-white text-me' />
            </a>
          </div>
        </div>}
      </div>

    </Router>
  );
}

export default App;
