import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { saveAs } from "file-saver";
import { SITE_URL } from '../constants/siteConstants';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { useSelector } from 'react-redux';

function CatalogItem({ rec, isAdmin, modeHandler, editMode, setReload }) {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const navigation = useNavigate();
    const recDeleter = async (tbl, id) => {
        const res = window.confirm('Are you sure to delete this record?');
        if (res) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            const { data } = await axios.delete(
                `/api/delete/${tbl}/${id}/`,
                config
            )
            if (data.success) {
                if(tbl === 'CatalogY'){
                    console.log('gonna put syns')
                    const config1 = {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${userInfo.token}`
                        }
                    }                    
                    const { data } = await axios.put(
                        `/api/phylogeny/catalog/updatesyns/${rec.id}/`,
                        config1
                    )
                    console.log(data)
                }
                navigation('/geometridae/catalogue');
                setReload(true)
            } else {
                alert('something went wrong')
            }
        }
    }

    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };
    return (
        <div className={isMobile ? 'text-mobile' : 'text-sm'}>
            {(isAdmin && !editMode) &&
                <>
                    <i className='fas fa-times text-color-2 cursor-pointer mx-1' onClick={() => recDeleter('CatalogN', rec.id)}></i>
                    <i className='fas fa-edit text-color-5 cursor-pointer' onClick={() => modeHandler('CatalogN', rec.id)}></i>
                </>
            }
            <Link to={`./CatalogN/${rec.id}`}>
                {rec.genus === rec.original_genus ?
                    <span className="p-1 mb-2 mr-1">
                        <i>{rec.genus} {rec.senior_species} {rec.senior_sub_species}</i> {rec.senior_species_author}, {rec.senior_species_year}
                    </span>
                    :
                    <span className="p-1 mb-2">
                        <i>{rec.genus} {rec.senior_species} {rec.senior_sub_species}</i> ({rec.senior_species_author}, {rec.senior_species_year}) (<i>{rec.original_genus}</i>)
                    </span>
                }
            </Link>
            <small className="mx-3">
                T.l. in: {rec.country}
            </small>
            {rec.docfile && <i className='fas fa-download text-color-3 cursor-pointer' onClick={() => saveFile(rec.docfile)}></i>}

            {rec && rec.synonyms && rec.synonyms.map(syn => (
                <div key={syn.id}>
                    {isAdmin && !editMode &&
                        <>
                            <i className='fas fa-times text-color-2 cursor-pointer mx-1' onClick={() => recDeleter('CatalogY', syn.id)}></i>
                            <i className='fas fa-edit text-color-5 cursor-pointer' onClick={() => modeHandler('CatalogY', syn.id, rec.id)}></i>
                        </>
                    }
                    <Link to={`./CatalogY/${syn.id}`}>
                        {syn.genus === syn.original_genus ?
                            <span className="p-1 mx-3">
                                = <i>{syn.species} {syn.subspecies}</i> {syn.species_author}, {syn.species_year}
                            </span>
                            :
                            <span className="p-1 mx-3">
                                = <i>{syn.species} {syn.subspecies}</i> ({syn.species_author}, {syn.species_year}) (<i>{syn.original_genus}</i>)
                            </span>
                        }
                    </Link>
                    <small>
                        T.l. in: {syn.country}
                    </small>
                    {syn.docfile && <i className='fas fa-download text-color-3 cursor-pointer mx-3' onClick={() => saveFile(syn.docfile)}></i>}
                </div>
            ))
            }
            <p></p>


        </div >
    )
}

export default CatalogItem