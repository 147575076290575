import axios from 'axios'
import {
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SETTINGS_FAIL,

    SETTINGS_UPDATE_REQUEST,
    SETTINGS_UPDATE_SUCCESS,
    SETTINGS_UPDATE_FAIL,
    
    SETTINGS_HOME_REQUEST,
    SETTINGS_HOME_SUCCESS,
    SETTINGS_HOME_FAIL,

    SETTINGS_HOME_UPDATE_REQUEST,
    SETTINGS_HOME_UPDATE_SUCCESS,
    SETTINGS_HOME_UPDATE_FAIL,

    SETTINGS_CONTACT_REQUEST,
    SETTINGS_CONTACT_SUCCESS,
    SETTINGS_CONTACT_FAIL,

    SETTINGS_CONTACT_UPDATE_REQUEST,
    SETTINGS_CONTACT_UPDATE_SUCCESS,
    SETTINGS_CONTACT_UPDATE_FAIL,

    SETTINGS_MUNDI_REQUEST,
    SETTINGS_MUNDI_SUCCESS,
    SETTINGS_MUNDI_FAIL,

    SETTINGS_MUNDI_UPDATE_REQUEST,
    SETTINGS_MUNDI_UPDATE_SUCCESS,
    SETTINGS_MUNDI_UPDATE_FAIL,

    CH_INFO_REQUEST,
    CH_INFO_SUCCESS,
    CH_INFO_FAIL,

    CH_INFO_UPDATE_REQUEST,
    CH_INFO_UPDATE_SUCCESS,
    CH_INFO_UPDATE_FAIL,

    SETTINGS_CLASSIFICATION_REQUEST,
    SETTINGS_CLASSIFICATION_SUCCESS,
    SETTINGS_CLASSIFICATION_FAIL,

    SETTINGS_CLASSIFICATION_UPDATE_REQUEST,
    SETTINGS_CLASSIFICATION_UPDATE_SUCCESS,
    SETTINGS_CLASSIFICATION_UPDATE_FAIL,

    SETTINGS_PUBLICATIONS_REQUEST,
    SETTINGS_PUBLICATIONS_SUCCESS,
    SETTINGS_PUBLICATIONS_FAIL,

    SETTINGS_PUBLICATIONS_UPDATE_REQUEST,
    SETTINGS_PUBLICATIONS_UPDATE_SUCCESS,
    SETTINGS_PUBLICATIONS_UPDATE_FAIL,

    SETTINGS_CATNOTES_REQUEST,
    SETTINGS_CATNOTES_SUCCESS,
    SETTINGS_CATNOTES_FAIL,

    SETTINGS_CATNOTES_UPDATE_REQUEST,
    SETTINGS_CATNOTES_UPDATE_SUCCESS,
    SETTINGS_CATNOTES_UPDATE_FAIL,

    ACK_REQUEST,
    ACK_SUCCESS,
    ACK_FAIL,

    ACK_UPDATE_REQUEST,
    ACK_UPDATE_SUCCESS,
    ACK_UPDATE_FAIL,

    DISCLAIMER_REQUEST,
    DISCLAIMER_SUCCESS,
    DISCLAIMER_FAIL,

    DISCLAIMER_UPDATE_REQUEST,
    DISCLAIMER_UPDATE_SUCCESS,
    DISCLAIMER_UPDATE_FAIL,

    COUNTERS_REQUEST,
    COUNTERS_SUCCESS,
    COUNTERS_FAIL,

    NEWS_TOP_REQUEST,
    NEWS_TOP_SUCCESS,
    NEWS_TOP_FAIL,

    NEWS_ALL_REQUEST,
    NEWS_ALL_SUCCESS,
    NEWS_ALL_FAIL,

    NEWS_LIST_REQUEST,
    NEWS_LIST_SUCCESS,
    NEWS_LIST_FAIL,

    NEWS_DETAILS_REQUEST,
    NEWS_DETAILS_SUCCESS,
    NEWS_DETAILS_FAIL,

    NEWS_CREATE_REQUEST,
    NEWS_CREATE_SUCCESS,
    NEWS_CREATE_FAIL,

    NEWS_UPDATE_REQUEST,
    NEWS_UPDATE_SUCCESS,
    NEWS_UPDATE_FAIL,

    NEWS_DELETE_REQUEST,
    NEWS_DELETE_SUCCESS,
    NEWS_DELETE_FAIL,

    HOME_ALBUM_REQUEST,
    HOME_ALBUM_SUCCESS,
    HOME_ALBUM_FAIL,

    MAIN_ALBUM_REQUEST,
    MAIN_ALBUM_SUCCESS,
    MAIN_ALBUM_FAIL,

    NEWSLETTER_LIST_REQUEST,
    NEWSLETTER_LIST_SUCCESS,
    NEWSLETTER_LIST_FAIL,

    NEWSLETTER_DETAILS_REQUEST,
    NEWSLETTER_DETAILS_SUCCESS,
    NEWSLETTER_DETAILS_FAIL,

    NEWSLETTER_CREATE_REQUEST,
    NEWSLETTER_CREATE_SUCCESS,
    NEWSLETTER_CREATE_FAIL,

    NEWSLETTER_DELETE_REQUEST,
    NEWSLETTER_DELETE_SUCCESS,
    NEWSLETTER_DELETE_FAIL,

    NEWSLETTER_UPDATE_REQUEST,
    NEWSLETTER_UPDATE_SUCCESS,
    NEWSLETTER_UPDATE_FAIL,
        
    NEWSLETTER_EMAIL_REQUEST,
    NEWSLETTER_EMAIL_SUCCESS,
    NEWSLETTER_EMAIL_FAIL,

    CONGRESS_ALL_REQUEST,
    CONGRESS_ALL_SUCCESS,
    CONGRESS_ALL_FAIL,

    CONGRESS_LIST_REQUEST,
    CONGRESS_LIST_SUCCESS,
    CONGRESS_LIST_FAIL,

    CONGRESS_DETAILS_REQUEST,
    CONGRESS_DETAILS_SUCCESS,
    CONGRESS_DETAILS_FAIL,


    CONGRESS_CREATE_REQUEST,
    CONGRESS_CREATE_SUCCESS,
    CONGRESS_CREATE_FAIL,

    CONGRESS_DELETE_REQUEST,
    CONGRESS_DELETE_SUCCESS,
    CONGRESS_DELETE_FAIL,

    CONGRESS_UPDATE_REQUEST,
    CONGRESS_UPDATE_SUCCESS,
    CONGRESS_UPDATE_FAIL,


    PARTICIPANT_CREATE_REQUEST,
    PARTICIPANT_CREATE_SUCCESS,
    PARTICIPANT_CREATE_FAIL,

    PARTICIPANT_DELETE_REQUEST,
    PARTICIPANT_DELETE_SUCCESS,
    PARTICIPANT_DELETE_FAIL,

    PROGRAM_CREATE_REQUEST,
    PROGRAM_CREATE_SUCCESS,
    PROGRAM_CREATE_FAIL,

    PROGRAM_DELETE_REQUEST,
    PROGRAM_DELETE_SUCCESS,
    PROGRAM_DELETE_FAIL,

    PROCEEDING_CREATE_REQUEST,
    PROCEEDING_CREATE_SUCCESS,
    PROCEEDING_CREATE_FAIL,

    PROCEEDING_DELETE_REQUEST,
    PROCEEDING_DELETE_SUCCESS,
    PROCEEDING_DELETE_FAIL,

    ARTICLE_REQUEST,
    ARTICLE_SUCCESS,
    ARTICLE_FAIL,

    ARTICLE_CREATE_REQUEST,
    ARTICLE_CREATE_SUCCESS,
    ARTICLE_CREATE_FAIL,

    ARTICLE_UPDATE_REQUEST,
    ARTICLE_UPDATE_SUCCESS,
    ARTICLE_UPDATE_FAIL,

    
    CAPTION_UPDATE_REQUEST,
    CAPTION_UPDATE_SUCCESS,
    CAPTION_UPDATE_FAIL,
    
    SETTINGS_INTRO_REQUEST,
    SETTINGS_INTRO_SUCCESS,
    SETTINGS_INTRO_FAIL,

    SETTINGS_INTRO_UPDATE_REQUEST,
    SETTINGS_INTRO_UPDATE_SUCCESS,
    SETTINGS_INTRO_UPDATE_FAIL,
    
    SETTINGS_ARCHIVE_REQUEST,
    SETTINGS_ARCHIVE_SUCCESS,
    SETTINGS_ARCHIVE_FAIL,

    SETTINGS_ARCHIVE_UPDATE_REQUEST,
    SETTINGS_ARCHIVE_UPDATE_SUCCESS,
    SETTINGS_ARCHIVE_UPDATE_FAIL,

    ARCHIVE_DETAILS_REQUEST,
    ARCHIVE_DETAILS_SUCCESS,
    ARCHIVE_DETAILS_FAIL,

    ATTACH_LIST_REQUEST,
    ATTACH_LIST_SUCCESS,
    ATTACH_LIST_FAIL,

    ATTACH_CREATE_REQUEST,
    ATTACH_CREATE_SUCCESS,
    ATTACH_CREATE_FAIL,
    ATTACH_CREATE_RESET    
} from '../constants/assetConstants'

// -------------------------------------------- SETTINGS

export const settingsAction = (cat) => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_REQUEST })

        const { data } = await axios.get(`/api/assets/settings/field/${cat}/`)

        dispatch({
            type: SETTINGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const settingsHomeAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_HOME_REQUEST })

        const { data } = await axios.get('/api/assets/settings/home/')

        dispatch({
            type: SETTINGS_HOME_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_HOME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsHomeAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_HOME_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/home/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_HOME_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_HOME_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const settingsContactAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_CONTACT_REQUEST })

        const { data } = await axios.get('/api/assets/settings/contact/')

        dispatch({
            type: SETTINGS_CONTACT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_CONTACT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsContactAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_CONTACT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/contact/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_CONTACT_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_CONTACT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const settingsMundiAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_MUNDI_REQUEST })

        const { data } = await axios.get('/api/assets/settings/mundi/')

        dispatch({
            type: SETTINGS_MUNDI_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_MUNDI_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsMundiAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_MUNDI_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/mundi/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_MUNDI_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_MUNDI_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const settingsIntroAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_INTRO_REQUEST })

        const { data } = await axios.get('/api/assets/settings/intro/')

        dispatch({
            type: SETTINGS_INTRO_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_INTRO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsIntroAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_INTRO_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/intro/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_INTRO_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_INTRO_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const CHInfoAction = () => async (dispatch) => {
    try {
        dispatch({ type: CH_INFO_REQUEST })

        const { data } = await axios.get('/api/assets/settings/chinfo/')

        dispatch({
            type: CH_INFO_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CH_INFO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateCHInfoAction = (chinfo) => async (dispatch, getState) => {

    try {
        dispatch({
            type: CH_INFO_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/chinfo/update/',
            chinfo,
            config
        )
        dispatch({
            type: CH_INFO_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CH_INFO_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const settingsClassificationAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_CLASSIFICATION_REQUEST })

        const { data } = await axios.get('/api/assets/settings/classification/')

        dispatch({
            type: SETTINGS_CLASSIFICATION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_CLASSIFICATION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsClassificationAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_CLASSIFICATION_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/classification/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_CLASSIFICATION_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_CLASSIFICATION_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const settingsPublicationsAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_PUBLICATIONS_REQUEST })

        const { data } = await axios.get('/api/assets/settings/publications/')

        dispatch({
            type: SETTINGS_PUBLICATIONS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_PUBLICATIONS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsPublicationsAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_PUBLICATIONS_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/settings/publications/update/',
            setting,
            config
        )
        dispatch({
            type: SETTINGS_PUBLICATIONS_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_PUBLICATIONS_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const counters = () => async (dispatch) => {
    try {
        dispatch({ type: COUNTERS_REQUEST })

        const { data } = await axios.get('/api/assets/counters/')

        dispatch({
            type: COUNTERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: COUNTERS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const mainAlbumAction = () => async (dispatch) => {
    try {
        dispatch({ type: MAIN_ALBUM_REQUEST })

        const { data } = await axios.get(`/api/assets/settings/mainalbum/`)

        dispatch({
            type: MAIN_ALBUM_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MAIN_ALBUM_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const homeAlbum = () => async (dispatch) => {
    try {
        dispatch({ type: HOME_ALBUM_REQUEST })

        const { data } = await axios.get(`/api/assets/settings/album/`)

        dispatch({
            type: HOME_ALBUM_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: HOME_ALBUM_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// -------------------------------------------- NEWS
export const topNews = () => async (dispatch) => {
    try {
        dispatch({ type: NEWS_TOP_REQUEST })

        const { data } = await axios.get(`/api/assets/news/top/`)

        dispatch({
            type: NEWS_TOP_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWS_TOP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listNews = (q = '', p = '') => async (dispatch) => {
    try {
        dispatch({ type: NEWS_LIST_REQUEST })

        const { data } = await axios.get(`/api/assets/news/?q=${q}&page=${p}`)

        dispatch({
            type: NEWS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const allNews = () => async (dispatch) => {
    try {
        dispatch({ type: NEWS_ALL_REQUEST })

        const { data } = await axios.get(`/api/assets/news/all/`)

        dispatch({
            type: NEWS_ALL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWS_ALL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const newsDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: NEWS_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/assets/news/${id}/`)

        dispatch({
            type: NEWS_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWS_DETAILS_FAIL,
            payload: error.response && error.response.data.message.detail
                ? error.response.data.message.detail
                : error.message,
        })

    }
}


export const updateNews = (news) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEWS_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/assets/news/update/${news.id}/`,
            news,
            config
        )
        dispatch({
            type: NEWS_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: NEWS_UPDATE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: NEWS_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createNewsAction = (news) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEWS_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/assets/news/add/',
            news,
            config
        )
        dispatch({
            type: NEWS_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: NEWS_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteNews = (tbl, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEWS_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(
            `/api/delete/${tbl}/${id}/`,
            config
        )

        dispatch({
            type: NEWS_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: NEWS_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


// -------------------------------------------- NEWSLETTER

export const listNewsletterAction = (q = '', p = '') => async (dispatch) => {
    try {
        dispatch({ type: NEWSLETTER_LIST_REQUEST })

        const { data } = await axios.get(`/api/assets/newsletter/?q=${q}&page=${p}`)

        dispatch({
            type: NEWSLETTER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWSLETTER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const newsletterDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: NEWSLETTER_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/assets/newsletter/${id}/`)

        dispatch({
            type: NEWSLETTER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWSLETTER_DETAILS_FAIL,
            payload: error.response && error.response.data.message.detail
                ? error.response.data.message.detail
                : error.message,
        })

    }
}


export const updateNewsletter = (newsletter) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEWSLETTER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/assets/newsletter/update/${newsletter.id}/`,
            newsletter,
            config
        )
        dispatch({
            type: NEWSLETTER_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: NEWSLETTER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: NEWSLETTER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createNewsletterAction = (newsletter) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEWSLETTER_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/assets/newsletter/add/',
            newsletter,
            config
        )
        dispatch({
            type: NEWSLETTER_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: NEWSLETTER_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteNewsletter = (tbl, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEWSLETTER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(
            `/api/delete/${tbl}/${id}/`,
            config
        )

        dispatch({
            type: NEWSLETTER_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: NEWSLETTER_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const emailNewsletterAction = (email) => async (dispatch, getState) => {
    try {
        dispatch({ type: NEWSLETTER_EMAIL_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post('/api/assets/newsletter/publish/',
        email,
        config)
        dispatch({
            type: NEWSLETTER_EMAIL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEWSLETTER_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// -------------------------------------------- CONGRESS 


export const listCongress = (q = '', p = '') => async (dispatch) => {
    try {
        dispatch({ type: CONGRESS_LIST_REQUEST })

        const { data } = await axios.get(`/api/assets/congress/?q=${q}&page=${p}`)

        dispatch({
            type: CONGRESS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CONGRESS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const allCongresss = () => async (dispatch) => {
    try {
        dispatch({ type: CONGRESS_ALL_REQUEST })

        const { data } = await axios.get(`/api/assets/congress/all/`)

        dispatch({
            type: CONGRESS_ALL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CONGRESS_ALL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const congressDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: CONGRESS_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/assets/congress/${id}/`)

        dispatch({
            type: CONGRESS_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CONGRESS_DETAILS_FAIL,
            payload: error.response && error.response.data.message.detail
                ? error.response.data.message.detail
                : error.message,
        })

    }
}


export const updateCongress = (congress) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONGRESS_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/assets/congress/update/${congress.id}/`,
            congress,
            config
        )
        dispatch({
            type: CONGRESS_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: CONGRESS_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CONGRESS_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createCongressAction = (congress) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONGRESS_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/assets/congress/add/',
            congress,
            config
        )
        dispatch({
            type: CONGRESS_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONGRESS_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteCongress = (tbl, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONGRESS_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(
            `/api/delete/${tbl}/${id}/`,
            config
        )

        dispatch({
            type: CONGRESS_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CONGRESS_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createParticipantAction = (id, participant) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARTICIPANT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/assets/congress/participant/add/${id}/`,
            participant,
            config
        )
        dispatch({
            type: PARTICIPANT_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARTICIPANT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteParticipant = (tbl, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARTICIPANT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(
            `/api/delete/${tbl}/${id}/`,
            config
        )

        dispatch({
            type: PARTICIPANT_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PARTICIPANT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createProceedingAction = (id, proceeding) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROCEEDING_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/assets/congress/proceeding/add/${id}/`,
            proceeding,
            config
        )
        dispatch({
            type: PROCEEDING_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROCEEDING_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteProceeding = (tbl, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROCEEDING_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(
            `/api/delete/${tbl}/${id}/`,
            config
        )

        dispatch({
            type: PROCEEDING_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PROCEEDING_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createProgramAction = (id, program) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROGRAM_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/assets/congress/program/add/${id}/`,
            program,
            config
        )
        dispatch({
            type: PROGRAM_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROGRAM_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteProgram = (tbl, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROGRAM_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(
            `/api/delete/${tbl}/${id}/`,
            config
        )

        dispatch({
            type: PROGRAM_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PROGRAM_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// -------------------------------------------- ARTICLES

export const lisArticleAction = (q = '') => async (dispatch) => {
    try {
        dispatch({ type: ARTICLE_REQUEST })

        const { data } = await axios.get(`/api/assets/articles/?q=${q}`)

        dispatch({
            type: ARTICLE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ARTICLE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const updateArticleAction = (article) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ARTICLE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/assets/articles/update/${article.id}/`,
            article,
            config
        )
        dispatch({
            type: ARTICLE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ARTICLE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createArticleAction = (article) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ARTICLE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/assets/articles/add/',
            article,
            config
        )
        dispatch({
            type: ARTICLE_CREATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: ARTICLE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ARTICLE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


// -------------------------------------------- ACK

export const ackAction = () => async (dispatch) => {
    try {
        dispatch({ type: ACK_REQUEST })

        const { data } = await axios.get('/api/assets/ack/list/')

        dispatch({
            type: ACK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ACK_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const updateAckAction = (ack) => async (dispatch, getState) => {

    try {
        dispatch({
            type: ACK_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/ack/update/',
            ack,
            config
        )
        dispatch({
            type: ACK_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ACK_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


// -------------------------------------------- DISCLAIMER

export const disclaimerAction = () => async (dispatch) => {
    try {
        dispatch({ type: DISCLAIMER_REQUEST })

        const { data } = await axios.get('/api/assets/disclaimer/list/')

        dispatch({
            type: DISCLAIMER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DISCLAIMER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateDisclaimerAction = (disclaimer) => async (dispatch, getState) => {

    try {
        dispatch({
            type: DISCLAIMER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/assets/disclaimer/update/',
            disclaimer,
            config
        )
        dispatch({
            type: DISCLAIMER_UPDATE_SUCCESS,
            payload: data,
        })



    } catch (error) {
        dispatch({
            type: DISCLAIMER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// ---------------------------------------------------------- CAPTION

export const updateCaptionAction = (caption) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CAPTION_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/updatecaption/',
            caption,
            config
        )
        dispatch({
            type: CAPTION_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CAPTION_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// -------------------------------------------- ARCHIVE

export const settingsArchiveAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_ARCHIVE_REQUEST })

        const { data } = await axios.get('/api/assets/settings/archive/')

        dispatch({
            type: SETTINGS_ARCHIVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_ARCHIVE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsArchiveAction = (setting) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_ARCHIVE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/assets/settings/archive/update/${setting.id}/`,
            setting,
            config
        )
        dispatch({
            type: SETTINGS_ARCHIVE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_ARCHIVE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAttachAction = () => async (dispatch) => {
    try {
        dispatch({ type: ATTACH_LIST_REQUEST })

        const { data } = await axios.get('/api/assets/settings/archive/')

        dispatch({
            type: ATTACH_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ATTACH_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const archiveDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: ARCHIVE_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/assets/settings/archive/${id}/`)

        dispatch({
            type: ARCHIVE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ARCHIVE_DETAILS_FAIL,
            payload: error.response && error.response.data.message.detail
                ? error.response.data.message.detail
                : error.message,
        })

    }
}


// --------------------------- CATNOTES


export const settingsCatNotesAction = () => async (dispatch) => {
    try {
        dispatch({ type: SETTINGS_CATNOTES_REQUEST })

        const { data } = await axios.get('/api/phylogeny/catnotes/')

        dispatch({
            type: SETTINGS_CATNOTES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_CATNOTES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSettingsCatNotesAction = (note) => async (dispatch, getState) => {

    try {
        dispatch({
            type: SETTINGS_CATNOTES_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/phylogeny/catnotes/update/',
            note,
            config
        )
        dispatch({
            type: SETTINGS_CATNOTES_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_CATNOTES_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
