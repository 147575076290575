import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listCongress, allCongresss } from '../../actions/assetActions'
import { Link, useSearchParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import SearchBox from '../../components/SearchBox'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import PageTitle from '../../components/PageTitle'
import { saveAs } from "file-saver";
import { SITE_URL } from '../../constants/siteConstants';

function CongressScreen() {
  const dispatch = useDispatch()

  const congressList = useSelector(state => state.listCongress)
  const { loading, error, congress, pages, page, has_next, has_prev, count } = congressList

  const allCongressList = useSelector(state => state.allCongress)
  const { congress: congressAllList } = allCongressList

  const [searchParams] = useSearchParams();
  let q = searchParams.get('q')
  let p = searchParams.get('page')
  if (!q) {
    q = ''
  }
  const [pager, setPager] = useState('')
  const [qer, setQer] = useState('')
  const saveFile = (file) => {
    saveAs(
        SITE_URL + file
    );
};

  useEffect(() => {
    if (!congress || !congress.length || (p && pager !== p) || (q !== qer) || (qer !== '' && q==='')) {
      dispatch(listCongress(q, p))
    }
    if (p !== pager) {
      setPager(p)
    }
    if (q !== qer) {
      setQer(q)
    }
    if (congressAllList.length === 0) {
      dispatch(allCongresss())
    }

  }, [dispatch, q, p])

  return (
    <div>
      <PageTitle title='Congresses' />
      <SearchBox placeholder={'Search congresses'} count={count} q={q} data={congressAllList} next={'congress'} />
      <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />

      {loading
        ? (<Loader />)
        : error
          ? (<Message variant='danger'>{error}</Message>)
          : (
            <Row>
              {congress.map(item => (
                <Col key={item.id} sm={6} md={4} lg={6} xl={4}>
                  <div className="blog-entry p-3 fadeInLeft animated">
                    <Link to={`/assets/congresses/${item.id}/`} className="blog-img">
                      <img src={item.img} className="img-responsive" alt={item.title} />
                    </Link>
                    {item.chairman &&
                      <span><small>Organizer(s): {item.chairman}  |</small></span>}
                    <span><small> Venue: {item.venue} </small></span>
                    {item.doc ? <p className='mt-1 mb-1 text-sm text-color-2 cursor-pointer' onClick={() => saveFile(item.doc)}>
                      <i className='fas fa-download text-color-2'></i> Download pdf
                    </p> : null}
                    <h3><Link to={`/assets/congresses/${item.id}/`}>{item.title}</Link></h3>
                  </div>
                </Col>
              ))}
            </Row>
          )}
      <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />
    </div>)
}

export default CongressScreen