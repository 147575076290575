import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allCatalogAction, treeResultAction, advancedSearchAction } from '../../../actions/phylogenyActions'
import { settingsCatNotesAction } from '../../../actions/assetActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SeachCatalog from '../../../components/SearchCatalog'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import Paginate from '../../../components/Paginate'
import PageTitle from '../../../components/PageTitle'
import { Accordion } from 'react-bootstrap'
import CatalogItem from '../../../components/CatalogItem'
import CatalogItemHeader from '../../../components/CatalogItemHeader'
import AdminEditCatalogue from '../../admin/phylogeny/AdminEditCatalogue'
import { SINGLE_CATALOG_RESET } from '../../../constants/phylogenyConstants'


function WorldCatalog() {
    let searchMethod = ''
    const [searchParams] = useSearchParams();
    let p = searchParams.get('page')
    let q = searchParams.get('q')
    let tree = searchParams.get('tree')
    let A = searchParams.get('A')
    let B = searchParams.get('B')
    let C = searchParams.get('C')
    let D = searchParams.get('D')
    let E = searchParams.get('E')
    let F = searchParams.get('F')
    let G = searchParams.get('G')
    let H = searchParams.get('H')
    const [CurrentPage, setCurrentPage] = useState(p)

    const [editMode, setEditMode] = useState(false)
    const cancleEditMode = () => {
        dispatch({ type: SINGLE_CATALOG_RESET })
        setEditMode(false)
    }
    const [tbl, setTbl] = useState('')
    const [selectedRecordID, SetSelectedRecordID] = useState('')
    const modeHandler = (tbl, id) => {
        SetSelectedRecordID(id)
        setTbl(tbl)
        setEditMode(true)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    const allCatalog = useSelector(state => state.allCatalog)
    const { loading, error, catalogs, pages, page, has_next, has_prev, count } = allCatalog

    const treeResult = useSelector(state => state.treeResult)
    const { loading: loadingTree, error: errorTree, catalogs: catalogsTree, pages: pagesTree, page: pageTree, has_next: has_nextTree, has_prev: has_prevTree, count: countTree } = treeResult

    const advancedResult = useSelector(state => state.advancedSearch)
    const { loading: loadingAdvanced, error: errorAdvanced, catalogs: catalogsAdvanced, pages: pagesAdvanced, page: pageAdvanced, has_next: has_nextAdvanced, has_prev: has_prevAdvanced, count: countAdvanced } = advancedResult

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
      
    const settingsNote = useSelector(state => state.settingsCatNote)
    const { error: errorNote, loading: loadingNote, note } = settingsNote

    if (tree) {
        searchMethod = 'tree'
    } else if (A || B || C || D || E || F || G || H) {
        searchMethod = 'advanced'
    } else {
        searchMethod = 'quick'
    }
    const dispatch = useDispatch()
    if (!q) {
        q = ''
    }
    const [qer, setQer] = useState()
    const [reload, setReload] = useState(false)
    const navigation = useNavigate()
    useEffect(() => {
        if (reload){
            setReload(false)
            dispatch(allCatalogAction(q, p))
            navigation('./')
        }
        if (!note) {
            dispatch(settingsCatNotesAction())
        }
        if (searchMethod === 'tree') {
            dispatch(treeResultAction(tree, p))
        } else if (searchMethod === 'advanced') {
            dispatch(advancedSearchAction(A, B, C, D, E, F, G, H, p))
        } else {
            if (!allCatalog || !allCatalog.catalogs || !allCatalog.catalogs.length || CurrentPage !== p || (q !== '' && q !== qer)) {
                if (p !== CurrentPage) {
                    setCurrentPage(p)
                }
                if (q !== qer) {
                    setQer(q)
                }
                dispatch(allCatalogAction(q, p))
            }
        }
    }, [dispatch, q, p, A, B, C, D, E, F, G, H, tree, editMode, reload])


    const [activePanel, SetActivePanel] = useState(0)

    const panelHandler = id => {
        if (id === activePanel) {
            SetActivePanel(-1)
        } else {
            SetActivePanel(id)
        }
    }

    let CatalogF = []
    let loadingF = false
    let errorF = ''
    let pagesF = ''
    let pageF = ''
    let has_nextF = ''
    let has_prevF = ''

    if (tree) {
        CatalogF = catalogsTree
        loadingF = loadingTree
        errorF = errorTree
        pagesF = pagesTree
        pageF = pageTree
        has_nextF = has_nextTree
        has_prevF = has_prevTree
    } else if (H) {
        CatalogF = catalogsAdvanced
        loadingF = loadingAdvanced
        errorF = errorAdvanced
        pagesF = pagesAdvanced
        pageF = pageAdvanced
        has_nextF = has_nextAdvanced
        has_prevF = has_prevAdvanced
    } else {
        CatalogF = catalogs
        loadingF = loading
        errorF = error
        pagesF = pages
        pageF = page
        has_nextF = has_next
        has_prevF = has_prev
    }

    const headerCountHandler = (item) => {
        const filtered = CatalogF.filter(f => f.blockn === item.blockn)
        let i = filtered.length
        filtered.map(p => (
            p.synonyms ?
                i += p.synonyms.length
                :
                null
        ))
        return (i)
    }
    const countFHandler = () => {
        if (tree) {
            return countTree
        } else {
            if (H) {
                return countAdvanced
            } else {
                if (q !== '') {
                    return count
                } else {
                    return allCatalog.count
                }
            }
        }
    }

    const reloadAllRecords = () => {
        navigation('/geometridae/catalogue');
        setReload(true)
    }

    return (
        <div>
            {userInfo && userInfo.isAdmin && editMode && <AdminEditCatalogue tbl={tbl} id={selectedRecordID} cancleEditMode={cancleEditMode} />}
            <PageTitle title='Online taxonomic facility of Geometridae' />
            {note ? <div dangerouslySetInnerHTML={{ __html: note }} className='text-justify mx-3 text-sm'></div> : null}
            <SeachCatalog placeholder={'Quick search'} q={q} data={''} next={'world-catalog'} />
            <h6>
                {countFHandler() ? <span className='text-success'>{countFHandler()} records found</span>:
                <span className='text-color-2'>no match</span>}
                {(q !== '' || tree || H)
                    &&
                    <span onClick={reloadAllRecords} className='text-color-5 cursor-pointer'>  |  Check all records</span>}
            </h6>

            <Paginate pages={pagesF} page={pageF} has_next={has_nextF} has_prev={has_prevF} q={q} isAdmin={false} />
            {loadingF
                ? (<Loader />)
                : errorF
                    ? (<Message variant='danger'>{errorF}</Message>)
                    : (
                        <Accordion defaultActiveKey="0">
                            {CatalogF && CatalogF.map((item, index) => (
                                (item.block) &&
                                (
                                    <div key={index}>
                                        <Accordion.Item eventKey={String(index)} key={item.id}>
                                            <Accordion.Header className={activePanel === index ? 'active' : ''} onClick={() => panelHandler(index)}>
                                                <CatalogItemHeader item={item} count={headerCountHandler(item)} />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <CatalogItem rec={item} isAdmin={userInfo && userInfo.isAdmin} modeHandler={modeHandler} editMode={editMode} setReload={setReload} />
                                                {CatalogF.map(b => (
                                                    (
                                                        b.super_family === item.super_family &&
                                                        b.family === item.family &&
                                                        b.sub_family === item.sub_family &&
                                                        b.tribe === item.tribe &&
                                                        b.genus === item.genus &&
                                                        b.id !== item.id
                                                    ) &&
                                                    <CatalogItem rec={b} isAdmin={userInfo && userInfo.isAdmin} modeHandler={modeHandler} editMode={editMode} key={b.id}  setReload={setReload} />
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item></div>)
                            ))}
                        </Accordion  >
                    )}
            <Paginate pages={pagesF} page={pageF} has_next={has_nextF} has_prev={has_prevF} q={q} isAdmin={false} />
        </div>
    )
}

export default WorldCatalog