import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listNewsletterAction } from '../../actions/assetActions'
import { userSubscibeAction } from '../../actions/userActions'
import { Link, useSearchParams } from 'react-router-dom'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { OffCanvas, OffCanvasMenu } from "react-offcanvas";
import { isMobile } from 'react-device-detect'
import SearchBox from '../../components/SearchBox'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import PageTitle from '../../components/PageTitle'
import { saveAs } from "file-saver";
import { SITE_URL } from '../../constants/siteConstants';
import SocialMedia from '../../components/SocialMedia';
import '../../components/styles.css'
import OutsideClickHandler from '../../components/OutsideClickHandler';
import { useAlert } from 'react-alert'
import { USER_SUBSCRIBE_RESET } from '../../constants/userConstants'


function NewsLetterScreen() {
    alert = useAlert()
    const dispatch = useDispatch()

    const newsletterList = useSelector(state => state.listNewsletter)
    const { loading, error, newsletter, pages, page, has_next, has_prev, count } = newsletterList

    const userSubscribe = useSelector(state => state.userSubscribe)
    const { loading: loadingSubscribe, error: errorSubscribe, success: successSubscribe } = userSubscribe

    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    let p = searchParams.get('page')
    if (!q) {
        q = ''
    }
    const [pager, setPager] = useState('')
    const [qer, setQer] = useState('')

    useEffect(() => {

        if (!newsletter || !newsletter.length || (p && pager !== p) || (q !== qer) || (qer !== '' && q==='')) {
            dispatch(listNewsletterAction(q, p))
        }
        if (p !== pager) {
            setPager(p)
        }
        if (q !== qer) {
            setQer(q)
        }

        if (successSubscribe) {
            dispatch({type: USER_SUBSCRIBE_RESET})
            alert.show('subscribing proccess accomplished successfully')
        }
    }, [dispatch, q, p, successSubscribe])

    const saveFile = (file) => {
        saveAs(
            SITE_URL + file
        );
    };

    const submitHandler = (e) => {
        e.preventDefault()
        if (email) {
            dispatch(userSubscibeAction({
                email,
            }))
        setEmail('')
        }
    }

    const [email, setEmail] = useState('')
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const handleClick = () => {
        setIsMenuOpened(true);
    }
    return (
        <div>
            <PageTitle title='Newsletters' />
            <SearchBox placeholder={'Search newsletters'} count={count} q={q} data={newsletter} next={'newsletters'} />
            {loadingSubscribe
                ? (<Loader />)
                : errorSubscribe
                    ? (<Message variant='danger'>{errorSubscribe}</Message>)
                    : (
                        null
                    )}
            <span className='cursor-pointer m-2 text-me text-color-3' onClick={handleClick}>
                <i class="fas fa-envelope"></i> Subscribe to newsletters
            </span>

            <OutsideClickHandler
                onOutsideClick={() => {
                    setIsMenuOpened(false);
                }}
            >
                <OffCanvas
                    width={400}
                    transitionDuration={300}
                    isMenuOpened={isMenuOpened}
                    position={"right"}
                    effect={"overlay"}
                >

                    <OffCanvasMenu className="menuClass">
                        <h3 className="mb-4">Subscribe for newsletters</h3>
                        <Form onSubmit={submitHandler}>

                            <Form.Control
                                required
                                type='email'
                                placeholder='Email address'
                                value={email ?? ""}
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </Form.Control>
                            <Button className='my-3' type='submit'><i className='fas fa-save'></i> Subscribe</Button>
                        </Form>

                        <p className={isMobile ? 'text-mobile' : 'text-sm'}>
                            <i className='fas fa-info text-color-3 mx-2'></i>
                            You can unsubscribe later from your recieved emails
                        </p>
                    </OffCanvasMenu>
                </OffCanvas>

            </OutsideClickHandler >

            <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />

            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <Row>
                            {newsletter.map(item => (
                                <Col key={item.id} sm={6} md={4} lg={6} xl={4}>
                                    <div className="blog-entry p-3 fadeInLeft animated">
                                        <Link to="#" className="blog-img">
                                            <img src={item.img} className="img-responsive img-fit" alt='' />
                                        </Link>
                                        <span><small>Year: {item.year} | Vol: {item.vol} | Issue: {item.issue}</small>
                                        </span>
                                        <SocialMedia
                                            url={SITE_URL}
                                            text={item.title}
                                        />
                                        <p className='mt-1 mb-1 text-sm text-color-2 cursor-pointer' onClick={() => saveFile(item.doc)}>
                                            <i className='fas fa-download text-color-2'></i> Download
                                        </p>
                                        <h3>{item.title}</h3>
                                        <div className={isMobile ? 'text-mobile' : 'text-sm'}>
                                            <div dangerouslySetInnerHTML={{ __html: item.description }} className='text-justify'></div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    )}
            <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />
        </div>)
}

export default NewsLetterScreen