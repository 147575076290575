import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { listNewsletterAction, emailNewsletterAction } from '../../../actions/assetActions'
import { useSearchParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import Paginate from '../../../components/Paginate'
import PageTitle from '../../../components/PageTitle'
import { Table, Button, Row, Col, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { subscribeListAction } from '../../../actions/userActions';
import { useAlert } from 'react-alert'
function AdminNewslettersScreen() {
    const dispatch = useDispatch()
    alert = useAlert()
    const newsletterList = useSelector(state => state.listNewsletter)
    const { loading, error, newsletter, pages, page, has_next, has_prev } = newsletterList
    const newsletterEmail = useSelector(state => state.emailNewsletter)
    const { success } = newsletterEmail
    const [deleted, setDeleted] = useState(false)
    const [show, setShow] = useState(false)
    const [publishId, setPublishID] = useState()
    const [publishSubject, setPublishSubject] = useState()
    const [publishBody, setPublishBody] = useState()
    const subscribeList = useSelector(state => state.subsList)
    const { subs } = subscribeList
    const [searchParams] = useSearchParams();
    let q = searchParams.get('q')
    let p = searchParams.get('page')
    if (!q) {
        q = ''
    }
    const navigation = useNavigate();
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    async function deleteHandler(tbl, id) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            await axios.delete(
                `/api/delete/${tbl}/${id}/`,
                config
            )
            setDeleted(true)
            alert.error('Item deleted successfully.')
        }
    }
    const emailHandler = () => {
        if (window.confirm('Are you sure you want to email this newsletter?')) {
            const { data } = dispatch(emailNewsletterAction({
                id: publishId,
                subject: publishSubject,
                body: publishBody
            }))
        }
    }
    const prepareEmail = (id, title) => {
        setShow(true)
        setPublishID(id)
        setPublishSubject(title)
        setPublishBody('')
    }
    useEffect(() => {
        if (success) {
            alert.show('Newsletter published.')
            setPublishID('')
            setPublishSubject('')
            setPublishBody('')
            setShow(false)
        }
        if (!userInfo || !userInfo.isAdmin) {
            navigation('/login?redirect=admin')
        }
        dispatch(listNewsletterAction(q, p))
        dispatch(subscribeListAction())
        setDeleted(false)
    }, [dispatch, q, p, deleted, success])
    return (
        <div>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <>
                            <PageTitle title='Manage Site newsletters'></PageTitle>
                            <LinkContainer to={`/admin/newsletters/add`}>
                                <Button variant='success' className='btn-sm'>
                                    <i className='fas fa-plus'></i> Add Newsletter
                                </Button>
                            </LinkContainer>
                            <Row className='m-1'>
                                <Col md={6}>
                                    <Table striped bordered hover responsive className='table-sm mt-3'>
                                        <thead>
                                            <tr>
                                                <th>List of Newsletters</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {newsletter.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        <i className='fas fa-envelope mx-2 text-success cursor-pointer'
                                                            onClick={() => prepareEmail(item.id, item.title)}></i>
                                                        <LinkContainer to={`edit/${item.id}`}>
                                                            <i className='fas fa-edit cursor-pointer text-warning mx-2'></i>
                                                        </LinkContainer>
                                                        <i className='fas fa-trash mx-2 text-danger cursor-pointer'
                                                            onClick={() => deleteHandler('Newsletter', item.id)}>
                                                        </i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={6}>
                                    {show && <div>
                                        <Form.Control
                                            type='text'
                                            placeholder='Email Subject'
                                            value={publishSubject}
                                            onChange={(e) => setPublishSubject(e.target.value)}>
                                        </Form.Control>
                                        <Form.Control
                                            as='textarea'
                                            className='h-75'
                                            required
                                            rows='5'
                                            placeholder='Enter Email Body'
                                            value={publishBody ?? ""}
                                            onChange={(e) => setPublishBody(e.target.value)}>
                                        </Form.Control>
                                        <Button onClick={() => emailHandler()} className='btn btn-block'>
                                        <i className='fas fa-envelope'></i> Send Email</Button>
                                    </div>}
                                </Col>
                            </Row>
                        </>
                    )}
            <Paginate pages={pages} page={page} has_next={has_next} has_prev={has_prev} q={q} isAdmin={true} />
            <Row className='mt-5'>
                {(subs && subs.length) ?
                    <div>
                        <h1>
                            Subscribes ({subs.length})
                        </h1>
                        <hr></hr>
                        {subs.map(item => (
                            <span key={item.id}>
                                <span className='tag tag--pill m-1'>
                                    {item.email} ({item.created})
                                    <span className='text-color-2'>
                                        <i className='fas fa-trash m-2' onClick={() => deleteHandler('Subscribe', item.id)}></i>
                                    </span>
                                </span>
                            </span>
                        ))}
                    </div>
                :
                <p><i className='fas fa-info mx-2'></i>Currently no subscribers.</p>
                }
            </Row>
        </div>
    )
}

export default AdminNewslettersScreen