import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsDetails } from '../../actions/assetActions';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import ImageGallery from '../../components/ImageGallery';
import SocialMedia from '../../components/SocialMedia';
import { SITE_URL } from '../../constants/siteConstants';
import { Row, Col } from 'react-bootstrap';

function SingleNewsScreen() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const item = useSelector(state => state.detailNews)
    const { loading, error, news } = item
    let albumExist = false
    if (Array.isArray(news.album) && news.album.length) {
        albumExist = true
    }
    useEffect(() => {
        if (!news || id !== news.id) {
            dispatch(newsDetails(id))
        }
    }, [dispatch, id])


    return (
        <React.Fragment>
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <div>
                            <Link to='/assets/news/' className='fhst-heading3'>
                                <i className="fas fa-arrow-alt-circle-left cursor-pointer"></i> 
                                News
                            </Link>
                            <h1 className='m-5 text-center fhst-heading2'>{news.header} </h1>
                            <SocialMedia
                                url={SITE_URL}
                                text={news.header}
                            />
                            <img src={news.img} className="img-responsive w-50 mb-3" alt={news.header} />
                            <p className='text-me text-black mb-3'>{news.header}</p>
                            <div dangerouslySetInnerHTML={{ __html: news.body }}></div>
                            {albumExist && (
                                <Row>
                                    <Col sm={12} md={12} lg={{ span: 8, offset: 2 }}>
                                        <ImageGallery items={news.album} />
                                    </Col>
                                </Row>
                            )}
                            {news.reference && (
                                <p className='mt-4 text-me'><div className='text-color-3'>reference:</div> {news.reference}</p>
                            )}
                        </div>
                    )}
        </React.Fragment>

    )
}

export default SingleNewsScreen