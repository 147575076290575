export const SETTINGS_REQUEST = 'SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const SETTINGS_FAIL = 'SETTINGS_FAIL'
export const SETTINGS_RESET = 'SETTINGS_RESET'

export const SETTINGS_UPDATE_REQUEST = 'SETTINGS_UPDATE_REQUEST'
export const SETTINGS_UPDATE_SUCCESS = 'SETTINGS_UPDATE_SUCCESS'
export const SETTINGS_UPDATE_FAIL = 'SETTINGS_UPDATE_FAIL'
export const SETTINGS_UPDATE_RESET = 'SETTINGS_UPDATE_RESET'

export const SETTINGS_HOME_REQUEST = 'SETTINGS_HOME_REQUEST'
export const SETTINGS_HOME_SUCCESS = 'SETTINGS_HOME_SUCCESS'
export const SETTINGS_HOME_FAIL = 'SETTINGS_HOME_FAIL'
export const SETTINGS_HOME_RESET = 'SETTINGS_HOME_RESET'

export const SETTINGS_HOME_UPDATE_REQUEST = 'SETTINGS_HOME_UPDATE_REQUEST'
export const SETTINGS_HOME_UPDATE_SUCCESS = 'SETTINGS_HOME_UPDATE_SUCCESS'
export const SETTINGS_HOME_UPDATE_FAIL = 'SETTINGS_HOME_UPDATE_FAIL'
export const SETTINGS_HOME_UPDATE_RESET = 'SETTINGS_HOME_UPDATE_RESET'

export const SETTINGS_CONTACT_REQUEST = 'SETTINGS_CONTACT_REQUEST'
export const SETTINGS_CONTACT_SUCCESS = 'SETTINGS_CONTACT_SUCCESS'
export const SETTINGS_CONTACT_FAIL = 'SETTINGS_CONTACT_FAIL'
export const SETTINGS_CONTACT_RESET = 'SETTINGS_CONTACT_RESET'

export const SETTINGS_CONTACT_UPDATE_REQUEST = 'SETTINGS_CONTACT_UPDATE_REQUEST'
export const SETTINGS_CONTACT_UPDATE_SUCCESS = 'SETTINGS_CONTACT_UPDATE_SUCCESS'
export const SETTINGS_CONTACT_UPDATE_FAIL = 'SETTINGS_CONTACT_UPDATE_FAIL'
export const SETTINGS_CONTACT_UPDATE_RESET = 'SETTINGS_CONTACT_UPDATE_RESET'

export const SETTINGS_MUNDI_REQUEST = 'SETTINGS_MUNDI_REQUEST'
export const SETTINGS_MUNDI_SUCCESS = 'SETTINGS_MUNDI_SUCCESS'
export const SETTINGS_MUNDI_FAIL = 'SETTINGS_MUNDI_FAIL'
export const SETTINGS_MUNDI_RESET = 'SETTINGS_MUNDI_RESET'

export const SETTINGS_MUNDI_UPDATE_REQUEST = 'SETTINGS_MUNDI_UPDATE_REQUEST'
export const SETTINGS_MUNDI_UPDATE_SUCCESS = 'SETTINGS_MUNDI_UPDATE_SUCCESS'
export const SETTINGS_MUNDI_UPDATE_FAIL = 'SETTINGS_MUNDI_UPDATE_FAIL'
export const SETTINGS_MUNDI_UPDATE_RESET = 'SETTINGS_MUNDI_UPDATE_RESET'

export const CH_INFO_REQUEST = 'CH_INFO_REQUEST'
export const CH_INFO_SUCCESS = 'CH_INFO_SUCCESS'
export const CH_INFO_FAIL = 'CH_INFO_FAIL'
export const CH_INFO_RESET = 'CH_INFO_RESET'

export const CH_INFO_UPDATE_REQUEST = 'CH_INFO_UPDATE_REQUEST'
export const CH_INFO_UPDATE_SUCCESS = 'CH_INFO_UPDATE_SUCCESS'
export const CH_INFO_UPDATE_FAIL = 'CH_INFO_UPDATE_FAIL'
export const CH_INFO_UPDATE_RESET = 'CH_INFO_UPDATE_RESET'

export const SETTINGS_INTRO_REQUEST = 'SETTINGS_INTRO_REQUEST'
export const SETTINGS_INTRO_SUCCESS = 'SETTINGS_INTRO_SUCCESS'
export const SETTINGS_INTRO_FAIL = 'SETTINGS_INTRO_FAIL'
export const SETTINGS_INTRO_RESET = 'SETTINGS_INTRO_RESET'

export const SETTINGS_INTRO_UPDATE_REQUEST = 'SETTINGS_INTRO_UPDATE_REQUEST'
export const SETTINGS_INTRO_UPDATE_SUCCESS = 'SETTINGS_INTRO_UPDATE_SUCCESS'
export const SETTINGS_INTRO_UPDATE_FAIL = 'SETTINGS_INTRO_UPDATE_FAIL'
export const SETTINGS_INTRO_UPDATE_RESET = 'SETTINGS_INTRO_UPDATE_RESET'

export const SETTINGS_ARCHIVE_REQUEST = 'SETTINGS_ARCHIVE_REQUEST'
export const SETTINGS_ARCHIVE_SUCCESS = 'SETTINGS_ARCHIVE_SUCCESS'
export const SETTINGS_ARCHIVE_FAIL = 'SETTINGS_ARCHIVE_FAIL'
export const SETTINGS_ARCHIVE_RESET = 'SETTINGS_ARCHIVE_RESET'

export const ARCHIVE_DETAILS_REQUEST = 'ARCHIVE_DETAILS_REQUEST'
export const ARCHIVE_DETAILS_SUCCESS = 'ARCHIVE_DETAILS_SUCCESS'
export const ARCHIVE_DETAILS_FAIL = 'ARCHIVE_DETAILS_FAIL'

export const SETTINGS_ARCHIVE_UPDATE_REQUEST = 'SETTINGS_ARCHIVE_UPDATE_REQUEST'
export const SETTINGS_ARCHIVE_UPDATE_SUCCESS = 'SETTINGS_ARCHIVE_UPDATE_SUCCESS'
export const SETTINGS_ARCHIVE_UPDATE_FAIL = 'SETTINGS_ARCHIVE_UPDATE_FAIL'
export const SETTINGS_ARCHIVE_UPDATE_RESET = 'SETTINGS_ARCHIVE_UPDATE_RESET'

export const SETTINGS_CLASSIFICATION_REQUEST = 'SETTINGS_CLASSIFICATION_REQUEST'
export const SETTINGS_CLASSIFICATION_SUCCESS = 'SETTINGS_CLASSIFICATION_SUCCESS'
export const SETTINGS_CLASSIFICATION_FAIL = 'SETTINGS_CLASSIFICATION_FAIL'
export const SETTINGS_CLASSIFICATION_RESET = 'SETTINGS_CLASSIFICATION_RESET'

export const SETTINGS_CLASSIFICATION_UPDATE_REQUEST = 'SETTINGS_CLASSIFICATION_UPDATE_REQUEST'
export const SETTINGS_CLASSIFICATION_UPDATE_SUCCESS = 'SETTINGS_CLASSIFICATION_UPDATE_SUCCESS'
export const SETTINGS_CLASSIFICATION_UPDATE_FAIL = 'SETTINGS_CLASSIFICATION_UPDATE_FAIL'
export const SETTINGS_CLASSIFICATION_UPDATE_RESET = 'SETTINGS_CLASSIFICATION_UPDATE_RESET'

export const SETTINGS_PUBLICATIONS_REQUEST = 'SETTINGS_PUBLICATIONS_REQUEST'
export const SETTINGS_PUBLICATIONS_SUCCESS = 'SETTINGS_PUBLICATIONS_SUCCESS'
export const SETTINGS_PUBLICATIONS_FAIL = 'SETTINGS_PUBLICATIONS_FAIL'
export const SETTINGS_PUBLICATIONS_RESET = 'SETTINGS_PUBLICATIONS_RESET'

export const SETTINGS_PUBLICATIONS_UPDATE_REQUEST = 'SETTINGS_PUBLICATIONS_UPDATE_REQUEST'
export const SETTINGS_PUBLICATIONS_UPDATE_SUCCESS = 'SETTINGS_PUBLICATIONS_UPDATE_SUCCESS'
export const SETTINGS_PUBLICATIONS_UPDATE_FAIL = 'SETTINGS_PUBLICATIONS_UPDATE_FAIL'
export const SETTINGS_PUBLICATIONS_UPDATE_RESET = 'SETTINGS_PUBLICATIONS_UPDATE_RESET'

export const SETTINGS_CATNOTES_REQUEST = 'SETTINGS_CATNOTES_REQUEST'
export const SETTINGS_CATNOTES_SUCCESS = 'SETTINGS_CATNOTES_SUCCESS'
export const SETTINGS_CATNOTES_FAIL = 'SETTINGS_CATNOTES_FAIL'
export const SETTINGS_CATNOTES_RESET = 'SETTINGS_CATNOTES_RESET'

export const SETTINGS_CATNOTES_UPDATE_REQUEST = 'SETTINGS_CATNOTES_UPDATE_REQUEST'
export const SETTINGS_CATNOTES_UPDATE_SUCCESS = 'SETTINGS_CATNOTES_UPDATE_SUCCESS'
export const SETTINGS_CATNOTES_UPDATE_FAIL = 'SETTINGS_CATNOTES_UPDATE_FAIL'
export const SETTINGS_CATNOTES_UPDATE_RESET = 'SETTINGS_CATNOTES_UPDATE_RESET'

export const ACK_REQUEST = 'ACK_REQUEST'
export const ACK_SUCCESS = 'ACK_SUCCESS'
export const ACK_FAIL = 'ACK_FAIL'
export const ACK_RESET = 'ACK_RESET'

export const ACK_UPDATE_REQUEST = 'ACK_UPDATE_REQUEST'
export const ACK_UPDATE_SUCCESS = 'ACK_UPDATE_SUCCESS'
export const ACK_UPDATE_FAIL = 'ACK_UPDATE_FAIL'
export const ACK_UPDATE_RESET = 'ACK_UPDATE_RESET'

export const DISCLAIMER_REQUEST = 'DISCLAIMER_REQUEST'
export const DISCLAIMER_SUCCESS = 'DISCLAIMER_SUCCESS'
export const DISCLAIMER_FAIL = 'DISCLAIMER_FAIL'
export const DISCLAIMER_RESET = 'DISCLAIMER_RESET'

export const DISCLAIMER_UPDATE_REQUEST = 'DISCLAIMER_UPDATE_REQUEST'
export const DISCLAIMER_UPDATE_SUCCESS = 'DISCLAIMER_UPDATE_SUCCESS'
export const DISCLAIMER_UPDATE_FAIL = 'DISCLAIMER_UPDATE_FAIL'
export const DISCLAIMER_UPDATE_RESET = 'DISCLAIMER_UPDATE_RESET'

export const NEWS_TOP_REQUEST = 'NEWS_TOP_REQUEST'
export const NEWS_TOP_SUCCESS = 'NEWS_TOP_SUCCESS'
export const NEWS_TOP_FAIL = 'NEWS_TOP_FAIL'

export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST'
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS'
export const NEWS_LIST_FAIL = 'NEWS_LIST_FAIL'

export const NEWS_ALL_REQUEST = 'NEWS_ALL_REQUEST'
export const NEWS_ALL_SUCCESS = 'NEWS_ALL_SUCCESS'
export const NEWS_ALL_FAIL = 'NEWS_ALL_FAIL'

export const NEWS_CREATE_REQUEST = 'NEWS_CREATE_REQUEST'
export const NEWS_CREATE_SUCCESS = 'NEWS_CREATE_SUCCESS'
export const NEWS_CREATE_FAIL = 'NEWS_CREATE_FAIL'
export const NEWS_CREATE_RESET = 'NEWS_CREATE_RESET'

export const NEWS_UPDATE_REQUEST = 'NEWS_UPDATE_REQUEST'
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS'
export const NEWS_UPDATE_FAIL = 'NEWS_UPDATE_FAIL'
export const NEWS_UPDATE_RESET = 'NEWS_UPDATE_RESET'

export const CAPTION_UPDATE_REQUEST = 'CAPTION_UPDATE_REQUEST'
export const CAPTION_UPDATE_SUCCESS = 'CAPTION_UPDATE_SUCCESS'
export const CAPTION_UPDATE_FAIL = 'CAPTION_UPDATE_FAIL'
export const CAPTION_UPDATE_RESET = 'CAPTION_UPDATE_RESET'

export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST'
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS'
export const NEWS_DELETE_FAIL = 'NEWS_DELETE_FAIL'

export const NEWS_DETAILS_REQUEST = 'NEWS_DETAILS_REQUEST'
export const NEWS_DETAILS_SUCCESS = 'NEWS_DETAILS_SUCCESS'
export const NEWS_DETAILS_FAIL = 'NEWS_DETAILS_FAIL'

export const HOME_ALBUM_REQUEST = 'HOME_ALBUM_REQUEST'
export const HOME_ALBUM_SUCCESS = 'HOME_ALBUM_SUCCESS'
export const HOME_ALBUM_FAIL = 'HOME_ALBUM_FAIL'

export const MAIN_ALBUM_REQUEST = 'MAIN_ALBUM_REQUEST'
export const MAIN_ALBUM_SUCCESS = 'MAIN_ALBUM_SUCCESS'
export const MAIN_ALBUM_FAIL = 'MAIN_ALBUM_FAIL'

export const NEWS_ALBUM_DELETE_REQUEST = 'NEWS_ALBUM_DELETE_REQUEST'
export const NEWS_ALBUM_DELETE_SUCCESS = 'NEWS_ALBUM_DELETE_SUCCESS'
export const NEWS_ALBUM_DELETE_FAIL = 'NEWS_ALBUM_DELETE_FAIL'

export const COUNTERS_REQUEST = 'COUNTERS_REQUEST'
export const COUNTERS_SUCCESS = 'COUNTERS_SUCCESS'
export const COUNTERS_FAIL = 'COUNTERS_FAIL'


export const NEWSLETTER_LIST_REQUEST = 'NEWSLETTER_LIST_REQUEST'
export const NEWSLETTER_LIST_SUCCESS = 'NEWSLETTER_LIST_SUCCESS'
export const NEWSLETTER_LIST_FAIL = 'NEWSLETTER_LIST_FAIL'

export const NEWSLETTER_DETAILS_REQUEST = 'NEWSLETTER_DETAILS_REQUEST'
export const NEWSLETTER_DETAILS_SUCCESS = 'NEWSLETTER_DETAILS_SUCCESS'
export const NEWSLETTER_DETAILS_FAIL = 'NEWSLETTER_DETAILS_FAIL'

export const NEWSLETTER_CREATE_REQUEST = 'NEWSLETTER_CREATE_REQUEST'
export const NEWSLETTER_CREATE_SUCCESS = 'NEWSLETTER_CREATE_SUCCESS'
export const NEWSLETTER_CREATE_FAIL = 'NEWSLETTER_CREATE_FAIL'
export const NEWSLETTER_CREATE_RESET = 'NEWSLETTER_CREATE_RESET'

export const NEWSLETTER_UPDATE_REQUEST = 'NEWSLETTER_UPDATE_REQUEST'
export const NEWSLETTER_UPDATE_SUCCESS = 'NEWSLETTER_UPDATE_SUCCESS'
export const NEWSLETTER_UPDATE_FAIL = 'NEWSLETTER_UPDATE_FAIL'
export const NEWSLETTER_UPDATE_RESET = 'NEWSLETTER_UPDATE_RESET'

export const NEWSLETTER_DELETE_REQUEST = 'NEWSLETTER_DELETE_REQUEST'
export const NEWSLETTER_DELETE_SUCCESS = 'NEWSLETTER_DELETE_SUCCESS'
export const NEWSLETTER_DELETE_FAIL = 'NEWSLETTER_DELETE_FAIL'

export const NEWSLETTER_EMAIL_REQUEST = 'NEWSLETTER_EMAIL_REQUEST'
export const NEWSLETTER_EMAIL_SUCCESS = 'NEWSLETTER_EMAIL_SUCCESS'
export const NEWSLETTER_EMAIL_FAIL = 'NEWSLETTER_EMAIL_FAIL'

export const CONGRESS_LIST_REQUEST = 'CONGRESS_LIST_REQUEST'
export const CONGRESS_LIST_SUCCESS = 'CONGRESS_LIST_SUCCESS'
export const CONGRESS_LIST_FAIL = 'CONGRESS_LIST_FAIL'

export const CONGRESS_ALL_REQUEST = 'CONGRESS_ALL_REQUEST'
export const CONGRESS_ALL_SUCCESS = 'CONGRESS_ALL_SUCCESS'
export const CONGRESS_ALL_FAIL = 'CONGRESS_ALL_FAIL'

export const CONGRESS_DETAILS_REQUEST = 'CONGRESS_DETAILS_REQUEST'
export const CONGRESS_DETAILS_SUCCESS = 'CONGRESS_DETAILS_SUCCESS'
export const CONGRESS_DETAILS_FAIL = 'CONGRESS_DETAILS_FAIL'


export const CONGRESS_CREATE_REQUEST = 'CONGRESS_CREATE_REQUEST'
export const CONGRESS_CREATE_SUCCESS = 'CONGRESS_CREATE_SUCCESS'
export const CONGRESS_CREATE_FAIL = 'CONGRESS_CREATE_FAIL'
export const CONGRESS_CREATE_RESET = 'CONGRESS_CREATE_RESET'

export const PARTICIPANT_CREATE_REQUEST = 'PARTICIPANT_CREATE_REQUEST'
export const PARTICIPANT_CREATE_SUCCESS = 'PARTICIPANT_CREATE_SUCCESS'
export const PARTICIPANT_CREATE_FAIL = 'PARTICIPANT_CREATE_FAIL'
export const PARTICIPANT_CREATE_RESET = 'PARTICIPANT_CREATE_RESET'

export const PARTICIPANT_DELETE_REQUEST = 'PARTICIPANT_DELETE_REQUEST'
export const PARTICIPANT_DELETE_SUCCESS = 'PARTICIPANT_DELETE_SUCCESS'
export const PARTICIPANT_DELETE_FAIL = 'PARTICIPANT_DELETE_FAIL'
export const PARTICIPANT_DELETE_RESET = 'PARTICIPANT_DELETE_RESET'

export const PROGRAM_CREATE_REQUEST = 'PROGRAM_CREATE_REQUEST'
export const PROGRAM_CREATE_SUCCESS = 'PROGRAM_CREATE_SUCCESS'
export const PROGRAM_CREATE_FAIL = 'PROGRAM_CREATE_FAIL'
export const PROGRAM_CREATE_RESET = 'PROGRAM_CREATE_RESET'

export const PROGRAM_DELETE_REQUEST = 'PROGRAM_DELETE_REQUEST'
export const PROGRAM_DELETE_SUCCESS = 'PROGRAM_DELETE_SUCCESS'
export const PROGRAM_DELETE_FAIL = 'PROGRAM_DELETE_FAIL'
export const PROGRAM_DELETE_RESET = 'PROGRAM_DELETE_RESET'

export const PROCEEDING_CREATE_REQUEST = 'PROCEEDING_CREATE_REQUEST'
export const PROCEEDING_CREATE_SUCCESS = 'PROCEEDING_CREATE_SUCCESS'
export const PROCEEDING_CREATE_FAIL = 'PROCEEDING_CREATE_FAIL'
export const PROCEEDING_CREATE_RESET = 'PROCEEDING_CREATE_RESET'

export const PROCEEDING_DELETE_REQUEST = 'PROCEEDING_DELETE_REQUEST'
export const PROCEEDING_DELETE_SUCCESS = 'PROCEEDING_DELETE_SUCCESS'
export const PROCEEDING_DELETE_FAIL = 'PROCEEDING_DELETE_FAIL'
export const PROCEEDING_DELETE_RESET = 'PROCEEDING_DELETE_RESET'

export const CONGRESS_UPDATE_REQUEST = 'CONGRESS_UPDATE_REQUEST'
export const CONGRESS_UPDATE_SUCCESS = 'CONGRESS_UPDATE_SUCCESS'
export const CONGRESS_UPDATE_FAIL = 'CONGRESS_UPDATE_FAIL'
export const CONGRESS_UPDATE_RESET = 'CONGRESS_UPDATE_RESET'

export const CONGRESS_DELETE_REQUEST = 'CONGRESS_DELETE_REQUEST'
export const CONGRESS_DELETE_SUCCESS = 'CONGRESS_DELETE_SUCCESS'
export const CONGRESS_DELETE_FAIL = 'CONGRESS_DELETE_FAIL'

export const ARTICLE_REQUEST = 'ARTICLE_REQUEST'
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS'
export const ARTICLE_FAIL = 'ARTICLE_FAIL'

export const ARTICLE_UPDATE_REQUEST = 'ARTICLE_UPDATE_REQUEST'
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS'
export const ARTICLE_UPDATE_FAIL = 'ARTICLE_UPDATE_FAIL'
export const ARTICLE_UPDATE_RESET = 'ARTICLE_UPDATE_RESET'

export const ARTICLE_CREATE_REQUEST = 'ARTICLE_CREATE_REQUEST'
export const ARTICLE_CREATE_SUCCESS = 'ARTICLE_CREATE_SUCCESS'
export const ARTICLE_CREATE_FAIL = 'ARTICLE_CREATE_FAIL'
export const ARTICLE_CREATE_RESET = 'ARTICLE_CREATE_RESET'

export const ATTACH_LIST_REQUEST = 'ATTACH_LIST_REQUEST'
export const ATTACH_LIST_SUCCESS = 'ATTACH_LIST_SUCCESS'
export const ATTACH_LIST_FAIL = 'ATTACH_LIST_FAIL'

export const ATTACH_CREATE_REQUEST = 'ATTACH_CREATE_REQUEST'
export const ATTACH_CREATE_SUCCESS = 'ATTACH_CREATE_SUCCESS'
export const ATTACH_CREATE_FAIL = 'ATTACH_CREATE_FAIL'
export const ATTACH_CREATE_RESET = 'ATTACH_CREATE_RESET'