import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader';
import PageTitle from '../../components/PageTitle';

function UnsubscribeScreen() {
    const { id } = useParams();
    const navigation = useNavigate()
    if (!id) {
        navigation('/')
    }
    const [uploading, setUploading] = useState(false)
    const [uns, setUns] = useState(false)
    const unsubscribeHandler = async (e) => {
        setUploading(true)
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                }
            }
            await axios.delete(
                `/api/users/unsubscribe/${id}/`,
                config
            )
            alert.error('Unsubscribe process successfull')
            setUploading(false)
            setUns(true)
        } catch (error) {
            setUploading(false)
        }
    }
    useEffect(() => {
        if (!uns) {
            unsubscribeHandler()
            setUns(true)
        }
    }, [uploading, uns])
    return (
        <div className='m-5'>
            {uploading && <Loader />}

            {!uns ? <div>
                <h1> Trying to unsubscirbe</h1>
            </div>
                :
                <div>
                    <PageTitle title='Forum Herbulot newsletters' />
                    <h4 className='text-color-2'>
                        <i className='fas fa-check-circle m-3'></i>
                        Your email address removed from our our database. <br />
                        <i className='fas fa-info-circle m-3'></i>
                        you wont recieve any emails from us.
                        <div className='text-center m-5 text-color-2'>Thank you</div>
                    </h4>
                </div>}
        </div>
    )
}

export default UnsubscribeScreen